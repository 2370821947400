import { createContext } from "react";

export const DisclaimerSigned = createContext({}); // keep track of whether user has acknowledged terms of use
export const UserInfo = createContext({});
export const ActiveTour = createContext(null);
export const AvailableTags = createContext([]);
export const TagLookup = createContext({});
export const StripePromise = createContext(null);
export const VisualCache = createContext(null);
export const Bundles = createContext([]);

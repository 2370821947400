import {APIGetUserInfo} from "./APIFunctions";

//Debounce fuction from https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore
export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function shallowCopy(obj) {
  return Object.assign({}, obj);
}

export function download(filename, dataUri) {
  var element = document.createElement("a");
  element.setAttribute("href", dataUri);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);
  element.click();

  document.body.removeChild(element);
}

export function downloadHtml(filename, text){
  const dataUri = URL.createObjectURL(
    new Blob([text], { type: "text/html" })
  );
  download(filename, dataUri);
}

export function scaleImage(dataUri, maxDimension, cb) {
  const img = document.createElement("img");
  img.onload = () => {
    const largestNative = Math.max(img.naturalWidth, img.naturalHeight);
    if (largestNative <= maxDimension) {
      cb(dataUri);
    }
    const f = maxDimension / largestNative;
    const canvas = document.createElement("canvas");
    const w = Math.round(f * img.naturalWidth);
    const h = Math.round(f * img.naturalHeight);
    canvas.width = w;
    canvas.height = h;

    // Scale and draw the source image to the canvas

    canvas.getContext("2d").drawImage(img, 0, 0, w, h);
    // Convert the canvas to a data URL in PNG format
    cb(canvas.toDataURL());
  };
  img.src = dataUri;
}

export function getSimbadTypeFromSlug(slug) {
  const lookup = {
    "?": "Object of Unknown Nature",
    Unknown: "Object of Unknown Nature",
    "..1": "{pr*} Pre-Main Sequence Star ",
    "..10": "Barium Star",
    "..11": "Dwarf Carbon Star",
    "..12": "Carbon-Enhanced Metal Poor Star",
    "..13": "{Al*} Eclipsing Binary of Algol type",
    "..14": "{bL*}Eclipsing Binary of beta Lyr type",
    "..15": "{WU*} Eclipsing Binary of W UMa type",
    "..16": "{NL*} Nova-like Binary",
    "..17": "{DN*} Dwarf Nova",
    "..18": "{DQ*} CV of DQ Her type  Intermediate polar.",
    "..19": "{AM*} CV of AM CVn type",
    "..2": "LBV=Luminous Blue Variable",
    "..20": "Irregular Variable with rapid variations",
    "..21": "{Fl*} Flare Star",
    "..22": "Star showing Eclipses by its Planet",
    "..23": "{*iC} Star towards a Cluster",
    "..24": "{*iA} Star towards an Association",
    "..25": "{*iN} Star towards a Nebula",
    "..26": "{*i*} Star in double system",
    "..27": "{BNe} Bright Nebula",
    "..28": "{HzG} Galaxy with high redshift",
    "..29": "{ERO} ERO/VRO, Extremely/Very Red Object",
    "..3": "{FU*} FU Ori Variable",
    "..30": "ULIRG, Ultra Luninous Infrared Galaxy",
    "..31": "{LyA, DLA, mAL, LLS, BAL} Absorption Line System",
    "..32": "{red} Very Red Source",
    "..4": "Red Clump Star",
    "..5": "{sr*} Semi-Regular Variable",
    "..6": "O-rich AGB Star",
    "..7": "{ZZ*} Pulsating White Dwarf",
    "..8": "ELMWD=Extremely Low Mass White Dwarf",
    "..9": "CH Star",
    "*": "Star",
    Star: "Star",
    "**": "Double or Multiple Star",
    "**": "Double or Multiple Star",
    "**?": "Double or Multiple Star candidate",
    "a2*": "alpha2 CVn Variable",
    "alf2CVnV*": "alpha2 CVn Variable",
    "a2?": "alpha2 CVn Variable candidate",
    "AB*": "Asymptotic Giant Branch Star",
    "AGB*": "Asymptotic Giant Branch Star",
    "AB?": "Asymptotic Giant Branch Star candidate",
    "Ae*": "Herbig Ae/Be Star",
    "Ae*": "Herbig Ae/Be Star",
    "Ae?": "Herbig Ae/Be Star candidate",
    AGN: "Active Galaxy Nucleus",
    AGN: "Active Galaxy Nucleus",
    "AG?": "Active Galaxy Nucleus candidate",
    "As*": "Association of Stars",
    Association: "Association of Stars",
    "As?": "Association of Stars candidate",
    "bC*": "beta Cep Variable",
    "bCepV*": "beta Cep Variable",
    "bC?": "beta Cep Variable candidate",
    bCG: "Blue Compact Galaxy",
    BlueCompactG: "Blue Compact Galaxy",
    "BD*": "Brown Dwarf",
    "BrownD*": "Brown Dwarf",
    "BD?": "Brown Dwarf candidate",
    "Be*": "Be Star",
    "Be*": "Be Star",
    "Be?": "Be Star candidate",
    BH: "Black Hole",
    BlackHole: "Black Hole",
    "BH?": "Black Hole candidate",
    BiC: "Brightest Galaxy in a Cluster (BCG)",
    BrightestCG: "Brightest Galaxy in a Cluster (BCG)",
    Bla: "Blazar",
    Blazar: "Blazar",
    "Bz?": "Blazar candidate",
    BLL: "BL Lac",
    BLLac: "BL Lac",
    "BL?": "BL Lac candidate",
    blu: "Blue Object",
    blue: "Blue Object",
    "BS*": "Blue Straggler",
    BlueStraggler: "Blue Straggler",
    "BS?": "Blue Straggler candidate",
    bub: "Bubble",
    Bubble: "Bubble",
    "BY*": "BY Dra Variable",
    "BYDraV*": "BY Dra Variable",
    "BY?": "BY Dra Variable candidate",
    "C*": "Carbon Star",
    "C*": "Carbon Star",
    "C*?": "Carbon Star candidate",
    "cC*": "Classical Cepheid Variable",
    ClassicalCep: "Classical Cepheid Variable",
    "Ce*": "Cepheid Variable",
    Cepheid: "Cepheid Variable",
    "Ce?": "Cepheid Variable candidate",
    CGb: "Cometary Globule / Pillar",
    ComGlob: "Cometary Globule / Pillar",
    CGG: "Compact Group of Galaxies",
    Compact_Gr_G: "Compact Group of Galaxies",
    "Cl*": "Cluster of Stars",
    "Cluster*": "Cluster of Stars",
    "Cl?": "Cluster of Stars candidate",
    Cld: "Cloud",
    Cloud: "Cloud",
    ClG: "Cluster of Galaxies",
    ClG: "Cluster of Galaxies",
    "C?G": "Cluster of Galaxies candidate",
    cm: "Centimetric Radio Source",
    cmRad: "Centimetric Radio Source",
    cor: "Dense Core",
    denseCore: "Dense Core",
    "CV*": "Cataclysmic Binary",
    "CataclyV*": "Cataclysmic Binary",
    "CV?": "Cataclysmic Binary candidate",
    DNe: "Dark Cloud (nebula)",
    DarkNeb: "Dark Cloud (nebula)",
    "dS*": "delta Sct Variable",
    "delSctV*": "delta Sct Variable",
    "EB*": "Eclipsing Binary",
    EclBin: "Eclipsing Binary",
    "EB?": "Eclipsing Binary candidate",
    "El*": "Ellipsoidal Variable",
    EllipVar: "Ellipsoidal Variable",
    "El?": "Ellipsoidal Variable candidate",
    "Em*": "Emission-line Star",
    "EmLine*": "Emission-line Star",
    EmG: "Emission-line galaxy",
    EmissionG: "Emission-line galaxy",
    EmO: "Emission Object",
    EmObj: "Emission Object",
    "Er*": "Eruptive Variable",
    "Eruptive*": "Eruptive Variable",
    "Er?": "Eruptive Variable candidate",
    err: "Not an Object (Error, Artefact, ...)",
    Inexistent: "Not an Object (Error, Artefact, ...)",
    ev: "Transient Event",
    Transient: "Transient Event",
    "Ev*": "Evolved Star",
    "Evolved*": "Evolved Star",
    "Ev?": "Evolved Star candidate",
    FIR: "Far-IR source (λ >= 30 µm)",
    FarIR: "Far-IR source (λ >= 30 µm)",
    flt: "Interstellar Filament",
    Filament: "Interstellar Filament",
    G: "Galaxy",
    Galaxy: "Galaxy",
    "G?": "Galaxy candidate",
    gam: "Gamma-ray Source",
    gamma: "Gamma-ray Source",
    gB: "Gamma-ray Burst",
    gammaBurst: "Gamma-ray Burst",
    "gD*": "gamma Dor Variable",
    "gammaDorV*": "gamma Dor Variable",
    GiC: "Galaxy towards a Cluster of Galaxies",
    GtowardsCl: "Galaxy towards a Cluster of Galaxies",
    GiG: "Galaxy towards a Group of Galaxies",
    GtowardsGroup: "Galaxy towards a Group of Galaxies",
    GiP: "Galaxy in Pair of Galaxies",
    GinPair: "Galaxy in Pair of Galaxies",
    glb: "Globule (low-mass dark cloud)",
    Globule: "Globule (low-mass dark cloud)",
    GlC: "Globular Cluster",
    GlobCluster: "Globular Cluster",
    "Gl?": "Globular Cluster candidate",
    gLe: "Gravitational Lens",
    GravLens: "Gravitational Lens",
    "Le?": "Gravitational Lens candidate",
    gLS: "Gravitational Lens System (lens+images)",
    GravLensSystem: "Gravitational Lens System (lens+images)",
    "LS?": "Gravitational Lens System (lens+images) candidate",
    GNe: "Nebula",
    GalNeb: "Nebula",
    GrG: "Group of Galaxies",
    GroupG: "Group of Galaxies",
    "Gr?": "Group of Galaxies candidate",
    grv: "Gravitational Source",
    Gravitation: "Gravitational Source",
    GWE: "Gravitational Wave Event",
    GravWaveEvent: "Gravitational Wave Event",
    H2G: "HII Galaxy",
    HIIG: "HII Galaxy",
    "HB*": "Horizontal Branch Star",
    "HorBranch*": "Horizontal Branch Star",
    "HB?": "Horizontal Branch Star candidate",
    HH: "Herbig-Haro Object",
    HerbigHaroObj: "Herbig-Haro Object",
    HI: "HI (21cm) Source",
    HI: "HI (21cm) Source",
    HII: "HII Region",
    HIIReg: "HII Region",
    "HS*": "Hot Subdwarf",
    HotSubdwarf: "Hot Subdwarf",
    "HS?": "Hot Subdwarf candidate",
    "HV*": "High Velocity Star",
    "HighVel*": "High Velocity Star",
    HVC: "High-velocity Cloud",
    HVCld: "High-velocity Cloud",
    HXB: "High Mass X-ray Binary",
    HighMassXBin: "High Mass X-ray Binary",
    "HX?": "High Mass X-ray Binary candidate",
    IG: "Interacting Galaxies",
    InteractingG: "Interacting Galaxies",
    IR: "Infra-Red Source",
    Infrared: "Infra-Red Source",
    "Ir*": "Irregular Variable",
    "IrregularV*": "Irregular Variable",
    ISM: "Interstellar Medium Object",
    ISM: "Interstellar Medium Object",
    LeG: "Gravitationally Lensed Image of a Galaxy",
    LensedG: "Gravitationally Lensed Image of a Galaxy",
    LeI: "Gravitationally Lensed Image",
    LensedImage: "Gravitationally Lensed Image",
    "LI?": "Gravitationally Lensed Image candidate",
    LeQ: "Gravitationally Lensed Image of a Quasar",
    LensedQ: "Gravitationally Lensed Image of a Quasar",
    Lev: "(Micro)Lensing Event",
    LensingEv: "(Micro)Lensing Event",
    LIN: "LINER-type Active Galaxy Nucleus",
    LINER: "LINER-type Active Galaxy Nucleus",
    "LM*": "Low-mass Star",
    "Low-Mass*": "Low-mass Star",
    "LM?": "Low-mass Star candidate",
    "LP*": "Long-Period Variable",
    "LongPeriodV*": "Long-Period Variable",
    "LP?": "Long-Period Variable candidate",
    LSB: "Low Surface Brightness Galaxy",
    LowSurfBrghtG: "Low Surface Brightness Galaxy",
    LXB: "Low Mass X-ray Binary",
    LowMassXBin: "Low Mass X-ray Binary",
    "LX?": "Low Mass X-ray Binary candidate",
    "Ma*": "Massive Star",
    "Massiv*": "Massive Star",
    "Ma?": "Massive Star candidate",
    Mas: "Maser",
    Maser: "Maser",
    MGr: "Moving Group",
    MouvGroup: "Moving Group",
    "Mi*": "Mira Variable",
    Mira: "Mira Variable",
    "Mi?": "Mira Variable candidate",
    MIR: "Mid-IR Source (3 to 30 µm)",
    MidIR: "Mid-IR Source (3 to 30 µm)",
    mm: "Millimetric Radio Source",
    mmRad: "Millimetric Radio Source",
    MoC: "Molecular Cloud",
    MolCld: "Molecular Cloud",
    mR: "Metric Radio Source",
    metricRad: "Metric Radio Source",
    "MS*": "Main Sequence Star",
    "MainSequence*": "Main Sequence Star",
    "MS?": "Main Sequence Star candidate",
    mul: "Composite Object, Blend",
    Blend: "Composite Object, Blend",
    "N*": "Neutron Star",
    "Neutron*": "Neutron Star",
    "N*?": "Neutron Star candidate",
    NIR: "Near-IR Source (λ < 3 µm)",
    NearIR: "Near-IR Source (λ < 3 µm)",
    "No*": "Classical Nova",
    Nova: "Classical Nova",
    "No?": "Classical Nova candidate",
    "OH*": "OH/IR Star",
    "OH/IR*": "OH/IR Star",
    "OH?": "OH/IR Star candidate",
    OpC: "Open Cluster",
    OpenCluster: "Open Cluster",
    Opt: "Optical Source",
    Optical: "Optical Source",
    "Or*": "Orion Variable",
    "OrionV*": "Orion Variable",
    out: "Outflow",
    Outflow: "Outflow",
    "of?": "Outflow candidate",
    "pA*": "Post-AGB Star",
    "post-AGB*": "Post-AGB Star",
    "pA?": "Post-AGB Star candidate",
    PaG: "Pair of Galaxies",
    PairG: "Pair of Galaxies",
    PCG: "Proto Cluster of Galaxies",
    protoClG: "Proto Cluster of Galaxies",
    "PCG?": "Proto Cluster of Galaxies candidate",
    "Pe*": "Chemically Peculiar Star",
    "ChemPec*": "Chemically Peculiar Star",
    "Pe?": "Chemically Peculiar Star candidate",
    Pl: "Extra-solar Planet",
    Planet: "Extra-solar Planet",
    "Pl?": "Extra-solar Planet candidate",
    "PM*": "High Proper Motion Star",
    "HighPM*": "High Proper Motion Star",
    PN: "Planetary Nebula",
    PlanetaryNeb: "Planetary Nebula",
    "PN?": "Planetary Nebula candidate",
    PoC: "Part of Cloud",
    PartofCloud: "Part of Cloud",
    PoG: "Part of a Galaxy",
    PartofG: "Part of a Galaxy",
    Psr: "Pulsar",
    Pulsar: "Pulsar",
    "Pu*": "Pulsating Variable",
    "PulsV*": "Pulsating Variable",
    "Pu?": "Pulsating Variable candidate",
    QSO: "Quasar",
    QSO: "Quasar",
    "Q?": "Quasar candidate",
    Rad: "Radio Source",
    Radio: "Radio Source",
    rB: "Radio Burst",
    radioBurst: "Radio Burst",
    "RC*": "R CrB Variable",
    "RCrBV*": "R CrB Variable",
    "RC?": "R CrB Variable candidate",
    reg: "Region defined in the Sky",
    Region: "Region defined in the Sky",
    rG: "Radio Galaxy",
    RadioG: "Radio Galaxy",
    "RG*": "Red Giant Branch star",
    "RGB*": "Red Giant Branch star",
    "RB?": "Red Giant Branch star candidate",
    RNe: "Reflection Nebula",
    RefNeb: "Reflection Nebula",
    "Ro*": "Rotating Variable",
    "RotV*": "Rotating Variable",
    "Ro?": "Rotating Variable candidate",
    "RR*": "RR Lyrae Variable",
    RRLyrae: "RR Lyrae Variable",
    "RR?": "RR Lyrae Variable candidate",
    "RS*": "RS CVn Variable",
    "RSCVnV*": "RS CVn Variable",
    "RS?": "RS CVn Variable candidate",
    "RV*": "RV Tauri Variable",
    "RVTauV*": "RV Tauri Variable",
    "RV?": "RV Tauri Variable candidate",
    "S*": "S Star",
    "S*": "S Star",
    "S*?": "S Star candidate",
    "s*b": "Blue Supergiant",
    BlueSG: "Blue Supergiant",
    "s?b": "Blue Supergiant candidate",
    "s*r": "Red Supergiant",
    RedSG: "Red Supergiant",
    "s?r": "Red Supergiant candidate",
    "s*y": "Yellow Supergiant",
    YellowSG: "Yellow Supergiant",
    "s?y": "Yellow Supergiant candidate",
    "SB*": "Spectroscopic Binary",
    "SB*": "Spectroscopic Binary",
    "SB?": "Spectroscopic Binary candidate",
    SBG: "Starburst Galaxy",
    StarburstG: "Starburst Galaxy",
    SCG: "Supercluster of Galaxies",
    SuperClG: "Supercluster of Galaxies",
    "SC?": "Supercluster of Galaxies candidate",
    SFR: "Star Forming Region",
    StarFormingReg: "Star Forming Region",
    "sg*": "Evolved Supergiant",
    Supergiant: "Evolved Supergiant",
    "sg?": "Evolved Supergiant candidate",
    sh: "Interstellar Shell",
    HIshell: "Interstellar Shell",
    smm: "Sub-Millimetric Source",
    smmRad: "Sub-Millimetric Source",
    "SN*": "SuperNova",
    Supernova: "SuperNova",
    "SN?": "SuperNova candidate",
    SNR: "SuperNova Remnant",
    SNRemnant: "SuperNova Remnant",
    "SR?": "SuperNova Remnant candidate",
    "St*": "Stellar Stream",
    Stream: "Stellar Stream",
    "SX*": "SX Phe Variable",
    "SXPheV*": "SX Phe Variable",
    "Sy*": "Symbiotic Star",
    "Symbiotic*": "Symbiotic Star",
    "Sy?": "Symbiotic Star candidate",
    Sy1: "Seyfert 1 Galaxy",
    Seyfert1: "Seyfert 1 Galaxy",
    Sy2: "Seyfert 2 Galaxy",
    Seyfert2: "Seyfert 2 Galaxy",
    SyG: "Seyfert Galaxy",
    Seyfert: "Seyfert Galaxy",
    "TT*": "T Tauri Star",
    "TTauri*": "T Tauri Star",
    "TT?": "T Tauri Star candidate",
    ULX: "Ultra-luminous X-ray Source",
    ULX: "Ultra-luminous X-ray Source",
    "UX?": "Ultra-luminous X-ray Source candidate",
    UV: "UV-emission Source",
    UV: "UV-emission Source",
    "V*": "Variable Star",
    "Variable*": "Variable Star",
    "V*?": "Variable Star candidate",
    vid: "Underdense Region of the Universe",
    Void: "Underdense Region of the Universe",
    "WD*": "White Dwarf",
    WhiteDwarf: "White Dwarf",
    "WD?": "White Dwarf candidate",
    "WR*": "Wolf-Rayet",
    "WolfRayet*": "Wolf-Rayet",
    "WR?": "Wolf-Rayet candidate",
    "WV*": "Type II Cepheid Variable",
    Type2Cep: "Type II Cepheid Variable",
    "WV?": "Type II Cepheid Variable candidate",
    X: "X-ray Source",
    X: "X-ray Source",
    "XB*": "X-ray Binary",
    XrayBin: "X-ray Binary",
    "XB?": "X-ray Binary candidate",
    "Y*O": "Young Stellar Object",
    YSO: "Young Stellar Object",
    "Y*?": "Young Stellar Object candidate",
  };
  return lookup[slug] || "Unknown";
}

export function checkSSObjects(name) {
  const candidates = {
    sol: {
      title: "The Sun",
      layerName: "sun",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/The_Sun_by_the_Atmospheric_Imaging_Assembly_of_NASA%27s_Solar_Dynamics_Observatory_-_20100819.jpg/805px-The_Sun_by_the_Atmospheric_Imaging_Assembly_of_NASA%27s_Solar_Dynamics_Observatory_-_20100819.jpg",
    },
    sun: {
      layerName: "sun",
      title: "The Sun",
      desc: "The star at the center of our Solar System",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/The_Sun_by_the_Atmospheric_Imaging_Assembly_of_NASA%27s_Solar_Dynamics_Observatory_-_20100819.jpg/805px-The_Sun_by_the_Atmospheric_Imaging_Assembly_of_NASA%27s_Solar_Dynamics_Observatory_-_20100819.jpg",
    },
    mercury: {
      layerName: "mercury",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Mercury_in_true_color.jpg/768px-Mercury_in_true_color.jpg",
    },
    venus: {
      layerName: "venus",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/Venus_-_December_23_2016.png/768px-Venus_-_December_23_2016.png",
    },
    // earth: {
    //   layerName: "Bing Maps Aerial",
    //   image:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/The_Blue_Marble_%28remastered%29.jpg/768px-The_Blue_Marble_%28remastered%29.jpg",
    // },
    moon: {
      layerName: "moon",
      title: "The Moon",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/e/e4/Moon_merged_small.jpg",
    },
    mars: {
      layerName: "Visible Imagery",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/Tharsis_and_Valles_Marineris_-_Mars_Orbiter_Mission_%2830055660701%29.png/768px-Tharsis_and_Valles_Marineris_-_Mars_Orbiter_Mission_%2830055660701%29.png",
    },
    jupiter: {
      layerName: "jupiter",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/c/c1/Jupiter_New_Horizons.jpg",
    },
    callisto: {
      layerName: "callisto",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Callisto_-_July_8_1979_%2838926064465%29.jpg/743px-Callisto_-_July_8_1979_%2838926064465%29.jpg",
    },
    europa: {
      layerName: "europa",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/Europa_in_natural_color.png/768px-Europa_in_natural_color.png",
    },
    ganymede: {
      layerName: "ganymede",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Ganymede_-_Perijove_34_Composite.png/768px-Ganymede_-_Perijove_34_Composite.png",
    },
    io: {
      layerName: "io",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Io_highest_resolution_true_color.jpg/768px-Io_highest_resolution_true_color.jpg",
    },
    saturn: {
      layerName: "saturn",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Saturn_during_Equinox.jpg/1024px-Saturn_during_Equinox.jpg",
    },
    uranus: {
      layerName: "uranus",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Uranus_as_seen_by_NASA%27s_Voyager_2_%28remastered%29_-_JPEG_converted.jpg/768px-Uranus_as_seen_by_NASA%27s_Voyager_2_%28remastered%29_-_JPEG_converted.jpg",
    },
    neptune: {
      layerName: "neptune",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Neptune_-_Voyager_2_%2829347980845%29_flatten_crop.jpg/600px-Neptune_-_Voyager_2_%2829347980845%29_flatten_crop.jpg",
    },
    pluto: {
      layerName: "pluto",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Pluto_in_True_Color_-_High-Res.jpg/768px-Pluto_in_True_Color_-_High-Res.jpg",
    },
  };
  return candidates[name.toLowerCase()] || null;
}


export function manageStripe(userInfo) {
  window.open(
    `${
      process.env.REACT_APP_STRIPE_CUSTOMER_LINK
    }?prefilled_email=${encodeURIComponent(userInfo.email)}`,
    "_blank"
  );
}

export const stripeElementsAppearance =  {
  theme: "stripe",

  variables: {
    fontFamily: "'Helvetica', sans-serif",
    fontSizeBase: "1rem",
    fontSizeSm: "1rem",
    fontWeight: 400,
    fontWeightSm: 400,
    colorText: "rgb(33, 37, 41)",
    colorDanger: "#dc3545",
    fontSmooth: "auto",
    fontLineHeight: "1.5rem",
  },
  rules: {
    ".Input": {
      border: "1px solid #ced4da",
      boxShadow: "none",
      padding: "0.375rem 0.75rem",
      lineHeight: "1.5rem",
    },
    ".Input--invalid": {
      boxShadow: "none",
    },
    ".Input--invalid:focus": {
      border: "1px solid #dc3545",
      boxShadow: "0 0 0 0.25rem #dc354540",
    },
    ".Label": {
      lineHeight: "1.5rem",
      marginBottom: "0.5rem",
    },
    ".Error": {
      fontSize: "0.875rem",
      marginTop: "0.25rem",
    },
  },
};

export function pollForNewTier(userInfo, setUserInfo, newTier, onSuccess, onFailure, delay)
{
  delay = delay || 7500;
  if (userInfo.tier == newTier) {
    onSuccess();
    return;
  }
  APIGetUserInfo((ui) => {
    if (delay > 200000) {
      onFailure();
      return;
    }
    if (ui.tier == newTier) {
      setUserInfo(ui);
      onSuccess();
      return;
    } else {
      setTimeout(pollForNewTier, 2 * delay, userInfo, setUserInfo, newTier, onSuccess, onFailure, 2 * delay);
    }
  }, onFailure);
};


export function prepBrandProfileList(brandingProfiles, forcedList = null){
  const key = forcedList || (brandingProfiles.organization.length ? "organization" : "personal");
  const list = ((brandingProfiles || {})[key] || []);
  let out = list.filter(e => e.is_default);
  out = out.concat(list.filter(e => !e.is_default).sort((a,b)=> a.profile_name.localeCompare(b.profile_name)));
  return out.length? out : [{}];
}

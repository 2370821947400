import { useState, useContext, useEffect } from "react";
import { AvailableTags } from "../helper/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const TagNode = (props) => {
  const [expanded, setExpanded] = useState(true);
  const toggleExpand = () => {
    if (props.tag.children && props.tag.children.length) {
      setExpanded(!expanded);
    }
  };
  console.log(props.tag.slug+ " render");
  return (
    <>
      <div
        key={props.tag.slug}
        className="border rounded border-secondary d-flex"
        style={{ marginLeft:`${0.5+1.5*props.level}rem`, marginBottom:`0.25rem`, padding:"0.1rem" }}
        onClick={toggleExpand}
        tabIndex="-1"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            toggleExpand();
          }
        }}
      >
        {props.tag.children && props.tag.children.length ? (
          <FontAwesomeIcon
            icon={expanded ? faMinus : faPlus}
            className="icon-btnlike"
            size="xl"
            aria-label={expanded ? "colapse" : "expand"}
          />
        ) : null}
        <Link to={`/visuals/${props.tag.slug}`} className="ms-1 visualization_link">
          <h5>{props.tag.title}</h5>
        </Link>
        <p className="ms-auto">{props.tag.description}</p>
      </div>
      {expanded && props.tag.children.length
        ? props.tag.children.map((tag) => {
            return <TagNode tag={tag} level={props.level + 1} key={tag.slug} />;
          })
        : null}
    </>
  );
};

const TagListPage = (props) => {
  const { availableTags, setAvailableTags } = useContext(AvailableTags);
  const [tagTree, setTagTree] = useState([]);
  useEffect(() => {
    if (!availableTags) {
      return;
    }
    const tagDict = {};
    availableTags.forEach((tag) => {
      const newObj = Object.assign({ children: [] }, tag);
      tagDict[tag.slug] = newObj;
    });
    const topTags = [];
    availableTags.forEach((tag) => {
      if (tag.parent && tagDict[tag.parent]) {
        tagDict[tag.parent].children.push(tagDict[tag.slug]);
      } else {
        topTags.push(tagDict[tag.slug]);
      }
    });
    console.log(topTags);
    setTagTree(topTags);
  }, [availableTags]);
  return (
    <>
      <h3 className="TitleText">Snorkle Tags</h3>
      {tagTree.map((tag) => {
        return <TagNode tag={tag} level={0} key={tag.slug} />;
      })}
    </>
  );
};

export default TagListPage;

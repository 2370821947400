import { useState, useContext, useEffect } from "react";
import { UserInfo, VisualCache, Bundles } from "../helper/Context.js";
import { Form, Button, Col, Row, Modal, Image } from "react-bootstrap";
import { shallowCopy } from "../helper/Utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

import {
  APIGetVisualizations,
  APIGetOrgVisuals,
  APIAddEditBundle,
  APIDeleteBundle,
} from "../helper/APIFunctions.js";

const BundleEditForm = (props) => {
  const [currBundle, setCurrBundle] = useState(props.bundle);
  const [visArr, setVisArr] = useState(null);
  const { visCache, setVisCache } = useContext(VisualCache);
  const [searchTerm, setSearchTerm] = useState("");
  const [priceFormatError, setPriceFormatError] = useState(false);
  const [filteredVises, setFilteredVises] = useState([]);
  const [deleteVisId, setDeleteVisId] = useState(null);
  const [APIStatus, setAPIStatus] = useState({});
  const [rawPriceStr, setRawPriceStr] = useState(
    ((currBundle.price || 0) / 100).toFixed(2)
  );
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const updateProp = (propName, newValue) => {
    const temp = shallowCopy(currBundle);
    temp[propName] = newValue;
    if (propName == "price"){
      temp.annual_price = Math.round(12*0.7*newValue);
    }
    setCurrBundle(temp);
  };
  const addVisual = (id) => {
    const cVis = visCache[id];
    if (!cVis || cVis.premium_bundle) {
      return;
    }
    if (currBundle.visuals.some((v) => v.id == id)) {
      return;
    }
    const visList = [...currBundle.visuals];
    visList.push(cVis);
    console.log(visList);
    updateProp("visuals", visList);
    setSearchTerm("");
  };
  const createVisArr = () => {
    console.log("test");
    let arr = [];
    for (const [_, vis] of Object.entries(visCache)) {
      if (vis.id && !vis.premium_bundle) {
        arr.push(vis);
      }
    }
    setVisArr(arr);
  };
  useEffect(() => {
    if (searchTerm.length < 3) {
      return;
    }
    const urlSearch = searchTerm.match(
      /^(?:https?:\/\/)?(?:www\.)?vis\.snorkle\.io\/([a-zA-Z0-9_-]{43}=)/
    );
    if (urlSearch) {
      addVisual(urlSearch[1]);
    }
    setFilteredVises(
      visArr.filter(
        (vis) =>
          vis.title.toLowerCase().includes(searchTerm) ||
          vis.id.startsWith(searchTerm)
      )
    );
  }, [searchTerm]);
  return (
    <>
      {deleteVisId ? (
        <Modal show={true} onHide={() => setDeleteVisId(null)}>
          <Modal.Header closeButton closeVariant="white">Remove Visual</Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to remove "
              {currBundle.visuals.find((e) => e.id == deleteVisId).title}" from
              the bundle?
            </p>
            <Button
              onClick={() => {
                setDeleteVisId(null);
                const newVises = currBundle.visuals.filter(
                  (e) => e.id != deleteVisId
                );
                updateProp("visuals", newVises);
              }}
            >
              Remove
            </Button>
          </Modal.Body>
        </Modal>
      ) : null}
      {updateConfirm ? (
        <Modal
          show={true}
          onHide={() => (APIStatus.calling ? null : setUpdateConfirm(false))}
        >
          <Modal.Header closeButton closeVariant="white">Update Bundle</Modal.Header>
          <Modal.Body>
            {APIStatus.error ? (
              <p className="text-danger">{APIStatus.error}</p>
            ) : null}
            {APIStatus.success ? (
              <p className="text-success">Bundle Updated</p>
            ) : null}
            <p>Are you sure you want to update this bundle?</p>
            <Button
              disabled={APIStatus.calling}
              onClick={() => {
                setAPIStatus({ calling: true });
                APIAddEditBundle(
                  currBundle,
                  (res) => {
                    setAPIStatus({ success: true });
                    setTimeout(props.onBundleEdited, 1000, res);
                  },
                  (err) => {
                    setAPIStatus({ error: err.message || err.toString() });
                  }
                );
              }}
            >
              Update
            </Button>
          </Modal.Body>
        </Modal>
      ) : null}

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setUpdateConfirm(true);
        }}
      >
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            value={currBundle.name}
            disabled={APIStatus.calling}
            onChange={(e) => {
              updateProp("name", e.target.value);
            }}
          />
        </Form.Group>
        <div className="d-flex">
          <Form.Group className="w-75 me-2">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              value={currBundle.description}
              disabled={APIStatus.calling}
              onChange={(e) => {
                updateProp("description", e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="w-25 ms-2">
            <Form.Label>Price</Form.Label>
            <Form.Control
              value={rawPriceStr}
              isInvalid={priceFormatError}
              disabled={APIStatus.calling}
              onChange={(e) => {
                const v = e.target.value;
                setRawPriceStr(v);
                if (!v || !v.match(/^\$?\d+\.?\d{0,2}$/)) {
                  setPriceFormatError(true);
                  return;
                }
                setPriceFormatError(false);
                const p = Math.round(parseFloat(v.replace("$", "")) * 100);
                updateProp("price", p);
              }}
            />
            /Month ($
            {Math.floor(currBundle.annual_price / 100).toString() +
              "." +
              (Math.floor(currBundle.annual_price) % 100)
                .toString()
                .padStart(2, "0")}
            /Year)
          </Form.Group>
        </div>
        <Form.Group>
          <Form.Label>Add New Visual</Form.Label>
          <Row>
            <Col xs={12} md={6}>
              <Form.Control
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  console.log(visCache, visArr);
                  if (visCache && !visArr) {
                    createVisArr();
                  }
                }}
                disabled={APIStatus.calling}
                placeholder="Search visuals by title or id prefix, or copy the url directly."
              />
            </Col>
            {searchTerm && searchTerm.length >= 3 ? (
              <Col xs={12} md={6}>
                <Form.Select
                  value=""
                  disabled={!filteredVises.length}
                  onChange={(e) => {
                    if (!e.target.value) {
                      return;
                    }
                    addVisual(e.target.value);
                  }}
                >
                  {filteredVises.length ? (
                    <>
                      <option>Select Visual To Add</option>
                      {filteredVises.map((filteredVis) => {
                        return (
                          <option value={filteredVis.id} key={filteredVis.id}>
                            {filteredVis.thumbnail ? "" : "**NO IMAGE** "}
                            {filteredVis.title.substring(0, 50) +
                              (filteredVis.title.length > 50 ? "..." : "") +
                              " (" +
                              filteredVis.id.substring(0, 8) +
                              "...)"}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <option>No Matching Visuals</option>
                  )}
                </Form.Select>
              </Col>
            ) : null}
          </Row>
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Included Visuals</Form.Label>
          <Row className="mx-2">
            {currBundle.visuals.map((e) => (
              <div
                className="border rounded border-secondary col-6 col-md-3"
                key={e.id}
                style={{ position: "relative" }}
              >
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="text-danger"
                  title={`Remove "${e.title}"`}
                  size="lg"
                  style={{
                    position: "absolute",
                    right: "0.5rem",
                    top: "0.5rem",
                  }}
                  onClick={() => setDeleteVisId(e.id)}
                />
                <h5>
                  {e.title.substring(0, 30) +
                    (e.title.length > 30 ? "..." : "") +
                    "(" +
                    e.id.substring(0, 8) +
                    "...)"}
                </h5>
                <p>
                  {e.description.substring(0, 150) +
                    (e.description.length > 150 ? "..." : "")}
                </p>
                <p>
                  <a
                    href={e.visualization_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Visual Link
                  </a>
                </p>
                <Image src={e.thumbnail} className="w-50" />
              </div>
            ))}
          </Row>
        </Form.Group>
        <Button
          type="submit"
          disabled={
            APIStatus.calling ||
            priceFormatError ||
            !currBundle.name ||
            !currBundle.description ||
            !currBundle.visuals.length
          }
        >
          Update Bundle
        </Button>
      </Form>
    </>
  );
};

const BundleEditPage = (props) => {
  const { bundles, setBundles } = useContext(Bundles);
  const { visCache, setVisCache } = useContext(VisualCache);
  const { userInfo, setUserInfo } = useContext(UserInfo);
  const [publishStatus, setPublishStatus] = useState({});
  const [deleteBundleId, setDeleteBundleId] = useState(null);
  const [deleteBundleStatus, setDeleteBundleStatus] = useState({});
  const [editBundle, setEditBundle] = useState(null);
  useEffect(() => {
    if (!visCache || !visCache.personalVises) {
      APIGetVisualizations(
        (res) => {
          const t = shallowCopy(visCache);
          for (const vis of res.visuals || res) {
            t[vis.id] = vis;
          }
          t.personalVises = true;
          setVisCache(t);
        },
        () => {}
      );
    }
    if (
      userInfo.organization_permissions &&
      (!visCache || !visCache.orgVises)
    ) {
      APIGetOrgVisuals(
        (res) => {
          const t = shallowCopy(visCache);
          for (const vis of res.visuals || res) {
            t[vis.id] = vis;
          }
          t.orgVises = true;
          setVisCache(t);
        },
        () => {}
      );
    }
  }, []);
  if (!bundles) {
    return <></>;
  }
  return (
    <>
      {editBundle ? (
        <BundleEditForm
          bundle={editBundle}
          onBundleEdited={(newBundle) => {
            const temp = bundles.filter((b) => b.id != newBundle.id);
            temp.push(newBundle);
            console.log(temp);
            setBundles(temp);
            setEditBundle(null);
          }}
        />
      ) : (
        <>
          {deleteBundleId ? (
            <Modal
              show={true}
              onHide={() => {
                if (!deleteBundleStatus.working) {
                  setDeleteBundleStatus({});
                  setDeleteBundleId(null);
                }
              }}
            >
              <Modal.Header closeButton closeVariant="white">Delete Bundle</Modal.Header>
              <Modal.Body>
                {deleteBundleStatus.error ? (
                  <p className="text-danger">{deleteBundleStatus.error}</p>
                ) : null}
                {deleteBundleStatus.success ? (
                  <p className="text-success">Bundle Deleted</p>
                ) : null}

                <p>
                  Are you sure you want to delete "
                  {bundles.find((e) => e.id == deleteBundleId).name}"?
                </p>
                <Button
                  onClick={() => {
                    APIDeleteBundle(
                      deleteBundleId,
                      () => {
                        setDeleteBundleStatus({ success: true });
                        setTimeout(() => {
                          setDeleteBundleId(null);
                          setDeleteBundleStatus({});
                          setBundles(
                            bundles.filter((b) => b.id != deleteBundleId)
                          );
                        }, 1000);
                      },
                      (err) => {
                        setDeleteBundleStatus({
                          error: err.message || err.toString(),
                        });
                      }
                    );
                    setDeleteBundleStatus({ working: true });
                  }}
                  disabled={deleteBundleStatus.working}
                >
                  Remove
                </Button>
              </Modal.Body>
            </Modal>
          ) : null}
          <h4>Pending Bundles</h4>
          {bundles
            .filter((b) => b.status != "published")
            .map((bundle) => (
              <>
                <div
                  key={bundle.id}
                  className="border border-rounded border-secondary"
                  style={{ position: "relative" }}
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    className="text-danger"
                    title={`Delete "${bundle.name}"`}
                    size="lg"
                    style={{
                      position: "absolute",
                      right: "0.5rem",
                      top: "0.5rem",
                    }}
                    onClick={() => setDeleteBundleId(bundle.id)}
                  />
                  <h6>{bundle.name}</h6>
                  <p>{bundle.description}</p>
                  <p>
                    Price:{" "}
                    <ul>
                      <li>
                        {Math.floor(bundle.price / 100).toString() +
                          "." +
                          (bundle.price % 100).toString().padStart(2, "0")}
                        /Month
                      </li>
                      <li>
                        {Math.floor(bundle.annual_price / 100).toString() +
                          "." +
                          (bundle.annual_price % 100)
                            .toString()
                            .padStart(2, "0")}
                        /Year
                      </li>
                    </ul>
                  </p>
                  <Button onClick={() => setEditBundle(bundle)}>
                    Edit Bundle
                  </Button>
                  <Button
                    disabled={(publishStatus[bundle.id] || {}).sending}
                    onClick={() => {
                      APIAddEditBundle(
                        { id: bundle.id, publish: true },
                        () => {
                          setBundles(
                            bundles.map((b) => {
                              if (b.id == bundle.id) {
                                const t = shallowCopy(b);
                                t.status = "published";
                                return t;
                              } else {
                                return b;
                              }
                              const t = shallowCopy(publishStatus);
                              t[bundle.id] = { success: true };
                              setPublishStatus(t);
                            })
                          );
                        },
                        (err) => {
                          const t = shallowCopy(publishStatus);
                          t[bundle.id] = { error: err };
                          setPublishStatus(t);
                        }
                      );
                      const t = shallowCopy(publishStatus);
                      t[bundle.id] = { sending: true };
                      setPublishStatus(t);
                    }}
                  >
                    Publish Bundle
                  </Button>
                  {(publishStatus[bundle.id] || {}).success ? (
                    <span className="text-success">Success!</span>
                  ) : null}
                  {(publishStatus[bundle.id] || {}).error ? (
                    <span className="text-danger">
                      {publishStatus[bundle.id].error}
                    </span>
                  ) : null}
                </div>
              </>
            ))}
          <Button
            onClick={() =>
              setEditBundle({
                price: 0,
                visuals: [],
                description: "",
                name: "",
                id: null,
              })
            }
          >
            Create Bundle
          </Button>
          <h4>Published Bundles</h4>
          {bundles
            .filter((b) => b.status == "published")
            .map((bundle) => (
              <>
                <div
                  key={bundle.id}
                  className="border border-rounded border-secondary"
                  style={{ position: "relative" }}
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    className="text-danger"
                    title={`Delete "${bundle.name}"`}
                    size="lg"
                    style={{
                      position: "absolute",
                      right: "0.5rem",
                      top: "0.5rem",
                    }}
                    onClick={() => setDeleteBundleId(bundle.id)}
                  />
                  <h6>{bundle.name}</h6>
                  <p>{bundle.description}</p>
                  <p>
                    Price:{" "}
                    <ul>
                      <li>
                        {Math.floor(bundle.price / 100).toString() +
                          "." +
                          (bundle.price % 100).toString().padStart(2, "0")}
                        /Month
                      </li>
                      <li>
                        {Math.floor(bundle.annual_price / 100).toString() +
                          "." +
                          (bundle.annual_price % 100)
                            .toString()
                            .padStart(2, "0")}
                        /Year
                      </li>
                    </ul>
                  </p>
                </div>
              </>
            ))}
        </>
      )}
    </>
  );
};

export default BundleEditPage;

import { Row, Col } from "react-bootstrap";
import FeaturedViewers from "../components/FeaturedViewers";
import { Link } from "react-router-dom";
const UseCasesPage = (props) => {
    return (
        <>

            <Row className="justify-content-center">

                <div className="main-container-narrower">


                    <p></p>
                    <h2 className="TitleText mb-4">Deliver verified, interactive visual explainers</h2>
                    <p> Whether it's for the purpose of giving your practice's patient experience a 3D makeover, supplementing teaching, or creating marketing materials, build amazing, accurate scientific experiences.
                    </p>
                    <center>
                        <img src="https://snorkle-output-files.s3.amazonaws.com/mobiledisplays_usecase_all.png" width="100%"></img>

                    </center>

                    <p></p>
                    <h2 className="TitleText mb-4">Embed branded, interactive content into your website</h2>
                    <p></p> Offer engaging, interactive visuals on your group's website. Each Snorkle visual can be embedded simply by copying and pasting html code snippets.
                        <center>
                            <img src="https://snorkle-output-files.s3.amazonaws.com/lab_webpage_embed-use-case-2monitor.png" width="100%"></img>

                        </center>


                        <p></p>
                        <p></p>
                        <div>
                            <p></p>
                            <h2 className="TitleText mb-4">Snorkle<sup><i>Labs</i></sup> for education and presentations</h2>
                            <p>Integrating 3D digital models into anatomy courses has been shown to increase student engagement.<sup>1</sup> You can convert traditional slide decks into interactive, mobile-activating experiences using either Snorkle's slides function or through a <Link to="/powerpoint">free add-in</Link> for Microsoft PowerPoint. For each visual created, Snorkle automatically generates QR codes that link to mobile-friendly, interactive visuals that give attendees the same amount of control and inspection capabilities as the presenter.
                            </p>
                            <center>
                                <img src="https://snorkle-output-files.s3.amazonaws.com/presentation_usecase.png" width="80%"></img>
                            </center>
                        </div>
                        <p></p>

                        <p></p>
                        <div>
                            <h2 className="TitleText mb-4">Showcase your research</h2>
                            <p> Don't let the copy/paste bottleneck prevent you from showing off your hard work. Snorkle<sup><i>Labs</i></sup> offers your audience interactivity that normally requires specialty viewers. Multi-channel images? DICOM and 3D file formats? Large, dynamically-loading pathology images? We've got you covered. Snorkle<sup><i>Labs</i></sup> also offers quick links for sharing your visuals to social media.
                            </p>
                            <center>
                                <img src="https://snorkle-output-files.s3.amazonaws.com/brainslice_small.png" width="100%"></img>
                                <img src="https://snorkle-output-files.s3.amazonaws.com/tweet_image.png" width="70%"></img>
                            </center>
                        </div>
                        <p></p>
                        <p></p>
                        <div>
                            <h2 className="TitleText mb-4">AI-assisted search for 3D models or explainers</h2>
                            <p>Snorkle's AI-assisted search function lets you find interactive visual experiences that can be used for any of the use cases described here. Explore any topic in science or medicine, ranging from healthcare to astronomy!
                            </p>
                            <center>
                                <img src="https://snorkle-output-files.s3.amazonaws.com/image-search.png" width="60%"></img>
                            </center>
                        </div>
                        <p></p>
                        <p></p>


                        <p></p>
                        <h2 className="TitleText mb-4">Make and edit 3D auto-playing content for standalone displays</h2>

                        <div>
                            <p>Using Snorkle's presentation auto-play feature, you can curate visuals and offer a tour of landmarks annotations. Create or your own visuals and annotations or take advantage of pre-arranged visual asset bundles.</p>
                            <center>
                                <img src="https://snorkle-output-files.s3.amazonaws.com/waiting-room-screen.png" alt="Your Image" width="75%"></img>
                            </center>
                        </div>
                        <div>

                            {/* <iframe src="https://vis.snorkle.io/QO3GAQaKDDB0-DHMyz6PTTXEKf-14j2xF5vj21XOdcU=.html" width="100%" height="100%"></iframe> */}

                        </div>
                        <p></p>
                        <p></p>
                        <h4>References</h4>
                        <p>1. Erolin, C. (2019). Interactive 3D Digital Models for Anatomy and Medical Education. In: Rea, P. (eds) Biomedical Visualisation. Advances in Experimental Medicine and Biology, vol 1138.
                        </p>

                        <p></p>

                </div>
            </Row >
        </>
    );
};

export default UseCasesPage;

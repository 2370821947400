import { UserInfo } from "../helper/Context";
import { Row, Col, Button, Breadcrumb } from "react-bootstrap";
import { Link, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-regular-svg-icons";
import {
  faCirclePlus,
  // faGear,
  // faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import ProfilePage from "./ProfilePage";
import UploadPage from "./UploadPage";
import SettingsPage from "./SettingsPage";
import DeletedVisualsPage from "./DeletedVisualsPage";
const DashboardBody = (props) => {
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/home" }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/dashboard" }} active>
          Dashboard
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="TitleText mb-4">My Dashboard</h1>
      <UserInfo.Consumer>
      {({ userInfo, setUserInfo }) => {
       return (userInfo.tier === "free" ? <h5 className="text-center text-muted font-italic mb-4">Free plan: 10 visuals max, links persist 30 days.</h5> : null)
      }}
      </UserInfo.Consumer>
      <Row>
        <Col xs={12} sm={6}>
          <Link to="myvisuals">
            <Button className="w-100 dashboard-item">
              <FontAwesomeIcon icon={faFolder} size="10x" />
              <h4 className="text-center mt-4">My Visuals</h4>
            </Button>
          </Link>
        </Col>
        <Col xs={12} sm={6}>
          <Link to="upload">
            <Button className="w-100 dashboard-item">
              <FontAwesomeIcon icon={faCirclePlus} size="10x" />
              <h4 className="text-center mt-4">Add Visual</h4>
            </Button>
          </Link>
        </Col>
        {/* <Col xs={12} sm={6} lg={3}>
          <Link to="/dashboard/search">
            <Button className="w-100 dashboard-item">
              <FontAwesomeIcon icon={faMagnifyingGlass} size="10x" />
              <h4 className="text-center mt-4">Find Visualization</h4>
            </Button>
          </Link>
        </Col>
        <Col xs={12} sm={6} lg={3}>
          <Link to="/dashboard/settings">
            <Button className="w-100 dashboard-item">
              <FontAwesomeIcon icon={faGear} size="10x" />
              <h4 className="text-center mt-4">Settings</h4>
            </Button>
          </Link>
        </Col> */}
      </Row>
    </>
  );
};

const DashboardPage = (props) => {
  return (
    <Routes>
      <Route path="myvisualizations" element={<ProfilePage isOrg={false} />} />
      <Route path="myvisuals" element={<ProfilePage isOrg={false} />} />
      <Route
        path="upload/*"
        element={
          <UploadPage
            isLoggedIn={true}
            breadcrumbs={[
              { name: "Home", url: "/home" },
              { name: "Dashboard", url: "/dashboard" },
              { name: "Create Visual", url: "/dashboard/upload" },
            ]}
          />
        }
      />
      <Route path="settings" element={<SettingsPage />} />
      <Route path='*' element={<DashboardBody />} />
    </Routes>
  );
};

export default DashboardPage;

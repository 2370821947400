export const pdbValidator = (url) => {
  if (!url) {
    return null;
  }
  const pdbUrlRegex = /^(?:https?:\/\/)?(?:www.)?rcsb.org\/[-0-9a-zA-Z]+\/(\d[0-9A-Za-z]{3})(?:\/|#|$)/;
  const codeRegex = /^\d[0-9A-Za-z]{3}$/;
  const prefixRegex = /^pdb(?::\s*)(\d[0-9A-Z]{3})$/i;
  if (codeRegex.test(url) && /[a-zA-Z]/.test(url)) {
    //For stand alone code check for at least 1 letter to separate PDB from pubchem.
    return url.toUpperCase();
  }
  const match1 = url.match(pdbUrlRegex);
  if (match1 !== null) {
    return match1[match1.length - 1].toUpperCase();
  }
  const match2 = url.match(prefixRegex);
  if (match2) {
    return match2[1].toUpperCase();
  }
  return null;
};

export const pubchemValidator = (url) => {
  if (!url) {
    return null;
  }
  const pubchemUrlRegex = /^(?:https?:\/\/)?(?:www.)?pubchem.ncbi.nlm.nih.gov\/compound\/(\d+)(?:[/&#?]|$)/;
  const pubchemcodeRegex = /^\d+$/;
  const prefixRegex = /^cid(?::\s*)(\d+)$/i;
  if (pubchemcodeRegex.test(url)) {
    return url;
  }
  const match1 = url.match(pubchemUrlRegex);
  if (match1 !== null && match1.length) {
    return match1[match1.length - 1].toUpperCase();
  }
  const match2 = url.match(prefixRegex);
  if (match2) {
    return match2[1];
  }
  return null;
};

const sketchfabValidator = (url) => {
  if (!url) {
    return null;
  }
  const sketchfabPageUrlRegex = /^(?:https?:\/\/)?(?:www\.)?sketchfab\.com\/3d-models\/(?:[-0-9a-zA-Z]+-)?([0-9a-f]{32})(?:[/&#?]|$)/;
  const sketchfabEmbedUrlRegex = /^(?:https?:\/\/)?(?:www\.)?sketchfab\.com\/models\/([0-9a-f]{32})(?:[/&#?]|$)/;
  const sketchfabIdRegex = /^([0-9a-f]{32})$/;

  if (sketchfabIdRegex.test(url)) {
    return url;
  }

  const match1 = url.match(sketchfabPageUrlRegex);
  if (match1 !== null) {
    return match1[match1.length - 1];
  }

  const match2 = url.match(sketchfabEmbedUrlRegex);
  if (match2 !== null) {
    return match2[match2.length - 1];
  }
  return null;
};

const snorkleValidator = (url) => {
  if (!url) {
    return null;
  }

  const snorkleIdRegex = /^[0-9a-zA-Z_-]{43}=$/;
  const visSnorkleRegex = /^https?:\/\/vis.snorkle.io\/([0-9a-zA-Z_-]{43}=)(?:[/&#\.?]|$)/;
  const awsSnorkleRegex = /^https?:\/\/snorkle-output-files.s3.amazonaws.com\/([0-9a-zA-Z_-]{43}=)(?:[/&#?.]|$)/;

  if (snorkleIdRegex.test(url)) {
    return url;
  }

  const match1 = url.match(visSnorkleRegex);
  if (match1 !== null) {
    return match1[match1.length - 1];
  }

  const match2 = url.match(awsSnorkleRegex);
  if (match2 !== null) {
    return match2[match2.length - 1];
  }
  return null;
};

const webImageValidator = (url) => {
  if (!url) {
    return null;
  }
  const urlRegex = /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)\.(?:(jpg)|(png)|(jpeg))$/;
  const match1 = url.match(urlRegex);
  if (match1 !== null && match1.length) {
    return url;
  }
  return null;
};

const vimeoValidator = (url) => {
  if (!url) {
    return null;
  }
  const vimeoRegex1 = /^(?:https?:\/\/)?(?:www\.)?vimeo.com\/(?:channels\/.*?\/)?(\d+)$/;
  const vimeoRegex2 = /^(?:https?:\/\/)?player.vimeo.com\/video\/(\d+)\/?(?:\?(?:.*&)?h=([0-9a-f]+)(?:&|$))?/;
  const m1 = url.match(vimeoRegex1);
  if (m1) {
    return { id: m1[1], hash: null };
  }
  const m2 = url.match(vimeoRegex2);
  if (m2) {
    return { id: m2[1], hash: m2[2] };
  }
  return null;
};

export const fullValidation = (text, searchSnorkle) => {
  const localSketchfab = sketchfabValidator(text);
  if (localSketchfab) {
    return new Promise((resolve, reject) =>
      resolve({ id: localSketchfab, type: "sketchfab", display: "Sketchfab" })
    );
  }
  const localPdb = pdbValidator(text);
  if (localPdb) {
    return new Promise((resolve, reject) =>
      resolve({ id: localPdb, type: "pdb", display: "Protein Data Bank" })
    );
  }

  const localPubchem = pubchemValidator(text);
  if (localPubchem) {
    return new Promise((resolve, reject) =>
      resolve({ id: localPubchem, type: "pubchem", display: "NIH PubChem" })
    );
  }

  const localSnorkle = snorkleValidator(text);
  if (localSnorkle) {
    return new Promise((resolve, reject) => searchSnorkle ?
      resolve({
        id: localSnorkle,
        type: "snorkle",
        display: "Snorkle Visual",
      }) : reject("Failed validation check")
    );
  }


  const localVimeo = vimeoValidator(text);
  if (localVimeo) {
    return new Promise((resolve, reject) =>
      resolve({
        id: localVimeo.id,
        type: "vimeo",
        display: "Vimeo Video",
        hash: localVimeo.hash || null,
      })
    );
  }

  const localWebImage = webImageValidator(text);
  if (localWebImage) {
    return new Promise((resolve, reject) =>
      resolve({
        id: localWebImage,
        type: "simple_image",
        display: "Standard Web Image",
      })
    );
  }

  // searchPdb(text);
  return new Promise((resolve, reject) => reject("Failed validation check"));
};

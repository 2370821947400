import React, { useState, useEffect, useContext } from "react";
import { UserInfo, AvailableTags, VisualCache } from "../helper/Context";
import {
  Button,
  Spinner,
  Breadcrumb,
  Form,
  Row,
  Col,
  Tabs,
  Tab,
  Image,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInRouterContext, useRoutes, useSearchParams } from "react-router-dom";
import {
  faMagnifyingGlass,
  faArrowUp,
  faFolderPlus,
  faFolderClosed,
  faFolderOpen,
  faTruckMedical,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashCan, faImages } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import {
  APIGetVisualizations,
  APIGetOrgVisuals,
  APIEditVisualizations,
  APICreateVisFolder,
  APIEditVisualFolder,
  APIDeleteVisualFolder,
} from "../helper/APIFunctions";
import VisCard from "../components/VisCard";
import Presentation from "../components/Presentations";
import PresentationEditCanvas from "../components/PresentationEditCanvas";
import {
  getVisualLimit,
  canSortVisuals,
  getPresentationLimit,
} from "../helper/BusinessLogic";
import { shallowCopy } from "../helper/Utils";
import { filter } from "jszip";
const AddFolderBtn = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  return (
    <Button
      style={{
        backgroundColor: error ? "rgb(var(--bs-danger-rgb))" : "",
      }}
      id="add-folder-btn"
      title="Add New Visual Folder"
      disabled={disabled}
      onClick={(e) => {
        if (disabled) {
          return;
        }
        setDisabled(true);
        APICreateVisFolder(
          (body) => {
            props.onFolderAdd(body);
            setDisabled(false);
          },
          () => {
            setDisabled(false);
          },
          props.isOrg
        );
      }}
    >
      New Folder
      {/* <FontAwesomeIcon icon={faFolderPlus} size="lg" className="ms-2"/> */}
    </Button>
  );
};

const VisFolderHeader = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(props.title);
  useEffect(() => {
    setEditMode(false);
    setTitle(props.title);
  }, [props.title]);
  const onEditDone = () => {
    if (!title) {
      setTitle(props.title);
    } else if (title != props.title) {
      APIEditVisualFolder(
        props.id,
        { title: title },
        () => {
          props.onNewTitle(props.id, title);
        },
        (e) => { },
        props.isOrg
      );
    }
    setEditMode(false);
  };
  const deleteFolder = () => {
    APIDeleteVisualFolder(
      props,
      () => { },
      () => { },
      props.isOrg
    );
    props.onFolderDelete(props.id);
  };
  return (
    <>
      {editMode ? (
        <div
          style={{
            zIndex: 2000000 - 1,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          onClick={(e) => {
            e.stopPropagation();
            const sx = e.clientX;
            const sy = e.clientY;
            if (e.target != e.currentTarget) {
              return;
            }
            onEditDone();
            setTimeout(() => {
              const d = document.elementFromPoint(sx, sy);
              if (d.click) {
                d.click();
              }
            }, 0);
          }}
        />
      ) : null}

      <span
        className="flex-grow-1"
        onClick={(e) => {
          if (editMode) {
            e.stopPropagation();
          }
          if (e.detail == 2 && props.canEdit) {
            e.stopPropagation();
            editMode ? onEditDone() : setEditMode(true);
          }
        }}
      >
        {editMode ? (
          <>
            <span
              className="d-flex w-100"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  event.stopPropagation();
                  onEditDone();
                }
              }}
              draggable //Do this to allow text highlighting
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              style={{ zIndex: 2000000 }}
            >
              <Form.Control
                value={title}
                plaintext
                onBlur={onEditDone}
                style={{
                  width: "unset",
                  display: "inline",
                  padding: 0,
                  backgroundColor: "#f2f2f2", //TODO
                  borderBottom: "1px solid #ced4da",
                  lineHeight: 1,
                  zIndex: 2000000,
                }}
                onChange={(e) => setTitle(e.target.value)}
              ></Form.Control>{" "}
              {props.canDelete ? (
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="text-danger icon-btnlike ms-auto"
                  aria-label="delete"
                  role="button"
                  tabIndex="0"
                  style={{ zIndex: 999 }}
                  title={`Delete folder "${title}". Contents wil not be deleted`}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    deleteFolder();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      deleteFolder();
                    }
                  }}
                />
              ) : null}
            </span>
          </>
        ) : (
          title
        )}
      </span>
    </>
  );
};
const highlightTextFunc = (text, highlightPhrase) => {
  const lower = text.toLowerCase();
  const inds = [];
  let currInd = lower.indexOf(highlightPhrase);
  if (highlightPhrase && currInd >= 0) {
    inds[0] = currInd;
    currInd = lower.indexOf(
      highlightPhrase,
      inds[0] + highlightPhrase.length
    );
    while (currInd >= 0) {
      inds.push(currInd);
      currInd = lower.indexOf(
        highlightPhrase,
        inds[inds.length - 1] + highlightPhrase.length
      );
    }
    let output = "";
    output += text.substring(0, inds[0]);
    for (let i = 0; i < inds.length - 1; i++) {
      output += (
        `<mark class="p-0 m-0 bg-warning">${text.substring(inds[i], inds[i] + highlightPhrase.length)}</mark>`
      );
      output += (
        text.substring(inds[i] + highlightPhrase.length, inds[i + 1])
      );
    }
    let i = inds.length - 1;
    output += (
      `<mark class="p-0 m-0  bg-warning">${text.substring(inds[i], inds[i] + highlightPhrase.length)}</mark>`
    );
    output += (text.substring(inds[i] + highlightPhrase.length));
    return output;
  } else {
    return text;
  }
};
const Folder = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div
      id={"folder-container-" + props.visObject.id}
      onDrop={(event) => {
        if (
          props.isOrg &&
          !props.userInfo.organization_permissions.edit_visuals
        ) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        const data = event.dataTransfer.getData("text/plain");
        if (!data) {
          return;
        }
        const [type, id] = data.split(":");
        if (!type || !id) {
          return;
        }
        if (type != "visual" && type != "folder") {
          return;
        }
        const dropNode = event.currentTarget.querySelector(".vis-drop-target");
        const dropLocation = event.nativeEvent.offsetY + event.target.offsetTop;
        let upperLim = null;
        let lowerLim = null;
        for (const childElement of dropNode.children) {
          const d = childElement.dataset;
          if (!d || d.objType != type || d.objId == id) {
            continue;
          }
          const currPriority = parseFloat(d.priority);
          if (childElement.offsetTop + 30 > dropLocation) {
            upperLim =
              upperLim === null
                ? currPriority
                : Math.min(upperLim, currPriority);
          } else {
            lowerLim =
              lowerLim === null
                ? currPriority
                : Math.max(lowerLim, currPriority);
          }
        }
        let newPriority = props.defaultPriority;
        if (lowerLim && !upperLim) {
          newPriority = lowerLim + 10;
        } else if (!lowerLim && upperLim) {
          newPriority = upperLim - 10;
        } else if (lowerLim && upperLim) {
          newPriority = (upperLim + lowerLim) / 2;
        }
        if (type == "visual") {
          const oldVis = props.processedVisuals.idLookup[id];
          const newVis = shallowCopy(oldVis);
          newVis.priority = newPriority;
          newVis.folder_id = props.visObject.id;
          if (oldVis.premium_bundle && newVis.folder_id != oldVis.folder_id) {
            return;
          }
          const arr = props.visualizations.filter((vis) => vis.id != id);
          arr.push(newVis);
          props.setVisualizations(arr);
          APIEditVisualizations(
            oldVis,
            null,
            null,
            null,
            null,
            () => { },
            (e) => { },
            props.isOrg,
            { folder_id: newVis.folder_id, priority: newVis.priority }
          );
        } else if (type == "folder") {
          const newFolder = shallowCopy(props.processedVisuals.idLookup[id]);
          if (!newFolder || id == props.visObject.id) {
            return;
          }
          if (
            newFolder.parent == props.visObject.id &&
            newFolder.priority == newPriority
          ) {
            return;
          }
          if (props.visObject.id) {
            const ref = {};
            ref[id] = true;
            let ci = props.visObject.id || null;
            while (ci && !ref[ci]) {
              ref[ci] = true;
              ci = props.processedVisuals.idLookup[ci].parent || null;
            }
            if (ci) {
              return;
            }
          }
          APIEditVisualFolder(
            id,
            { priority: newPriority, parent: props.visObject.id },
            () => { },
            (e) => { }
          );
          newFolder.parent = props.visObject.id;
          newFolder.priority = newPriority;
          props.setFolders(
            props.folders.map((f) => (f.id == id ? newFolder : f))
          );
        }
      }}
      onDragOver={(event) => {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = "move";
      }}
      draggable={
        props.visObject.id &&
        ((!props.isOrg && canSortVisuals(props.userInfo.tier)) ||
          (props.isOrg &&
            props.userInfo.organization_permissions &&
            (props.userInfo.organization_permissions.edit_visuals ||
              props.userInfo.organization_permissions.add_presentations ||
              props.userInfo.organization_permissions.edit_presentations)))
      }
      onDragStart={(event) => {
        event.dataTransfer.setData(
          "text/plain",
          "folder:" + props.visObject.id
        );
        event.dataTransfer.effectAllowed = "move";
        event.stopPropagation();
      }}
      onClick={(e) => {
        if (e.currentTarget != e.target) {
          return;
        }
        e.stopPropagation();
        e.preventDefault();
        if (
          e.nativeEvent.offsetX - e.target.offsetLeft >
          parseFloat(getComputedStyle(e.currentTarget).fontSize)
        ) {
          return;
        }
        setCollapsed(!collapsed);
      }}
      data-priority={(props.visObject.priority || 0).toString()}
      data-obj-id={props.visObject.id}
      data-obj-type="folder"
      className={
        props.visObject.title ? "py-2 mb-4 portfolio-folder-border" : null
      }
    >
      {props.visObject.title ? (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setCollapsed(!collapsed);
          }}
          className="mb-4"
        >
          <h2 className="m-2 d-flex" id={`vis-folder-${props.visObject.id}`}>
            <FontAwesomeIcon
              icon={collapsed ? faFolderClosed : faFolderOpen}
              style={{
                color: props.visObject.premium_bundle
                  ? "darkgreen"
                  : "var(--text-color)",
                opacity: 0.85,
              }}
              size="xl"
              className="align-middle me-4"
            />
            <VisFolderHeader
              title={props.visObject.title}
              id={props.visObject.id}
              isOrg={props.isOrg}
              canDelete={!props.visObject.premium_bundle}
              canEdit={
                (!props.isOrg && canSortVisuals(props.userInfo.tier)) ||
                (props.userInfo.organization_permissions &&
                  props.userInfo.organization_permissions.edit_visuals)
              }
              onNewTitle={(id, newTitle) =>
                props.setFolders(
                  props.folders.map((f) => {
                    if (f.id !== id) {
                      return f;
                    }
                    const newF = shallowCopy(f);
                    newF.title = newTitle;
                    return newF;
                  })
                )
              }
              onFolderDelete={(id) => {
                props.setFolders(props.folders.filter((f) => f.id != id));
              }}
            />
            {getPresentationLimit(props.userInfo.tier, props.isOrg) === null &&
              (!props.isOrg ||
                props.userInfo.organization_permissions.add_presentations) ? (
              <button
                style={{ backgroundColor: "rgba(0,0,0,0)", border: "none" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.setEditPresentation({
                    id: "",
                    title: props.visObject.title,
                    description: "",
                    slides: props.visObject.visuals.map((newVis) => {
                      return {
                        visual_url: newVis.visualization_url,
                        visual_id: newVis.id,
                        intro: newVis.initial_message,
                        title: newVis.title,
                      };
                    }),
                    openEdit: true,
                  });
                }}
              >
                <Image
                  height="1em"
                  src="/portal/snorkle_vis_thumbnails/folder-to-presentation.png"
                  className="svg-inline--fa fa-2x"
                  title="Convert folder to presentation"
                />
              </button>
            ) : null}
          </h2>
        </div>
      ) : null}
      <div
        className={
          "vis-drop-target " +
          (props.visObject.id ? "ms-4 " + (collapsed ? "d-none" : "") : "")
        }
      >
        {props.children}
      </div>
    </div>
  );
};

const ProfilePage = (props) => {
  const [visualizations, setVisualizations] = useState([]);
  const [processedVisuals, setProcessedVisuals] = useState([]);
  const [folders, setFolders] = useState([]);
  const [editPresentation, setEditPresentation] = useState(null);
  const [presentations, setPresentations] = useState([]);
  const [videos, setVideos] = useState([]);
  const { visCache, setVisCache } = useContext(VisualCache);
  const { userInfo, setUserInfo } = useContext(UserInfo);
  const [visualNodes, setVisualNodes] = useState([]);
  const [userVisGetStatus, setUserVisGetStatus] = useState({
    sent: false,
    received: false,
  });
  const [fetchError, setFetchError] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  let [searchParams, setSearchParams] = useSearchParams();
  const userVisSuccessCB = (body) => {
    const visList = body.visuals || body;
    const temp = shallowCopy(visCache);
    for (const v of visList) {
      temp[v.id] = v;
    }
    temp[props.isOrg ? "orgVises" : "personalVises"] = true;
    setVisCache(temp);
    setVisualizations(visList);
    setFolders(body.folders || folders);
    setPresentations(body.presentations || []);
    const videos = (body.videos || []);
    for (const v of videos){
      v.audio_locked = true;
    }
    setVideos(videos);
    const nv = (searchParams.get("view") || "").toLowerCase();
    if (nv) {
      if (nv == "premium" && visList.some((vis) => vis.premium_bundle)) {
        setActiveTab(nv);
      } else if (nv == "presentations" && body.presentations.length) {
        setActiveTab(nv);
      } else if (nv == "favorites") {
        setActiveTab(nv);
      } else if (nv == "videos" && body.videos && body.videos.length) {
        setActiveTab(nv);
      }
    }
    setUserVisGetStatus({ sent: true, received: true });
  };
  const userVisFailureCB = (body) => {
    setFetchError(true);
    setUserVisGetStatus({ sent: true, received: true });
  };
  useEffect(() => {
    if (!userVisGetStatus.sent) {
      const func = props.isOrg ? APIGetOrgVisuals : APIGetVisualizations;
      func(userVisSuccessCB, userVisFailureCB);
      setUserVisGetStatus({ sent: true, received: false });
    }
  }, [userVisGetStatus.sent, props.isOrg]);

  useEffect(() => {
    const arr = [...visualizations].sort(
      (a, b) =>
        (a.priority || parseFloat(a.created_timestamp)) -
        (b.priority || parseFloat(b.created_timestamp))
    );
    const sortFolder = folders.sort(
      (a, b) => a.priority - b.priority || undefined
    );
    let visualDict = {
      idLookup: {},
      folders: [],
      visuals: [],
      title: "",
      id: null,
    };
    for (const f of sortFolder) {
      visualDict.idLookup[f.id] = {
        title: f.title,
        id: f.id,
        parent: f.parent,
        priority: f.priority,
        premium_bundle: f.premium_bundle,
        folders: [],
        visuals: [],
      };
    }
    visualDict.idLookup[null] = visualDict;
    for (const f of sortFolder) {
      const parent = visualDict.idLookup[f.parent || null];
      (parent || visualDict).folders.push(visualDict.idLookup[f.id]);
      if (parent && !f.premium_bundle) {
        f.premium_bundle = parent.premium_bundle;
      }
    }
    for (const vis of arr) {
      visualDict.idLookup[vis.id] = vis;
      const parent = visualDict.idLookup[vis.folder_id || null];
      (parent || visualDict).visuals.push(vis);
      if (parent) {
        vis.premium_bundle = parent.premium_bundle;
      }
    }
    setProcessedVisuals(visualDict);
  }, [visualizations, folders]);

  useEffect(() => {
    setVisualNodes(prepareVisualCards(processedVisuals, userInfo, !props.isOrg))
  }, [processedVisuals, userInfo])

  const [filterTerm, setFilterTerm] = useState("");

  useEffect(() => {
    const lowerF = (filterTerm || "").toLowerCase();
    const highlight = (node, object) => {
      const titles = node.getElementsByClassName("vis-card-title");
      for (let i = 0; i < titles.length; i++) {
        titles[i].innerHTML = lowerF ? highlightTextFunc(object.title, lowerF) : object.title;
      }
      const descriptions = node.getElementsByClassName("vis-card-description");
      for (let i = 0; i < descriptions.length; i++) {
        descriptions[i].innerHTML = lowerF ? highlightTextFunc(object.description, lowerF) : object.description;
      }
    }
    for (const v of ((processedVisuals || {}).visuals || [])) {
      let show = ["all", "favorites", "premium"].includes(activeTab);
      show = show && ((activeTab != "premium") || v.premium_bundle);
      show = show && ((activeTab != "favorites") || v.favorite);
      show = show && (!filterTerm || (v.title + "\n" + v.description).toLowerCase().includes(lowerF));
      const sel = document.getElementById("vis-container-" + v.id);
      if (!sel) {
        continue;
      }
      sel.classList.toggle("d-none", !show);
      if (show) {
        highlight(sel, v);
      }
    }
    for (const f of ((processedVisuals || {}).folders || [])) {
      let show = ["all", "favorites", "premium"].includes(activeTab);
      show = show && ((activeTab != "premium") || f.premium_bundle);
      const sel = document.getElementById("folder-container-" + f.id);
      if (!sel) {
        continue;
      }
      sel.classList.toggle("d-none", !show);
    }
    for (const p of presentations) {
      let show = activeTab == "presentations" && (!filterTerm || (p.title + "\n" + p.description).toLowerCase().includes(lowerF));
      const sel = document.getElementById(p.id + "_div");
      if (!sel) {
        continue;
      }
      sel.classList.toggle("d-none", !show);
      if (show) {
        highlight(sel, p);
      }
    }
    for (const v of videos) {
      let show = activeTab == "videos" && (!filterTerm || (v.title + "\n" + v.description).toLowerCase().includes(lowerF));
      const sel = document.getElementById(v.id + "_div");
      if (!sel) {
        continue;
      }
      sel.classList.toggle("d-none", !show);
      if (show) {
        highlight(sel, v);
      }
    }
  }, [filterTerm, activeTab, processedVisuals, videos, presentations]);

  const prepareVisualCards = (visObject, userInfo, isPersonal) => {
    const currVisuals = visObject.visuals || [];
    if (Array.isArray(visualizations) && visualizations.length) {
      const displayedVises = Array(currVisuals.length);
      const output = currVisuals.map((vis, index) => {
        const showVis = faTruckMedical;
        displayedVises[index] = showVis;
        const org = userInfo.organization_permissions;
        return {
          element: (
            <div
              className={
                (index + 1 === currVisuals.length ? "last-vis " : "") +
                (showVis
                  ? "border rounded border-secondary shadow-sm my-4"
                  : "d-none") + " snorkle-vis-container"
              }
              key={vis.id + "_div"}
              data-obj-id={vis.id}
              id={"vis-container-" + vis.id}
              data-priority={vis.priority || vis.created_timestamp}
              data-obj-type="visual"
              draggable={
                (isPersonal && canSortVisuals(userInfo.tier)) ||
                (org && org.edit_visuals)
              }
              onDragStart={(event) => {
                event.stopPropagation();
                event.dataTransfer.clearData();
                event.dataTransfer.setData("text/plain", "visual:" + vis.id);
                event.dataTransfer.effectAllowed = "move";
              }}
            >
              <AvailableTags.Consumer key={vis.id}>
                {({ availableTags, setAvailableTags }) => (
                  <VisCard
                    vis={vis}
                    canEdit={isPersonal || (org && org.edit_visuals)}
                    canDelete={
                      (isPersonal || (org && org.remove_visuals)) &&
                      !vis.premium_bundle
                    }
                    addCopyToOrg={isPersonal && org && org.add_visuals}
                    isOrg={!isPersonal}
                    key={vis.id}
                    canShrink={true}
                    canEmail={userInfo.tier === "unlimited"}
                    onVisDelete={(removeVis) => {
                      const temp = shallowCopy(visCache);
                      delete temp[removeVis.id];
                      setVisualizations(
                        visualizations.filter((vis) => vis !== removeVis)
                      );
                      setVisCache(temp);
                    }}
                    onVisEdit={(
                      editedVis,
                      newTitle,
                      newDesc,
                      newThumb,
                      newHasShare,
                      other = {}
                    ) => {
                      const temp = shallowCopy(visCache);
                      const visesAfterEdit = visualizations.map((vis) => {
                        if (vis.id === editedVis.id) {
                          let newVis = shallowCopy(vis);
                          newVis.title = newTitle;
                          newVis.description = newDesc;
                          if (newThumb) {
                            newVis.thumbnail = newThumb;
                          }
                          newVis.has_share_dialog = newHasShare;
                          const newObj = {};
                          for (const [key, value] of Object.entries(other)) {
                            const snakeKey = key
                              .split(/(?=[A-Z])/)
                              .map((e) => e.toLowerCase())
                              .join("_");
                            newObj[snakeKey] = value;
                          }
                          if (other.tags) {
                            newObj.tags = availableTags.filter((t) =>
                              other.tags.some((slug) => t.slug === slug)
                            );
                          }
                          const out = Object.assign(newVis, newObj);
                          temp[out.id] = out;
                          return out;
                        } else {
                          return vis;
                        }
                      });
                      setVisualizations(visesAfterEdit);
                      setVisCache(temp);
                    }}
                  />
                )}
              </AvailableTags.Consumer>
            </div>
          ),
          favorite: vis.favorite,
          shown: showVis,
          id: vis.id,
        };
      });
      let defaultVisPrior =
        Math.min(10000, ...currVisuals.map((v) => v.priority)) - 100;

      defaultVisPrior =
        Math.min(
          defaultVisPrior,
          ...visObject.folders.map((v) => v.priority || 10)
        ) - 100;
      return visualizations.some((e) => e) ? (
        <Folder
          visObject={visObject}
          processedVisuals={processedVisuals}
          defaultPriority={defaultVisPrior}
          userInfo={userInfo}
          folders={folders}
          visualizations={visualizations}
          setFolders={setFolders}
          setVisualizations={setVisualizations}
          isOrg={props.isOrg}
          setEditPresentation={setEditPresentation}
        >
          {visObject.folders.map((f) => (
            <div key={f.id}>
              {prepareVisualCards(f, userInfo, isPersonal)}
            </div>
          ))}
          {output.map((v) => (
            <div key={v.id}>{v.element}</div>
          ))}
        </Folder>
      ) : (
        <div className="text-center mb-2">No matching visual</div>
      );
    } else {
      return (
        <h4 className="TitleText">
          {props.isOrg
            ? "Your Organization doesn't have any visuals."
            : "You don't have any visuals."}
        </h4>
      );
    }
  };

  return (
    <>
      {!props.isOrg ? (
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/home" }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/dashboard" }}>
            Dashboard
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/dashboard/myvisuals" }}
            active
          >
            My Visuals
          </Breadcrumb.Item>
        </Breadcrumb>
      ) : null}
      <h3 className="TitleText">
        {props.isOrg ? "Organization Visuals" : "My Visuals"}
      </h3>
      {!userVisGetStatus.received ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="loading" className="text-secondary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : null}
      <div className={userVisGetStatus.received ? "" : "d-none"}>
        <UserInfo.Consumer>
          {({ userInfo, setUserInfo }) => {
            return fetchError ? (
              <h4
                className="text-danger text-center"
                id="portfolio-fetch-error"
              >
                Unable to fetch your visuals. Please try again later.
              </h4>
            ) : (
              <>
                {editPresentation ? (
                  <PresentationEditCanvas
                    getVisual={(id) => processedVisuals.idLookup[id]}
                    getVideo={(id) => videos.find((v) => v.id == id)}
                    presentation={editPresentation}
                    show={true}
                    isOrg={props.isOrg}
                    onClose={() => setEditPresentation(null)}
                    onPresentationEdit={(updatedPres) => {
                      const newPreses = [];
                      let replaced = false;
                      for (const p of presentations) {
                        replaced |= updatedPres.id == p.id;
                        newPreses.push(
                          p.id == updatedPres.id ? updatedPres : p
                        );
                      }
                      if (!replaced) {
                        newPreses.push(updatedPres);
                        setActiveTab("presentations");
                      }
                      setPresentations(newPreses);
                    }}
                  />
                ) : null}
                <Form.Group className="mb-3">
                  <Row className="justify-content-center">
                    <Form.Label className="col-auto align-self-center">
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        size="lg"
                        className="align-middle"
                      />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={filterTerm}
                      className="w-75"
                      placeholder="Search Term"
                      onChange={(event) => {
                        setFilterTerm(event.target.value);
                      }}
                    />
                  </Row>
                </Form.Group>
                {activeTab != "presentations" &&
                  activeTab != "videos" &&
                  (props.isOrg
                    ? userInfo.organization_permissions.edit_visuals
                    : canSortVisuals(userInfo.tier)) ? (
                  <AddFolderBtn
                    isOrg={props.isOrg}
                    onFolderAdd={(nf) => {
                      setFolders([...folders, nf]);
                      setTimeout(
                        () =>
                          document
                            .getElementById(`vis-folder-${nf.id}`)
                            .scrollIntoView(),
                        50
                      );
                    }}
                  />
                ) : null}
                {visualizations ? (
                  <Tabs
                    id="favorite-tabs"
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="all" title="All Visuals" />
                    <Tab eventKey="favorites" title="Favorite Visuals" />
                    {visualizations.some((vis) => vis.premium_bundle) ? (
                      <Tab eventKey="premium" title="Premium Visuals" />
                    ) : null}
                    {getPresentationLimit(userInfo.tier, props.isOrg) !== 0 ? (
                      <Tab eventKey="presentations" title="Presentations" />
                    ) : null}
                    {videos && videos.length ? (
                      <Tab eventKey="videos" title="Videos" />
                    ) : null}
                  </Tabs>
                ) : null}
                {presentations.map((p) => (
                  <div
                    id={p.id + "_div"}
                    key={p.id + "_div"}
                    className={
                      "border rounded border-secondary py-0 my-2" +
                      (filterTerm &&
                        !(p.title + "\n" + p.description)
                          .toLowerCase()
                          .includes(filterTerm.toLowerCase())
                        ? " d-none"
                        : "")
                    }
                  >
                    <Presentation
                      hideFavorite={true}
                      presentation={p}
                      canEdit={
                        !props.isOrg ||
                        (userInfo.organization_permissions &&
                          userInfo.organization_permissions
                            .edit_presentations)
                      }
                      canAdd={
                        !props.isOrg ||
                        userInfo.organization_permissions
                          .add_presentations
                      }
                      canEmail={userInfo.tier === "unlimited"}
                      canDelete={
                        !props.isOrg ||
                        (props.isOrg &&
                          userInfo.organization_permissions
                            .remove_presentations)
                      }
                      addCopyToOrg={false}
                      setEdit={(pres) => {
                        setEditPresentation(pres);
                      }}
                      hidePrivacyIcon={!userInfo.public_presentation}
                      isOrg={props.isOrg}
                      key={p.id}
                      canShrink={false}
                      onPresDelete={(pres) => {
                        setPresentations(
                          presentations.filter((p) => p.id != pres.id)
                        );
                      }}
                    />
                  </div>
                ))}
                {visualNodes}
                {videos.map((vis) => (
                  <div
                    className="border rounded border-secondary shadow-sm my-4"
                    id={vis.id + "_div"}
                    key={vis.id + "_div"}
                    draggable={
                      !(
                        props.isOrg &&
                        !userInfo.organization_permissions.edit_visuals
                      )
                    }
                    onDragStart={(event) => {
                      event.stopPropagation();
                      event.dataTransfer.clearData();
                      event.dataTransfer.setData(
                        "text/plain",
                        "video:" + vis.id
                      );
                      event.dataTransfer.effectAllowed = "move";
                    }}
                  >
                    <VisCard
                      vis={vis}
                      canEdit={
                        !(
                          props.isOrg &&
                          !userInfo.organization_permissions.edit_visuals
                        )
                      }
                      canDelete={
                        !(
                          props.isOrg &&
                          !userInfo.organization_permissions.delete_visuals
                        )
                      }
                      addCopyToOrg={
                        !props.isOrg &&
                        userInfo.organization_permissions &&
                        userInfo.organization_permissions.add_visuals
                      }
                      isOrg={props.isOrg}
                      key={vis.id}
                      canShrink={true}
                      canEmail={false}
                      onVisDelete={(removeVis) => {
                        const temp = videos.filter(
                          (t) => t.id != removeVis.id
                        );
                        setVideos(temp);
                      }}
                      onVisEdit={(
                        editedVis,
                        newTitle,
                        newDesc,
                        newThumb,
                        newHasShare,
                        other = {}
                      ) => {
                        const tempArr = [...videos];
                        const editIndex = tempArr.findIndex(
                          (v) => v.id == editedVis.id
                        );
                        if (editIndex == -1) {
                          return;
                        }
                        const tempObject = shallowCopy(editedVis);
                        tempObject.title = newTitle;
                        tempObject.description = newDesc;
                        tempObject.thumbnail = newThumb;
                        tempArr[editIndex] = tempObject;
                        setVideos(tempArr);
                      }}
                      highlightText={filterTerm.toLowerCase() || ""}
                    />
                  </div>
                ))}
                {activeTab == "presentations" ? (
                  <>
                    {presentations.length ? (null
                    ) : (
                      <>
                        <div className="text-center mb-2">
                          <h4 className="TitleText">
                            No{filterTerm ? " matching" : ""} presentations
                          </h4>
                        </div>
                      </>
                    )}

                    {(getPresentationLimit(userInfo.tier, props.isOrg) ===
                      null ||
                      presentations.length <
                      getPresentationLimit(userInfo.tier, props.isOrg)) &&
                      (!props.isOrg ||
                        userInfo.organization_permissions.add_presentations) ? (
                      <Button
                        className="w-100"
                        onClick={() => {
                          setEditPresentation({
                            id: "",
                            title: "New Presentation",
                            description: "",
                            slides: [],
                            openEdit: true,
                          });
                        }}
                      >
                        New Presentation
                      </Button>
                    ) : null}
                  </>
                ) : activeTab == "videos" ? (
                  <>
                  </>
                ) : (
                  <>
                    {!props.isOrg ||
                      userInfo.organization_permissions.add_visuals ? (
                      <div className="text-center">
                        {!getVisualLimit[userInfo["tier"]] ||
                          visualizations.length <
                          getVisualLimit[userInfo["tier"]] ||
                          props.isOrg ? (
                          <Link
                            to={
                              props.isOrg
                                ? "/organization/upload"
                                : "/dashboard/upload"
                            }
                          >
                            <Button className="text-center">
                              Add a new visual
                            </Button>
                          </Link>
                        ) : (
                          "You have reached the maximum number of visuals for your account."
                        )}
                      </div>
                    ) : null}
                  </>
                )}
              </>
            );
          }}
        </UserInfo.Consumer>
      </div>
      {visualizations && visualizations.length > 3 ? (
        <Button
          className="scroll-up-btn"
          onClick={() => {
            window.scroll(0, 0);
            setTimeout(() => {
              var sel = document.querySelector(".scroll-up-btn");
              if (sel) {
                sel.blur();
              }
            }, 250);
          }}
        >
          <span>
            <FontAwesomeIcon icon={faArrowUp} size="lg" title="Scroll to Top" />
          </span>
        </Button>
      ) : null}
    </>
  );
};

export default ProfilePage;

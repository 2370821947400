import React, { useState, useContext } from "react";
import { Button, Spinner, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { APIRegister } from "../helper/APIFunctions";
import { UserInfo, DisclaimerSigned } from "../helper/Context";
import * as Yup from "yup";
import { Formik } from "formik";
import { getUseTypes } from "../helper/BusinessLogic.js";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Required").email("Invalid Format"),
  name: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be at least 8 characters")
    .max(72, "Password cannot be have more than 72 characters"),
  passwordConfirm: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  tosAgree: Yup.bool().oneOf([true], "You must agree to create an account"),
});

const RegisterForm = (props) => {
  const { setUserInfo } = useContext(UserInfo);
  const [sendingCall, setSendingCall] = useState(false);
  const { setDisclaimerSigned } = useContext(DisclaimerSigned);
  const [callErrors, setCallErrors] = useState([]);
  const successRegisterCB = (body) => {
    setDisclaimerSigned(true);
    setUserInfo({
      name: body.name,
      email: body.email,
      tier: body.tier,
      client_id: body.client_id,
      use_type: body.use_type,
    });
    setSendingCall(false);
    props.onSuccessCB();
  };
  const onFailureRegisterCB = (body) => {
    if (!body.error) {
      setCallErrors(["There was a server error. Please try again later."]);
    } else {
      setCallErrors(body.error.map((element) => element.message));
    }
    setSendingCall(false);
  };
  return (
    <React.Fragment>
      {callErrors.map((error) => (
        <p className="text-danger text-center">{error}</p>
      ))}
      <Formik
        initialValues={{
          email: "",
          name: "",
          password: "",
          passwordConfirm: "",
          tosAgree: false,
          useType: "other",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setSendingCall(true);
          APIRegister(
            values.email,
            values.name,
            values.password,
            values.passwordConfirm,
            values.useType,
            successRegisterCB,
            onFailureRegisterCB
          ).then(actions.setSubmitting(false));
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="validationFormik01" className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Account Email"
                value={values.email}
                autoComplete="email"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationFormik02" className="mb-3">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                name="name"
                placeholder="User Name"
                value={values.name}
                disabled={isSubmitting || sendingCall}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.name && errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationFormik03" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                autoComplete="new-password"
                placeholder="Password"
                value={values.password}
                disabled={isSubmitting || sendingCall}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.password && errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationFormik04" className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                name="passwordConfirm"
                type="password"
                autoComplete="new-password"
                placeholder="Confirm Password"
                value={values.passwordConfirm}
                disabled={isSubmitting || sendingCall}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.passwordConfirm && errors.passwordConfirm}
              />
              <Form.Control.Feedback type="invalid">
                {errors.passwordConfirm}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>I am using Snorkle as a...</Form.Label>
              <Form.Select
                name="useType"
                value={values.useType}
                disabled={isSubmitting || sendingCall}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {getUseTypes().map((t) => (
                  <option key={t.slug} value={t.slug}>
                    {t.name}
                  </option>
                ))}{" "}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="validationFormik05" className="mb-3">
              <Form.Check
                type="checkbox"
                name="tosAgree"
                checked={values.tosAgree}
                disabled={isSubmitting || sendingCall}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.tosAgree && touched.tosAgree}
                label={
                  <>
                    I understand that this software is not intended for clinical
                    use, and that it is currently in beta. I also agree to the{" "}
                    <Link
                      to="/tos"
                      // to = 'https://www.snorkle.io/terms-of-use/'
                      target="_blank"
                      className="visualization_link"
                    >
                      terms of use
                    </Link>
                    .
                  </>
                }
              />
            </Form.Group>
            <Button
              type="submit"
              className="mt-2"
              disabled={isSubmitting || sendingCall}
            >
              {isSubmitting || sendingCall ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="login"
                  aria-hidden="true"
                />
              ) : (
                <>Register</>
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default RegisterForm;

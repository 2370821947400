import React, { useState } from "react";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import LoginModal from "./LoginModal";
import { UserInfo } from "../helper/Context";
import { APILogout } from "../helper/APIFunctions";
import { canRestoreVisuals } from "../helper/BusinessLogic";

const UserDropdown = (props) => {
  let navigate = useNavigate();
  return (
    <Dropdown>
      <Dropdown.Toggle as="div" className="nav-link">
        {props.userInfo.name}
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark" align="end">
        <Dropdown.Item eventKey="0" className="p-0" as="div">
          <NavLink
            className="nav-link id_my-visuals-nav-link"
            to="/dashboard/myvisuals"
          >
            My Visuals
          </NavLink>
        </Dropdown.Item>
        <Dropdown.Item eventKey="1" className="p-0" as="div">
          <NavLink
            className="nav-link id_new-visual-nav-link"
            to="/dashboard/upload"
          >
            Add New Visual
          </NavLink>
        </Dropdown.Item>
        <Dropdown.Item eventKey="7" className="p-0" as="div">
          <NavLink className="nav-link" to="/search">
            Search (beta)
          </NavLink>
        </Dropdown.Item>

        {props.userInfo && canRestoreVisuals(props.userInfo.tier) ? (
          <Dropdown.Item eventKey="7" className="p-0" as="div">
            <NavLink className="nav-link" to="/deletedvisuals">
              Deleted Visuals
            </NavLink>
          </Dropdown.Item>
        ) : null}

        {props.userInfo.organization_permissions ? (
          <>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="5" className="p-0" as="div">
              <NavLink className="nav-link" to="/organization/info">
                Organization Info
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item eventKey="4" className="p-0" as="div">
              <NavLink className="nav-link" to="/organization/visuals">
                Organization Visuals
              </NavLink>
            </Dropdown.Item>
            {props.userInfo.organization_permissions.add_visuals ? (
              <Dropdown.Item eventKey="6" className="p-0" as="div">
                <NavLink className="nav-link" to="/organization/upload">
                  Add Organization Visual
                </NavLink>
              </Dropdown.Item>
            ) : null}
          </>
        ) : null}
        <Dropdown.Divider />
        <Dropdown.Item eventKey="2" className="p-0" as="div">
          <NavLink className="nav-link" to="/dashboard/settings">
            Account Settings
          </NavLink>
        </Dropdown.Item>
        <Dropdown.Item eventKey="3" className="p-0" as="div">
          <div
            onClick={() => {
              APILogout();
              props.setUserInfo(null);
              navigate("/home");
            }}
            className="nav-link"
          >
            Logout
          </div>
        </Dropdown.Item>
        {props.userInfo.can_add_tags ? (
          <>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="0" className="p-0" as="div">
              <NavLink className="nav-link" to="/tags">
                Browse Visual Tags
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item eventKey="1" className="p-0" as="div">
              <NavLink className="nav-link" to="/tags/add">
                Add New Tags
              </NavLink>
            </Dropdown.Item>
          </>
        ) : null}
        {props.userInfo.admin ? (
          <>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="0" className="p-0" as="div">
              <NavLink className="nav-link" to="/admin">
                Admin Options
              </NavLink>
            </Dropdown.Item>
          </>
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const Header = (props) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className="bg-tertiary mt-0 px-4"
      >
        <NavLink
          className="navbar-brand visualization-link me-4 d-flex align-items-center"
          to="/home"
        >
          {/* <img
            src="/portal/new_snorkle_icon1.png"
            height="30px"
            width="30px"
            className="me-2 border border-light rounded"
            alt="Snorkle"
          /> */}
          <h1 className="mb-0" style={{ color: "#EA088B" }} >Snorkle</h1>
          <sup>
            <i>
              <h11 style={{ color: "cyan", fontWeight: "bold" }}>Labs</h11>
            </i>
          </sup>
        </NavLink>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            {/* <NavLink className="nav-link" to="/about">
              About
            </NavLink> */}
            <NavLink className="nav-link" to="/usecases">
              Use Cases
            </NavLink>
            <NavLink className="nav-link" to="/pricing">
              Plans
            </NavLink>
            <NavLink className="nav-link" to="/powerpoint">
              PowerPoint Add-in
            </NavLink>
            {/* <NavLink className="nav-link" to="/tutorials">
              Tutorials
            </NavLink> */}
            <NavLink className="nav-link" to="/contact">
              Contact Us
            </NavLink>
            {/* <NavLink className="nav-link" to="/bundles">
              Premium Bundles
            </NavLink>
            <NavLink className="nav-link" to="/special/gnydm2023">
              Special Events
            </NavLink> */}

            {/* <NavLink className="nav-link" to="/visuals">
              Browse Visuals
            </NavLink> */}
            <UserInfo.Consumer>
              {({ userInfo, setUserInfo }) => {
                return userInfo !== null ? (
                  <>
                    <NavLink className="nav-link" to="/tutorials">
                      Tutorials / Documentation
                    </NavLink>
                  </>
                ) : null;
              }}
            </UserInfo.Consumer>
          </Nav>
          <Nav className="ms-auto">
            <UserInfo.Consumer>
              {({ userInfo, setUserInfo }) => {
                return userInfo !== null ? (
                  <UserDropdown userInfo={userInfo} setUserInfo={setUserInfo} />
                ) : (
                  <div
                    className="nav-link"
                    onClick={() => setShowLoginModal(true)}
                  >
                    Login / Register
                  </div>
                );
              }}
            </UserInfo.Consumer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <LoginModal
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
      />
    </>
  );
};

export default Header;

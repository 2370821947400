import { useState, useContext } from "react";
import { Button, Breadcrumb, Spinner } from "react-bootstrap";
import { UserInfo } from "../helper/Context.js";
import AddTagsPage from "./AddTagsPage.js";
import BundleEditPage from "./BundleEditPage.js";
import { Link, Route, Routes, Navigate } from "react-router-dom";

function APIResetTerminal(successCB, failureCB) {
  const url = process.env.REACT_APP_API_ENDPOINT + "/admin/gnydm2023/special";
  const b = {
    reset_terminal: true,
  };
  return fetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(b),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();
      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

const adminFunctions = [
  {
    name: "Add Tags",
    permissionName: "tags",
    route: "tags",
    element: <AddTagsPage />,
  },
  {
    name: "Add/Edit Bundles",
    permissionName: "bundles",
    route: "bundles",
    element: <BundleEditPage />,
  },
];

const AdminBasePage = (props) => {
  const [apiResult, setApiResult] = useState({});

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/home" }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }} active>
          Admin
        </Breadcrumb.Item>
      </Breadcrumb>
      {props.errMsg ? (
        <h4 className="TitleText text-error">{props.errMsg}</h4>
      ) : null}
      {apiResult.error ? (
        <div className="text-danger">{apiResult.error}</div>
      ) : null}
      {apiResult.success ? (
        <div className="text-success">Terminal Reset</div>
      ) : null}
      <UserInfo.Consumer>
        {({ userInfo, setUserInfo }) => {
          return (
            <>
              {adminFunctions.map((f) =>
                userInfo.admin[f.permissionName] ? (
                  <div key={f.name}>
                    <Link to={f.route}>
                      <Button>{f.name}</Button>
                    </Link>
                  </div>
                ) : null
              )}
            </>
          );
        }}
      </UserInfo.Consumer>
      <Button
        className="mt-4"
        disabled={apiResult.sending}
        onClick={() => {
          setApiResult({ sending: true });
          APIResetTerminal(
            () => {
              setApiResult({ success: true });
            },
            (err) => {
              setApiResult({ error: err });
            }
          );
        }}
      >
        {apiResult.sending && apiResult.resetting ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="login"
            aria-hidden="true"
          />
        ) : (
          "Reset Terminal"
        )}
      </Button>
    </>
  );
};

const AdminPage = (props) => {
  return (
    <UserInfo.Consumer>
      {({ userInfo, setUserInfo }) => {
        return (
          <>
            {!userInfo || !userInfo.admin ? (
              <Navigate to="/home" />
            ) : (
              <Routes>
                {adminFunctions.map((f) => (
                  <Route
                    path={f.route}
                    key={f.name}
                    element={
                      userInfo.admin[f.permissionName] ? (
                        <>
                          <Breadcrumb>
                            <Breadcrumb.Item
                              linkAs={Link}
                              linkProps={{ to: "/home" }}
                            >
                              Home
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                              linkAs={Link}
                              linkProps={{ to: "/admin" }}
                            >
                              Admin
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                              linkAs={Link}
                              linkProps={{ to: "/admin/" + f.route }}
                              active
                            >
                              {f.name}
                            </Breadcrumb.Item>
                          </Breadcrumb>
                          {f.element}
                        </>
                      ) : (
                        <AdminBasePage
                          errMsg={"You are not authorized to access: " + f.name}
                        />
                      )
                    }
                  />
                ))}
                <Route
                  path="*"
                  element={
                    <>
                      <AdminBasePage />
                    </>
                  }
                />
              </Routes>
            )}
          </>
        );
      }}
    </UserInfo.Consumer>
  );
};

export default AdminPage;

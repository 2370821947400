import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Breadcrumb,
  Spinner,
  ListGroup,
  Tabs,
  Tab,
} from "react-bootstrap";
import { canSetPersonalBranding } from "../helper/BusinessLogic";
import { UserInfo } from "../helper/Context";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { APIResetPassword, APIUpdateUserInfo } from "../helper/APIFunctions";
import BrandingForm from "../components/BrandingForm";
import CurrentBillingDetails from "../components/CurrentBillingDetails";
import { useNavigate } from "react-router-dom";
import {
  getVisualLifetimeHrs,
  getVisualLimit,
  getUseTypes,
} from "../helper/BusinessLogic";
import { contactUs, shallowCopy } from "../helper/Utils";
import * as Yup from "yup";

const passwordChangeSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required("Required")
    .min(8, "Too short")
    .max(72, "Too long"),
  newPassword: Yup.string()
    .required("Required")
    .min(8, "Password must be at least 8 characters")
    .max(72, "Password cannot be have more than 72 characters"),
  newPasswordConfirm: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

const PasswordChange = (props) => {
  const [result, setResult] = useState(null);
  return (
    <>
      {result ? <p className={result.style}>{result.message}</p> : null}
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          newPasswordConfirm: "",
        }}
        validationSchema={passwordChangeSchema}
        onSubmit={(values, actions) => {
          setResult(null);
          APIResetPassword(
            values.newPassword,
            values.newPasswordConfirm,
            (body) => {
              setResult({
                style: "text-success text-center",
                message: "Success!",
              });
              actions.setSubmitting(false);
            },
            (body) => {
              setResult({
                style: "text-danger text-center",
                message: body.toString(),
              });
              actions.setSubmitting(false);
            },
            null,
            values.currentPassword
          );
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="change-password-current" className="mb-3">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                name="currentPassword"
                autoComplete="current-password"
                value={values.currentPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                isInvalid={touched.currentPassword && errors.currentPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.currentPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="change-password-new" className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                autoComplete="new-password"
                value={values.newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                isInvalid={touched.newPassword && errors.newPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.newPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              controlId="change-password-new-confirm"
              className="mb-3"
            >
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                autoComplete="new-password"
                name="newPasswordConfirm"
                value={values.newPasswordConfirm}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                isInvalid={
                  touched.newPasswordConfirm && errors.newPasswordConfirm
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.newPasswordConfirm}
              </Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="resetting password"
                  aria-hidden="true"
                />
              ) : (
                <>Reset Password</>
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

const getCountText = (tier) => {
  const count = getVisualLimit(tier);
  return count ? `${count} visual limit` : "Unlimited visuals";
};

const getLifetimeText = (tier) => {
  const hrs = getVisualLifetimeHrs(tier);
  return hrs
    ? `${Math.round(hrs / 24)} day visual lifetime`
    : "Infinite lifetime";
};

const AccountInfoPage = (props) => {
  let navigate = useNavigate();
  const [newUseType, setNewUseType] = useState(
    props.userInfo.use_type || "other"
  );
  const [updatingInfo, setUpdatingInfo] = useState(false);
  return (
    <Container>
      <Row className="border-secondary rounded align-items-center mb-4">
        <Col xs={3}>Email:</Col>
        <Col xs="auto">{props.userInfo.email}</Col>
      </Row>
      <Row className="border-secondary rounded align-items-center mb-4">
        <Col xs={3}>Plan:</Col>
        <Col xs="auto" className="text-capitalize">
          {props.userInfo.tier}
        </Col>
        <Col>
          {props.userInfo.tier == "free" ? (
            <Link to="/pricing">
              <Button>Upgrade for permanent links and more storage</Button>
            </Link>
          ) : props.userInfo.has_stripe ? (
            <Link to="/pricing">
              <Button>Manage your subscription</Button>
            </Link>
          ) : (
            <Button onClick={() => navigate("/contact")}>
              Contact us to manage your plan
            </Button>
          )}
        </Col>
      </Row>

      <Row className="border-secondary rounded mb-4">
        <Col xs={3} className="pt-2">
          Details:
        </Col>

        {props.userInfo.tier == "free" ? (
          <Col xs="auto">
            <ListGroup variant="flush" className="mx-0">
              <ListGroup.Item className="bg-transparent p-2">
                Standard image formats
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent p-2">
                Simple molecule viewer
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent p-2">
                {getCountText("free")}
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent p-2">
                {getLifetimeText("free")}
              </ListGroup.Item>
            </ListGroup>
          </Col>
        ) : null}

        {props.userInfo.tier == "premium" ? (
          <Col xs="auto">
            <ListGroup variant="flush" className="mx-0">
              <ListGroup.Item className="bg-transparent p-2">
                Standard image formats
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent p-2">
                Clinical image formats
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent p-2">
                Simple molecule viewer
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent p-2">
                {getCountText("premium")}
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent p-2">
                {getLifetimeText("premium")}
              </ListGroup.Item>
            </ListGroup>
          </Col>
        ) : null}

        {props.userInfo.tier == "unlimited" ? (
          <Col xs="auto">
            <ListGroup variant="flush" className="mx-0">
              <ListGroup.Item className="bg-transparent p-2">
                Standard image formats
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent p-2">
                Clinical imaging formats
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent p-2">
                Simple molecule viewer
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent p-2">
                {getCountText("unlimited")}
              </ListGroup.Item>
              <ListGroup.Item className="bg-transparent p-2">
                {getLifetimeText("unlimited")}
              </ListGroup.Item>
            </ListGroup>
          </Col>
        ) : null}
      </Row>

      {props.userInfo.organization ? (
        <Row className="border-secondary rounded align-items-center mb-4">
          <Col xs={3}>Organization:</Col>
          <Col xs="auto">{props.userInfo.organization.name}</Col>
        </Row>
      ) : null}
      <Row>
        <Col xs={3}>Change Password</Col>
        <Col xs={9}>
          <PasswordChange />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={3}>I am a...</Col>
        <Col xs={9}>
          <Form.Select
            name="useType"
            value={newUseType}
            onChange={(e) => {
              setNewUseType(e.target.value);
            }}
          >
            {getUseTypes().map((t) => (
              <option key={t.slug} value={t.slug}>
                {t.name}
              </option>
            ))}
          </Form.Select>
          <div>
            <Button
              className="mt-1"
              disabled={updatingInfo}
              onClick={() => {
                setUpdatingInfo(true);
                APIUpdateUserInfo(
                  { useType: newUseType },
                  () => {
                    const t = shallowCopy(props.userInfo);
                    t.use_type = newUseType;
                    props.setUserInfo(t);
                    setUpdatingInfo(false);
                  },
                  () => {
                    setUpdatingInfo(false);
                  }
                );
              }}
            >
              Update
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const SettingsPage = (props) => {
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/home" }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/dashboard" }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: "/dashboard/settings" }}
          active
        >
          Account Settings
        </Breadcrumb.Item>
      </Breadcrumb>

      <h2 className="TitleText mb-4">Account Settings</h2>
      <UserInfo.Consumer>
        {({ userInfo, setUserInfo }) => {
          return canSetPersonalBranding(userInfo.tier) ||
            userInfo.has_default_payment_method ? (
            <Tabs
              defaultActiveKey="info"
              id="account-settings-tabs"
              mountOnEnter={userInfo.has_default_payment_method}
              className="mb-3"
            >
              {}
              <Tab eventKey="info" title="Account Info">
                <AccountInfoPage
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                />
              </Tab>
              {canSetPersonalBranding(userInfo.tier) ? (
                <Tab eventKey="branding" title="Personal Branding">
                  <BrandingForm isOrg={false} />
                </Tab>
              ) : null}
              {userInfo.has_default_payment_method ? (
                <Tab eventKey="billing" title="Saved Payment">
                  <CurrentBillingDetails />
                </Tab>
              ) : null}
            </Tabs>
          ) : (
            <AccountInfoPage userInfo={userInfo} />
          );
        }}
      </UserInfo.Consumer>
    </>
  );
};

export default SettingsPage;

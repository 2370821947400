import { useState, useEffect, useContext } from "react";
import { Form, Button, Spinner, Modal, Image, Row, Col } from "react-bootstrap";
import { shallowCopy, prepBrandProfileList } from "../helper/Utils";
import {
  APISetBranding,
  APIRemoveBranding,
  APIGetBranding,
} from "../helper/APIFunctions";
import { UserInfo } from "../helper/Context";
const MBtoB = 1024 * 1024;
const websiteRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
const twitterRegex = /^@?[a-zA-Z0-9_]{1,15}$/;

const RemoveModal = (props) => {
  const [requestState, setRequestState] = useState({});
  return (
    <Modal show={props.show}>
      <Modal.Body>
        {requestState.error ? (
          <p className="text-danger text-center">{requestState.error}</p>
        ) : null}
        {requestState.success ? (
          <p className="text-success text-center">Branding Removed</p>
        ) : null}
        <p>
          Are you sure you want to remove{" "}
          {props.isOrg ? "your organization's" : "your"} branding?
        </p>
        <Button
          onClick={(event) => {
            APIRemoveBranding(
              props.brandId,
              (res) => {
                setRequestState({ success: true });
                props.onDelete(props.brandId);
                setTimeout(() => {
                  setRequestState({});
                  props.onCancel();
                }, 2000);
              },
              (err) => {
                setRequestState({
                  error: (err && err.error) || err || "Internal Server Error",
                });
              },
              props.isOrg
            );
            setRequestState({ sending: true });
          }}
          disabled={requestState.sending || requestState.success}
        >
          {requestState.sending ? (
            <Spinner
              animation="border"
              role="loading"
              className="text-secondary align-center"
              size="sm"
            />
          ) : (
            <>Confirm</>
          )}
        </Button>
        <Button onClick={props.onCancel} disabled={requestState.sending}>
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

const BrandingForm = (props) => {
  const [newIcon, setNewIcon] = useState(null);
  const { userInfo, setUserInfo } = useContext(UserInfo);
  const [newQrIcon, setNewQrIcon] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [submitInfo, setSubmitInfo] = useState({});
  const [selectedBrandProfile, setSelectedBrandProfile] = useState(0);
  const [brandingProfiles, setBrandingProfiles] = useState([{}]);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [imgSrcs, setImgSrcs] = useState({
    qr: null,
    icon: null,
    favicon: null,
  });
  const [textVals, setTextVals] = useState({
    website: "",
    name: "",
    twitter: "",
    profile_name: "",
  });
  const [defaultChecked, setDefaultChecked] = useState(false);
  const onTextChange = (event) => {
    let temp = shallowCopy(textVals);
    temp[event.target.name] = event.target.value;
    setTextVals(temp);
  };
  const onTextBlur = (event) => {
    let temp = shallowCopy(touched);
    temp[event.target.name] = true;
    setTouched(temp);
  };
  const [invalid, setInvalid] = useState([
    textVals.website && websiteRegex.test(textVals.website),
    false,
    textVals.twitter && twitterRegex.test(textVals.twitter),
    newIcon && newIcon.size > 2 * MBtoB,
    newQrIcon && newQrIcon.size > 2 * MBtoB,
  ]);
  const [touched, setTouched] = useState({
    name: false,
    website: false,
    twitter: false,
  });

  const [fetchResult, setFetchResult] = useState({
    sent: false,
    received: false,
  });

  useEffect(() => {
    if (!fetchResult.sent) {
      APIGetBranding(
        (body) => {
          let l = body && body.length ? body : [{}];
          setBrandingProfiles(prepBrandProfileList({ t: body }, "t"));
          setFetchResult({ sent: true, received: true });
        },
        (err) => {
          setBrandingProfiles([{}]);
          setFetchResult({ sent: true, received: true, error: err });
        },
        props.isOrg
      );
      setFetchResult({ sent: true, received: false });
    }
  }, [fetchResult]);

  useEffect(() => {
    if (selectedBrandProfile == -1 || !brandingProfiles.length) {
      setTextVals({
        name: "",
        website: "",
        twitter: "",
        profile_name: "",
      });
      setImgSrcs({
        qr: null,
        favicon: null,
        icon: null,
      });
      setDefaultChecked(false);
    } else {
      const selBrand = brandingProfiles[selectedBrandProfile] || {};
      setTextVals({
        name: selBrand.name || "",
        website: selBrand.website || "",
        twitter: selBrand.twitter || "",
        profile_name: selBrand.profile_name || "",
      });
      setImgSrcs({
        qr: selBrand.qr_icon || "",
        favicon: selBrand.favicon || "",
        icon: selBrand.icon || "",
      });
      setDefaultChecked(selBrand.is_default);
    }
    if (fetchResult.received) {
      const t = shallowCopy(userInfo);
      t.branding_profiles = shallowCopy(t.branding_profiles);
      t.branding_profiles[
        props.isOrg ? "organization" : "personal"
      ] = brandingProfiles.map((e) => {
        return {
          profile_name: e.profile_name,
          id: e.id,
          is_default: e.is_default,
        };
      });
      setUserInfo(t);
    }
    for (const eName of ["logo", "qr-logo", "favicon"]) {
      for (const element of document.getElementsByName(eName)) {
        element.value = "";
      }
    }
  }, [brandingProfiles, selectedBrandProfile]);

  useEffect(() => {
    setInvalid([
      !textVals.name,
      textVals.website && !websiteRegex.test(textVals.website),
      textVals.twitter && !twitterRegex.test(textVals.twitter),
      newIcon && newIcon.size > 2 * MBtoB,
      newQrIcon && newQrIcon.size > 2 * MBtoB,
      favicon && favicon.size > 0.5 * MBtoB,
    ]);
  }, [textVals, newIcon, newQrIcon]);
  return fetchResult.received ? (
    <>
      {submitInfo.error ? (
        <p className="text-danger text-center">{submitInfo.error}</p>
      ) : null}
      {submitInfo.success ? (
        <p className="text-success text-center">
          Branding Updated Successfully
        </p>
      ) : null}
      {brandingProfiles && userInfo.branding_profile_limit > 1 ? (
        <h5 className="TitleText text-weak">
          Using{" "}
          {brandingProfiles.reduce(
            (count, prof) => count + (prof.id ? 1 : 0),
            0
          )}{" "}
          of {userInfo.branding_profile_limit} Brand Profiles
        </h5>
      ) : null}
      {brandingProfiles &&
      brandingProfiles[0].id &&
      userInfo.branding_profile_limit > 1 &&
      !props.isOrg ? (
        <>
          Branding Profile:
          <Form.Select
            className="w-auto flex-grow-1 d-inline ms-2 mb-2"
            value={selectedBrandProfile}
            onChange={(e) => setSelectedBrandProfile(e.target.value)}
            disabled={submitInfo.submitting}
            aria-label="Branding Profile"
          >
            {brandingProfiles.map((e, i) => (
              <option key={e.id} value={i}>
                {e.profile_name}
                {e.is_default ? " (default)" : null}
              </option>
            ))}
            {userInfo.branding_profile_limit > brandingProfiles.length ? (
              <option value="-1">**New Branding Profile**</option>
            ) : null}
          </Form.Select>
        </>
      ) : null}
      {fetchResult.error ? (
        <h3 className="text-danger text-center">{fetchResult.error} </h3>
      ) : (
        <>
          <Form
            onSubmit={(event) => {
              setSubmitInfo({ submitting: true });
              let imageData = { icon: null, qr: null, favicon: null };
              const onLoadFunc = (data, type) => {
                imageData[type] = data;
                if (imageData.icon && imageData.qr && imageData.favicon) {
                  APISetBranding(
                    textVals.name,
                    textVals.website,
                    textVals.twitter,
                    imageData.icon === true ? null : imageData.icon,
                    imageData.qr === true ? null : imageData.qr,
                    imageData.favicon === true ? null : imageData.favicon,
                    textVals.profile_name || textVals.name,
                    selectedBrandProfile == -1
                      ? null
                      : (brandingProfiles[selectedBrandProfile] || {}).id ||
                          null,
                    (body) => {
                      setSubmitInfo({ success: true });
                      let temp = [...brandingProfiles];
                      if (body.is_default) {
                        temp.forEach((e) => (e.is_default = false));
                      }
                      let ind = selectedBrandProfile;
                      if (selectedBrandProfile == -1) {
                        ind = temp.length;
                        temp.push(body);
                      } else if (
                        brandingProfiles.length &&
                        brandingProfiles[0].id
                      ) {
                        temp[selectedBrandProfile] = body;
                      } else {
                        temp = [body];
                      }
                      const list = prepBrandProfileList({ t: temp }, "t");
                      setBrandingProfiles(list);
                      setSelectedBrandProfile(
                        list.findIndex((e) => e.id == body.id)
                      );
                    },
                    (err) => {
                      setSubmitInfo({
                        error: (
                          (err && err.error) ||
                          err ||
                          "Internal Server Error"
                        ).toString(),
                      });
                    },
                    props.isOrg,
                    { default: defaultChecked }
                  );
                }
              };
              if (newIcon) {
                const iconLoader = new FileReader();
                iconLoader.onload = () => onLoadFunc(iconLoader.result, "icon");
                iconLoader.onerror = () => {
                  setSubmitInfo({ error: "Unable to read Icon image file." });
                };
                iconLoader.readAsDataURL(newIcon);
              } else {
                onLoadFunc(true, "icon");
              }
              if (newQrIcon) {
                const qrLoader = new FileReader();
                qrLoader.onload = () => onLoadFunc(qrLoader.result, "qr");
                qrLoader.onerror = () => {
                  setSubmitInfo({ error: "Unable to read QRIcon image file." });
                };
                qrLoader.readAsDataURL(newQrIcon);
              } else {
                onLoadFunc(true, "qr");
              }
              if (favicon) {
                const faviconLoader = new FileReader();
                faviconLoader.onload = () =>
                  onLoadFunc(faviconLoader.result, "favicon");
                faviconLoader.onerror = () => {
                  setSubmitInfo({
                    error: "Unable to read Webpage Icon image file.",
                  });
                };
                faviconLoader.readAsDataURL(favicon);
              } else {
                onLoadFunc(true, "favicon");
              }
              event.preventDefault();
            }}
          >
            <Form.Group className="mb-4">
              <Form.Label>Brand Name</Form.Label>
              <Form.Control
                value={textVals.name}
                name="name"
                onChange={onTextChange}
                onBlur={onTextBlur}
                disabled={submitInfo.submitting}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Website</Form.Label>
              <Form.Control
                value={textVals.website}
                onBlur={onTextBlur}
                name="website"
                onChange={onTextChange}
                disabled={submitInfo.submitting}
                isInvalid={touched.website && invalid[1]}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Invalid Format
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Twitter Handle</Form.Label>
              <Form.Control
                value={textVals.twitter}
                onBlur={onTextBlur}
                name="twitter"
                onChange={onTextChange}
                isInvalid={touched.twitter && invalid[2]}
                disabled={submitInfo.submitting}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Invalid Format
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Logo Image</Form.Label>
              <Row>
                <Col xs="auto" className="my-auto">
                  {imgSrcs.icon ? (
                    <Image
                      src={imgSrcs.icon}
                      style={{ maxWidth: 64, maxHeight: 64 }}
                      className="border border-secondary"
                    />
                  ) : null}
                </Col>
                <Col className="my-auto">
                  <Form.Control
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    onChange={(event) => {
                      setNewIcon(
                        (event.target.files && event.target.files[0]) || null
                      );
                    }}
                    name="logo"
                    isInvalid={invalid[3]}
                    disabled={submitInfo.submitting}
                  />
                  <Form.Text>
                    Accepts .png, .jpg and .jpeg, files with a limit of 2MB.
                    Will be scaled to fit within 128x128px.
                  </Form.Text>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>QR Icon</Form.Label>
              <Row>
                <Col xs="auto" className="my-auto">
                  {imgSrcs.qr ? (
                    <Image
                      src={imgSrcs.qr}
                      style={{ maxWidth: 64, maxHeight: 64 }}
                      className="border border-secondary"
                    />
                  ) : null}
                </Col>
                <Col className="my-auto">
                  <Form.Control
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    name="qr-logo"
                    onChange={(event) => {
                      setNewQrIcon(
                        (event.target.files && event.target.files[0]) || null
                      );
                    }}
                    isInvalid={invalid[4]}
                    disabled={submitInfo.submitting}
                  />
                  <Form.Control.Feedback type="invalid">
                    Selected file is too large.
                  </Form.Control.Feedback>
                  <Form.Text>
                    Accepts .png, .jpg and .jpeg, files with a limit of 2MB.
                    Will be scaled to 150x150px.
                  </Form.Text>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Webpage Icon</Form.Label>
              <Row>
                <Col xs="auto" className="my-auto">
                  {imgSrcs.favicon ? (
                    <Image
                      src={imgSrcs.favicon}
                      style={{ maxWidth: 32, maxHeight: 32 }}
                      className="border border-secondary"
                    />
                  ) : null}
                </Col>
                <Col className="my-auto">
                  <Form.Control
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    name="favicon"
                    onChange={(event) => {
                      setFavicon(
                        (event.target.files && event.target.files[0]) || null
                      );
                    }}
                    isInvalid={invalid[5]}
                    disabled={submitInfo.submitting}
                  />
                  <Form.Control.Feedback type="invalid">
                    Selected file is too large.
                  </Form.Control.Feedback>
                  <Form.Text>
                    Accepts .png, .jpg and .jpeg, files with a limit of 512KB.
                    Will be scaled to fit within 32x32px.
                  </Form.Text>
                </Col>
              </Row>
            </Form.Group>
            {userInfo.branding_profile_limit > 1 ? (
              <>
                <Form.Group className="mb-4">
                  <Form.Label>Branding Profile Name</Form.Label>
                  <Form.Control
                    value={textVals.profile_name || textVals.name}
                    name="profile_name"
                    onChange={onTextChange}
                    onBlur={onTextBlur}
                    disabled={submitInfo.submitting}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </Form.Group>
                {(brandingProfiles.length > 1 ||
                  (brandingProfiles.length == 1 &&
                    selectedBrandProfile == -1)) &&
                (selectedBrandProfile == -1 ||
                  !(brandingProfiles[selectedBrandProfile] || {})
                    .is_default) ? (
                  <Form.Group className="mb-4">
                    <Form.Check
                      label="Make Default Branding Profile"
                      name="make_default"
                      checked={defaultChecked}
                      onChange={(e) => setDefaultChecked(e.target.checked)}
                      disabled={submitInfo.submitting}
                    />
                  </Form.Group>
                ) : null}
              </>
            ) : null}

            <Button
              type="submit"
              disabled={submitInfo.submitting || invalid.some((e) => e)}
              className="mt-2"
            >
              {submitInfo.submitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="setting branding"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
          </Form>
          {selectedBrandProfile != -1 &&
          (brandingProfiles[selectedBrandProfile] || {}).id ? (
            <Button
              className="bg-danger"
              onClick={() => setShowRemoveModal(true)}
            >
              Remove Branding
            </Button>
          ) : null}
        </>
      )}
      <RemoveModal
        isOrg={props.isOrg}
        show={showRemoveModal}
        brandId={
          selectedBrandProfile == -1
            ? null
            : (brandingProfiles[selectedBrandProfile] || {}).id
        }
        onCancel={() => setShowRemoveModal(false)}
        onDelete={(brandId) => {
          let temp = brandingProfiles.filter((e) => e.id != brandId);
          if (!temp.length) {
            temp = [{}];
          }
          setBrandingProfiles(prepBrandProfileList({ t: temp }, "t"));
          setSelectedBrandProfile(0);
        }}
      />
    </>
  ) : (
    <div className="d-flex justify-content-center">
      <Spinner animation="border" role="loading" className="text-secondary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default BrandingForm;

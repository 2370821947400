import { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";

const PaySetupCompleteComponent = (props) => {
  const getUrl = (nextAction) => {
    const s = nextAction[nextAction.type];
    return {
      redirect: !!s.url,
      url: s.hosted_voucher_url || s.hosted_instructions_url || s.url,
    };
  };
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {props.setupIntent && props.setupIntent.status == "requires_action" ? (
        <div className="text-center">
          <p>Further action is required to update your payment method.</p>
          <Button
            className="w-25"
            onClick={() => {
              if (getUrl(props.setupIntent.next_action).redirect) {
                window.open(
                  getUrl(props.setupIntent.next_action).url,
                  "_blank"
                );
              } else {
                setShowModal(true);
              }
            }}
          >
            Continue Payment
          </Button>
          {!getUrl(props.setupIntent.next_action).redirect ? (
            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              dialogClassName="modal-90pct"
              contentClassName="w-100 h-100"
            >
              <Modal.Header closeButton closeVariant="white">
                <h3>Payment Instructions</h3>
              </Modal.Header>
              <Modal.Body>
                <iframe
                  src={getUrl(props.setupIntent.next_action).url}
                  id="next-actions"
                  style={{ width: "100%", height: "100%" }}
                />
              </Modal.Body>
            </Modal>
          ) : null}
        </div>
      ) : null}
      {props.setupIntent && props.setupIntent.status == "succeeded" ? (
        <>We have update your payment method. Thank you for using Snorkle.</>
      ) : null}
      {props.setupIntent && props.setupIntent.status == "processing" ? (
        <>Your payment method change is processing.</>
      ) : null}
      {props.setupIntent &&
      props.setupIntent.status == "requires_payment_method" ? (
        <div className="text-danger">
          Your payment method has been declined. Please check the details and
          try again.
        </div>
      ) : null}
      {props.setupIntent && props.setupIntent.status == "canceled" ? (
        <>Your payment method change has been canceled.</>
      ) : null}
    </>
  );
};

export default PaySetupCompleteComponent;

import { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { APISetVisThumbnail } from "../../helper/APIFunctions";
import { download, scaleImage } from "../../helper/Utils";

const SnapshotModal = (props) => {
  const [message, setMessage] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(false);
  const [nocacheUrlSuffix, _] = useState(`?now=${Date.now()}`)

  const baseListener = (event, isThumb) => {
    const data = event.data;
    if (data.description !== "Snapshot") {
      return;
    }
    if (!data.result) {
      setMessage({
        valid: false,
        msg: "Invalid View. Please make sure the visual is fully loaded.",
      });
      return;
    }
    setDisabled(true);
    if (isThumb) {
      scaleImage(data.result, 1024, (dataUri) => {
        APISetVisThumbnail(
          props.vis,
          dataUri,
          () => {
            setMessage({ valid: true, msg: "Thumbnail Updated Successfully!" });
            props.onNewThumb(dataUri);
            setDisabled(false);
          },
          (err) => {
            setMessage({
              valid: false,
              msg: (err && err.msg) || err || "Internal Server Error",
            });
            setDisabled(false);
          },
          props.isOrg
        );
      });
    } else {
      download(props.filebase + ".png", data.result);
      setDisabled(false);
    }
  };

  const fileListener = (event) => baseListener(event, false);
  const thumbListener = (event) => baseListener(event, true);

  useEffect(() => {
    window.addEventListener(
      "message",
      currentRequest == "thumbnail" ? thumbListener : fileListener
    );
    return () => {
      window.removeEventListener("message", fileListener);
      window.removeEventListener("message", thumbListener);
    };
  }, [currentRequest]);

  const viewerId = "snapshot-viewer" + props.vis.id;
  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById(viewerId)
        .contentWindow.postMessage("ForceViewerOptions", "*");
    }, 100);
  }, []);
  return (
    <Modal
      show="true"
      onHide={props.onClose}
      dialogClassName="modal-90pct"
      contentClassName="w-100 h-100"
    >
      <Modal.Header closeButton closeVariant="white">Snapshot Visual View</Modal.Header>
      <Modal.Body
        className="text-center d-flex flex-column"
        style={{ height: "100%" }}
      >
        {message ? (
          <p
            className={
              "text-center " + (message.valid ? "text-success" : "text-danger")
            }
          >
            {message.msg}
          </p>
        ) : null}
        <div
          style={{
            flexGrow: 1,
            position: "relative",
            overflow: "hidden",
            width: "100%",
            minWidth: "300px",
            minHeight: "300px",
          }}
        >
          <iframe
            src={props.url+ nocacheUrlSuffix}
            id={viewerId}
            className="border border-secondary rounded"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <div>
          <Button
            onClick={() => {
              setCurrentRequest("file");
              setDisabled(true);
              document
                .getElementById(viewerId)
                .contentWindow.postMessage("GetSnapshot", "*");
            }}
            className="mt-4"
            disabled={disabled}
          >
            {disabled && currentRequest == "file" ? (
              <Spinner
                animation="border"
                role="loading"
                className="text-secondary align-center"
                size="sm"
              />
            ) : (
              "Download As File"
            )}
          </Button>
          {props.canEdit ? (
            <Button
              onClick={() => {
                setCurrentRequest("thumbnail");
                setDisabled(true);
                document
                  .getElementById(viewerId)
                  .contentWindow.postMessage("GetSnapshot", "*");
              }}
              className="mt-4"
              disabled={disabled}
            >
              {disabled && currentRequest == "thumbnail" ? (
                <Spinner
                  animation="border"
                  role="loading"
                  className="text-secondary align-center"
                  size="sm"
                />
              ) : (
                "Make Visual Thumbnail"
              )}
            </Button>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SnapshotModal;

import { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { APIRequestPasswordReset } from "../helper/APIFunctions";
import * as Yup from "yup";
import { Formik } from "formik";

const ForgotPasswordForm = (props) => {
  const [result, setResult] = useState(null);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required").email("Invalid Format"),
  });
  return (
    <>
      {result ? <p className={result.style}>{result.message}</p> : null}

      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setResult(null);
          APIRequestPasswordReset(
            values.email,
            (body) => {
              setResult({
                style: "text-center text-success",
                message:
                  "If we have your email on file, we will send a password reset request shortly.",
              });
              actions.setSubmitting(false);
            },
            (body) => {
              setResult({ style: "text-danger text-center", message: body.toString() });
              actions.setSubmitting(false);
            }
          );
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="validationFormik01" className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Account Email"
                autoComplete="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                isInvalid={touched.email && errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
              <button onClick={props.closeForgetPass} className="btn btn-link"  type="button">
                Back
              </button>
            </Form.Group>
            <Button type="submit" className="mt-2" disabled={isSubmitting}>
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="login"
                  aria-hidden="true"
                />
              ) : (
                "Request Password Reset"
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPasswordForm;

import { useEffect, useState, useCallback } from "react";
import { shallowCopy } from "../helper/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Button, Spinner, Modal } from "react-bootstrap";
import { faCaretUp, faCaretDown, faCircleCheck as faSuccess, faCircleQuestion as faPending, faCircleXmark as faFailed, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
const getIcon = (status) => {
    switch (status) {
        case "success":
            return faSuccess;
        case "failure":
            return faFailed;
        case "pending":
        default:
            return faPending;
    }
}
const getColorClass = (status) => {
    switch (status) {
        case "success":
            return "text-success";
        case "failure":
            return "text-danger";
        case "processing":
        default:
            return "";
    }
}
const getStatusText = (status) => {
    switch (status) {
        case "success":
            return "has succeeded";
        case "failure":
            return "has failed";
        case "processing":
        default:
            return "is processing";
    }
}

const MessageCenter = (props) => {
    const [requests, setRequests] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const eventHandler = (event) => {
        const d = event.data
        if (!d.description || !d.id) {
            return;
        }
        const ind = requests.findIndex(v => v.id === d.id);
        let t = requests.map(v => shallowCopy(v));
        if (d.description === "APIQueryStart") {
            t.push({ id: d.id, title: d.title, status: "processing" });
        }
        else if (d.description === "APIQuerySuccess") {
            if (ind > -1) {
                t[ind].status = "success";
                t[ind].message = d.message;
                t[ind].url = d.url;
            }
            else {
                t.push({ id: d.id, title: d.title, status: "success", message: d.message })
            }
            if (!d.url) {
                setTimeout(() => window.postMessage({ id: d.id, description: "APIQueryClear" }), 30000);
            }
        }
        else if (d.description === "APIQueryFailure") {
            if (ind > -1) {
                t[ind].status = "failure";
                t[ind].message = d.message;
            }
            else {
                t.push({ id: d.id, title: d.title, status: "failure", message: d.message })
            }
        }
        else if (d.description === "APIQueryClear") {
            if (ind > -1) {
                t = t.filter(req => req.id !== d.id)
            }
        }
        else {
            return;
        }
        setRequests(t);
    };
    useEffect(() => {
        window.addEventListener("message", eventHandler);
        return () => { window.removeEventListener("message", eventHandler) };
    }, [requests]);
    const removeMessage = (id) => {
        setRequests(requests.filter(f => f.id != id));
    }
    const pendingCount = requests.filter(r => r.status == "processing").length;
    const successCount = requests.filter(r => r.status == "success").length;
    const failedCount = requests.filter(r => r.status == "failure").length;
    return <>
        {requests.length ?
            <div style={{ position: "sticky", bottom: "0px", left: "0px", height: "0", overflow: "visible" }}>
                <div style={{ position: "relative" }}>
                    <div className="px-1" style={{ height: "fit-contents", backgroundColor: "var(--nav-footer-bg)", color: "rgba(255,255,255,0.75)", bottom: "0px", position: "absolute", width: "fit-content" }}>
                        <button onClick={() => setExpanded(!expanded)} className="secret-button w-100 px-2">
                            Tasks:
                            {pendingCount ? <span className="ms-2">
                                <Spinner size="sm" title={`${pendingCount} task${pendingCount > 1 ? 's' : ''} processing`} />{pendingCount}</span> : null}
                            {successCount ? <span className="text-success ms-2">
                                <FontAwesomeIcon icon={faSuccess} className="mx-1" title={`${successCount} task${successCount > 1 ? 's' : ''} succeeded`} />{successCount}</span> : null}
                            {failedCount ? <span className="text-danger mx-2">
                                <FontAwesomeIcon icon={faFailed} className="mx-1" title={`${failedCount} task${failedCount > 1 ? 's' : ''} failed`} />{failedCount}</span> : null}
                            <span className="ms-2"><FontAwesomeIcon icon={expanded ? faCaretDown : faCaretUp} /></span>
                        </button>
                        {expanded ? <div style={{ backgroundColor: "var(--main-bg-color)", color: "var(--text-color)", maxHeight: "19rem", overflowY: "auto" }}>
                            <Table striped size="sm">
                                <tbody>
                                    {
                                        requests.map(r => <tr key={r.id}>
                                            <td style={{ maxWidth: "15rem" }} title={r.title} className="text-trailoff">{r.title}</td>
                                            <td>{r.status !== "processing" ?
                                                <FontAwesomeIcon icon={getIcon(r.status)} className={getColorClass(r.status)} title={`${r.title} ${getStatusText(r.status)}`} /> :
                                                <Spinner title={`${r.title} ${getStatusText(r.status)}`} size="sm" />}
                                            </td>{r.url ? <td><button className="secret-button" onClick={()=>setPreviewUrl(r.url)}><FontAwesomeIcon icon={faUpRightFromSquare} title="Preview this visual" /></button></td> :
                                                <td style={{ maxWidth: "15rem" }} className="text-trailoff" title={r.message}>{r.message}</td>}

                                        </tr>)
                                    }
                                </tbody>
                            </Table>
                            {successCount + failedCount >= 1 ? <Button className="w-100" onClick={() => {
                                const t = [...requests.filter(r => r.status === "processing")];
                                setRequests(t);
                            }}>Clear Finished Tasks</Button> : null}
                        </div> : null}
                    </div>
                </div>
            </div> : null}
        {previewUrl ? <Modal
            show={true}
            onHide={() => {
                setPreviewUrl(null);
            }}
            dialogClassName="modal-90pct"
            contentClassName="w-100 h-100"
        >
            <Modal.Header closeButton closeVariant="white">
                Visual Preview
            </Modal.Header>
            <Modal.Body
                className="text-center d-flex flex-column"
                style={{ height: "100%" }}
            >
                <div
                    className="d-flex flex-column"
                    style={{
                        flexGrow: 1,
                        position: "relative",
                        overflow: "hidden",
                        width: "100%",
                        minWidth: "300px",
                        minHeight: "300px",
                    }}
                >
                    <iframe
                        src={previewUrl}
                        id={`preview-modal-tasks`}
                        className="border border-secondary rounded"
                        style={{
                            width: "100%",
                            height: "100%",
                            flexGrow: 1,
                        }}
                    />
                </div>
            </Modal.Body>
        </Modal>
            : null}
    </>
}

export default MessageCenter
import { useState, useEffect, useContext } from "react";
import { APIGetUserInfo } from "../helper/APIFunctions";
import { UserInfo } from "../helper/Context";
import { Modal, Button } from "react-bootstrap";
import { pollForNewTier } from "../helper/Utils";

const PaymentCompleteComponent = (props) => {
  const getUrl = (nextAction) => {
    const s = nextAction[nextAction.type];
    return {
      redirect: !!s.url,
      url: s.hosted_voucher_url || s.hosted_instructions_url || s.url,
    };
  };
  const [userTierUpdated, setUserTierUpdated] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { userInfo, setUserInfo } = useContext(UserInfo);

  useEffect(() => {
    if (props.paymentIntent.status == "succeeded" && props.newTier) {
      pollForNewTier(
        userInfo,
        setUserInfo,
        props.newTier,
        () => {
          setUserTierUpdated(true);
          if (props.onTierUpdate) {
            props.onTierUpdate();
          }
        },
        () => {
          setUpdateError(true);
          if (props.onTierUpdate) {
            props.onTierUpdate();
          }
        }
      );
    }
    else if (props.paymentIntent.status == "succeeded"){
      props.onSuccess();
    }
  }, [props.payment_intent]);

  return (
    <>
      {props.paymentIntent &&
      props.paymentIntent.status == "requires_action" ? (
        <div className="text-center">
          <p>Further action is required to complete your payment.</p>
          <Button
            className="w-25"
            onClick={() => {
              if (getUrl(props.paymentIntent.next_action).redirect) {
                window.open(
                  getUrl(props.paymentIntent.next_action).url,
                  "_blank"
                );
              } else {
                setShowModal(true);
              }
            }}
          >
            Continue Payment
          </Button>
          {!getUrl(props.paymentIntent.next_action).redirect ? (
            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              dialogClassName="modal-90pct"
              contentClassName="w-100 h-100"
            >
              <Modal.Header closeButton closeVariant="white">
                <h3>Payment Instructions</h3>
              </Modal.Header>
              <Modal.Body>
                <iframe
                  src={getUrl(props.paymentIntent.next_action).url}
                  id="next-actions"
                  style={{ width: "100%", height: "100%" }}
                />
              </Modal.Body>
            </Modal>
          ) : null}
        </div>
      ) : null}
      {props.paymentIntent && props.paymentIntent.status == "succeeded" ? (
        props.newTier ? (
          <>
            {!userTierUpdated ? (
              <>
                Thank you so much for your {props.paymentIntent.object=="setup_intent"? "interest" :"payment"}. Please wait while we update
                your Snorkle plan.
              </>
            ) : (
              <div className="text-success">
                Thank you so much for your {props.paymentIntent.object=="setup_intent"? "interest" :"payment"}. We have update your Snorkle
                plan. Please enjoy.
              </div>
            )}
            {updateError ? (
              <div className="text-danger">
                We are unable to verify your account upgrade at this time.
                Please contact us to resolve this issue.
              </div>
            ) : null}
          </>
        ) : (
          <>
            {" "}
            Thank you so much for your payment. We are adding your premium
            bundles, and they will be available shortly. If this process takes
            longer than 15 minutes, please contact us.
          </>
        )
      ) : null}
      {props.paymentIntent && props.paymentIntent.status == "processing" ? (
        <>
          Your payment is processing. We will email you when payment is
          complete.
        </>
      ) : null}
      {props.paymentIntent &&
      props.paymentIntent.status == "requires_payment_method" ? (
        <div className="text-danger">
          Your payment has been declined. Please check the payment details and
          try again.
        </div>
      ) : null}
      {props.paymentIntent && props.paymentIntent.status == "canceled" ? (
        <>Your payment has been canceled.</>
      ) : null}
    </>
  );
};

export default PaymentCompleteComponent;

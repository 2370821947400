import { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Card, Offcanvas, Image, Row, Col } from "react-bootstrap";
import { shallowCopy, prepBrandProfileList } from "../helper/Utils";
import { UserInfo } from "../helper/Context";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import {
  faX as removeIcon,
  faLink,
  faLock,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import { APIAddEditPresentation } from "../helper/APIFunctions";
const CardWrapper = (props) => {
  return (
    <div
      style={{
        display: "inline-flex",
        width: props.isVertical ? "100%" : "initial",
        flexDirection: props.isVertical ? "column" : "row",
        flexWrap: "nowrap",
      }}
    >
      {props.children}
    </div>
  );
};
export const PresentationEditCanvas = (props) => {
  const [editTitle, setEditTitle] = useState(true);
  const [error, setError] = useState(null);
  const [isVertical, setIsVertical] = useState(window.innerWidth > 768);
  const [presentation, setPresentation] = useState(null);
  const [autoAdvance, setAutoAdvance] = useState(false);
  const [autoTimeText, setAutoTimeText] = useState("");
  const [autoTimeError, setAutoTimeError] = useState(false);
  const [resizeCallback, setResizeCallback] = useState(null);
  const [editDescIdx, setEditDescIdx] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [show, setShow] = useState(true);
  const { userInfo, setUserInfo } = useContext(UserInfo);
  const brandProfiles = prepBrandProfileList(userInfo.branding_profiles);
  const fileListToThumb = (fileList, onError = () => { }) => {
    let f = null;
    let i = 0;
    while (!f && i < fileList.length) {
      const cf = fileList[i];
      f = cf.type.startsWith("image") && cf.size <= 2 * 2048 * 2048 ? cf : f;
      i++;
    }
    if (!f) {
      onError("Files must be images and smaller than 2MB");
      return;
    }
    const imgLoader = new FileReader();
    imgLoader.onload = () => {
      const p = shallowCopy(presentation);
      p.thumbnail = imgLoader.result;
      setPresentation(p);
    };
    imgLoader.onerror = () => {
      onError("Unable to load image file");
    };
    imgLoader.readAsDataURL(f);
  };

  useEffect(() => {
    setResizeCallback(
      window.addEventListener("resize", () =>
        setIsVertical(window.innerWidth > 768)
      )
    );
    return () => window.removeEventListener("resize", resizeCallback);
  }, []);
  useEffect(() => {
    if (!props.getVisual || !props.presentation) {
      return;
    }
    if (presentation && presentation.id == props.presentation.id) {
      return;
    }
    const arr = props.presentation.slides
      .map((s) => {
        let vis = props.getVisual(s.visual_id);
        if (!vis) {
          vis = props.getVideo(s.visual_id);
        }
        return vis
          ? {
            visual_url: vis.visualization_url,
            visual_id: s.visual_id,
            intro: s.intro,
            title: vis.title,
            slideThumb: vis.thumbnail,
            isPrivate: vis.privacy == "private",
          }
          : null;
      })
      .filter((e) => e);
    const t = shallowCopy(props.presentation);
    t.slides = arr;
    t.branding_profile = t.branding_profile || brandProfiles[0].id || null;
    setPresentation(t);
    setAutoTimeText((t.auto_advance || 20).toString());
    setAutoAdvance(!!t.auto_advance);
  }, [props.presentation, props.getVisual]);
  const setIconFromVisualId = (id) => {
    if (!props.getVisual || !presentation) {
      return;
    }
    const newVis = props.getVisual(id);
    if (!newVis || !newVis.thumbnail) {
      return;
    }
    var image = new window.Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      context.drawImage(this, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      const p = shallowCopy(presentation);
      p.thumbnail = dataURL;
      setPresentation(p);
    };
    image.src = newVis.thumbnail;
  };
  useEffect(() => {
    if (!/^\d*(.|,)\d*$/.test(autoTimeText) || !/.*\d.*/.test(autoTimeText)) {
      setAutoTimeError(true);
    } else {
      setAutoTimeError(false);
      const p = shallowCopy(presentation);
      p.auto_advance = parseFloat(autoTimeText);
      setPresentation(p);
    }
  }, [autoTimeText]);

  useEffect(() => {
    if (
      !presentation ||
      presentation.id ||
      presentation.thumbnail ||
      !props.presentation.slides
    ) {
      return;
    }
    const iconThumb = presentation.slides.find((v) => v.slideThumb);
    if (iconThumb) {
      setIconFromVisualId(iconThumb.visual_id);
    }
  }, [presentation]);

  return presentation ? (
    <Offcanvas
      placement={isVertical ? "end" : "bottom"}
      style={{
        backgroundColor: isSaving
          ? "var(--main-bg-color)"
          : "var(--bs-offcanvas-bg)",
        height: isVertical ? "initial" : "20rem",
        maxHeight: isVertical ? "initial" : "50vh",
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.stopPropagation();
          const e = new Event("click", { bubbles: true, cancelable: true });
          event.target.dispatchEvent(e);
        }
      }}
      backdrop={false}
      autoFocus={false}
      enforceFocus={false}
      restoreFocus={false}
      show={show}
      scroll={true}
      onHide={() => {
        if (isSaving) {
          return;
        }
        setShow(false);
      }}
      onExited={() => {
        props.onClose();
        setPresentation(null);
      }}
      onDrop={(event) => {
        if (isSaving) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        const data = event.dataTransfer.getData("text/plain");
        if (event.dataTransfer.files) {
          const fileList = event.dataTransfer.files;
          fileListToThumb(fileList);
        }
        if (!data) {
          return;
        }
        const [type, id] = data.split(":");
        if (!type || !id) {
          return;
        }
        if (type != "visual" && type != "presentationVisual" && type != "video") {
          return;
        }
        if (type == "visual" || type == "video") {
          const p = shallowCopy(presentation);
          const newVis = (type == "visual" ? props.getVisual : props.getVideo)(id);
          if (!newVis) {
            return;
          }
          if (
            !presentation.thumbnail &&
            !(presentation.slides || []).length &&
            !presentation.id
          ) {
            setIconFromVisualId(id);
          }

          p.slides.push({
            visual_url: newVis.visualization_url,
            visual_id: newVis.id,
            intro: newVis.description,
            title: newVis.title,
            slideThumb: newVis.thumbnail,
            isPrivate: newVis.privacy == "private",
          });
          setPresentation(p);
        } else if (type == "presentationVisual") {
          const dropLocation = isVertical
            ? event.nativeEvent.clientY
            : event.nativeEvent.clientX;
          const cards = event.currentTarget.querySelectorAll(".card");
          let newInd = 0;
          for (const card of cards) {
            if (card.dataset.slideIndex == id) {
              continue;
            }
            const b = card.getBoundingClientRect();
            const cardStart = isVertical ? b.top : b.left;
            const cardEnd = isVertical ? b.bottom : b.right;
            if (cardStart <= dropLocation && dropLocation <= cardEnd) {
              newInd = parseInt(card.dataset.slideIndex);
              break;
            }
            if (dropLocation > cardEnd) {
              const si = parseInt(card.dataset.slideIndex);
              newInd = Math.max(newInd, si > id ? si : si + 1);
            }
          }
          const numInd = parseInt(id);
          if (newInd != numInd) {
            const newP = shallowCopy(presentation);
            newP.slides = newP.slides.filter((e, i) => i != numInd);
            newP.slides.splice(newInd, 0, presentation.slides[numInd]);
            setPresentation(newP);
          }
        }
      }}
      onDragOver={(event) => {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = "move";
      }}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title
          className="flex-grow-1"
        // onClick={(e) => {
        //   if (isSaving) {
        //     return;
        //   }
        //   if (editTitle) {
        //     e.stopPropagation();
        //   }
        //   if (e.detail == 2) {
        //     e.stopPropagation();
        //     setEditTitle(!editTitle);
        //   }
        // }}
        >
          {editTitle ? (
            <Form.Control
              value={presentation.title}
              plaintext
              onKeyDown={(event) => {
                if (event.key === "Enter" && !isSaving) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
              style={{
                display: "inline",
                padding: 0,
                backgroundColor: "#f2f2f2", //TODO
                borderBottom: "1px solid #ced4da",
                lineHeight: 1,
                zIndex: 2000000,
              }}
              onChange={(e) => {
                const p = shallowCopy(presentation);
                p.title = e.target.value;
                setPresentation(p);
              }}
              disabled={isSaving}
            />
          ) : (
            presentation.title
          )}
        </Offcanvas.Title>
        <FontAwesomeIcon
          icon={faSave}
          role={
            !(presentation.slides || []).length ||
              (autoAdvance && autoTimeError)
              ? ""
              : "button"
          }
          title="Save Presentation"
          size="xl"
          tabIndex="0"
          style={{
            opacity:
              !presentation.slides.length || (autoAdvance && autoTimeError)
                ? 0.5
                : 1,
          }}
          onClick={() => {
            if (isSaving || !presentation.slides.length) {
              return;
            }
            const p = shallowCopy(presentation);
            if (!autoAdvance) {
              p.auto_advance = 0;
            }
            APIAddEditPresentation(
              p,
              (res) => {
                props.onPresentationEdit(res);
                setShow(false);
              },
              (err) => {
                setError(err instanceof String ? err.toString() : "Internal Server Error");
                setIsSaving(false);
              },
              props.isOrg
            );
            setError(null);
            setIsSaving(true);
          }}
        />
      </Offcanvas.Header>
      <Offcanvas.Body className={`d-flex ${isVertical ? "flex-column" : ""}`}>
        {error ? <h5 className="TitleText text-danger">{error}</h5> : null}
        <Form.Control
          type="file"
          accept=".png,.jpg,.jpeg"
          onChange={(event) => {
            fileListToThumb(event.target.files);
          }}
          disabled={isSaving}
          id="update-pres-icon-form"
          className="d-none"
        />
        <Row style={{ minWidth: "20em", maxWidth: isVertical ? "" : "30em" }}>
          <Col xs={isVertical ? 4 : 3} className="pe-0">
            <div
              style={{ position: "relative" }}
              className="border border-secondary w-100 h-100"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                document.getElementById("update-pres-icon-form").click();
              }}
              onDragOver={(event) => {
                event.preventDefault();
                event.stopPropagation();
                event.dataTransfer.dropEffect = "move";
              }}
              onDrop={(event) => {
                if (isSaving) {
                  return;
                }
                const data = event.dataTransfer.getData("text/plain");
                if (!data) {
                  return;
                }
                const [type, id] = data.split(":");
                if (!type || !id) {
                  return;
                }
                if (type != "visual") {
                  return;
                }
                event.preventDefault();
                event.stopPropagation();
                setIconFromVisualId(id);
              }}
            >
              <Image
                style={{ objectFit: "contain", width: "100%", height: "100%" }}
                src={
                  presentation.thumbnail ||
                  "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"
                }
              />
              <FontAwesomeIcon
                icon={faCamera}
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: "0.1rem",
                  zIndex: 1000,
                }}
                role="button"
                tabIndex="0"
              />
            </div>
          </Col>
          <Col xs={isVertical ? 8 : 9} className="ps-0">
            <Form.Control
              as="textarea"
              onChange={(e) => {
                const p = shallowCopy(presentation);
                p.description = e.target.value;
                setPresentation(p);
              }}
              rows={isVertical ? 6 : 2}
              placeholder="Presentation Description"
              value={presentation.description}
              disabled={isSaving}
            />
            <Form.Check
              type="checkbox"
              label="Enable Text-to-Speech"
              className="ms-1"
              checked={presentation.enable_tts}
              onChange={(event) => {
                const p = shallowCopy(presentation);
                p.enable_tts = event.target.checked;
                setPresentation(p);
              }}
              disabled={isSaving}
            />
          </Col>
          <Form.Group>
            <Form.Label>Auto-advance:</Form.Label>
            <Form.Check
              className="mx-4"
              type="switch"
              checked={autoAdvance}
              onChange={(e) => setAutoAdvance(e.target.checked)}
              disabled={isSaving}
              inline
            />{" "}
            {autoAdvance ? (
              <>
                <Form.Control
                  inline
                  value={autoTimeText}
                  disabled={isSaving}
                  size="sm"
                  className="w-25 ms-auto me-1 d-inline"
                  onChange={(event) => {
                    setAutoTimeText(event.target.value);
                  }}
                  isInvalid={autoTimeError}
                />
                sec
              </>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Label>Show Visual QR Codes:</Form.Label>
            <Form.Check
              className="mx-4"
              type="switch"
              checked={presentation.show_visual_qrs}
              onChange={(event) => {
                const p = shallowCopy(presentation);
                p.show_visual_qrs = event.target.checked;
                setPresentation(p);
              }}
              disabled={isSaving}
              inline
            /></Form.Group>
          {userInfo.public_presentation ? (
            <Form.Select
              aria-label="Presentation visibility option"
              value={presentation.privacy || "unlisted"}
              className="my-1"
              onChange={(event) => {
                const p = shallowCopy(presentation);
                p.privacy = event.target.value;
                setPresentation(p);
              }}
              disabled={isSaving}
            >
              <option value="unlisted">Unlisted</option>
              <option value="public">Public</option>
            </Form.Select>
          ) : null
          }{
            (!presentation.id && (brandProfiles || []).length > 1) ?
              <Row>
                <Form.Label className="col-sm-3">Branding Profile: </Form.Label>
                <Form.Select
                  value={presentation.branding_profile}
                  onChange={(event) => {
                    const p = shallowCopy(presentation);
                    p.branding_profile = event.target.value;
                    setPresentation(p);
                  }}
                  disabled={props.disabled}
                  className="col-sm-8 w-75"
                >
                  {brandProfiles.map((e) => (
                    <option key={e.id} value={e.id}>
                      {e.profile_name} {e.is_default ? " (default)" : null}
                    </option>
                  ))}
                </Form.Select>
              </Row>
              : null
          }{ (!presentation.id && (brandProfiles || []).length > 1) }
        </Row>
        <CardWrapper isVertical={isVertical}>
          {!presentation.slides.length ?
            <Card style={{ fontSize: isVertical ? "400%" : "200%", border: "none", textAlign: "center", opacity: "0.5" }}>
              <Card.Body>Drag<br />Visuals<br />Here</Card.Body>
            </Card> : null}
          {presentation.slides.map((s, idx) => (
            <Card
              key={"slide_" + idx}
              className="m-1"
              data-slide-index={idx}
              draggable={idx != editDescIdx && !isSaving}
              onDragStart={(event) => {
                if (isSaving) {
                  return;
                }
                event.stopPropagation();
                event.dataTransfer.clearData();
                event.dataTransfer.setData(
                  "text/plain",
                  "presentationVisual:" + idx
                );
                const e = event.currentTarget;
                setTimeout(() => e.classList.add("d-none"), 3);
                event.dataTransfer.effectAllowed = "move";
              }}
              onDragEnd={(e) => {
                e.currentTarget.classList.remove("d-none");
              }}
              style={{
                width: isVertical ? "100%" : "15rem",
                flex: "unset",
                position: "relative",
              }}
            >
              <FontAwesomeIcon
                icon={removeIcon}
                size="xl"
                style={{
                  position: "absolute",
                  top: "0.25rem",
                  right: "0.25rem",
                }}
                tabIndex="0"
                onClick={() => {
                  if (isSaving) {
                    return;
                  }
                  const p = shallowCopy(presentation);
                  p.slides = p.slides.filter((e, i) => i != idx);
                  setPresentation(p);
                }}
              />
              <Card.Title className="me-4">
                <span className="clamp-lines-2">
                  {idx + 1}: {s.title}
                </span>
                <abbr title={(s.isPrivate ?"Protected Visual: ":"")+s.visual_url}>
                  <FontAwesomeIcon icon={s.isPrivate ? faLock : faLink} style={{ borderBottom: "dotted black" }} className="ms-2" />
                </abbr>
              </Card.Title>
              <Card.Body
                className="pb-0 pt-1"
                onClick={(e) => {
                  if (isSaving) {
                    return;
                  }
                  if (editDescIdx == idx) {
                    e.stopPropagation();
                  }
                  if (e.detail == 2) {
                    e.stopPropagation();
                    setEditDescIdx(idx == editDescIdx ? null : idx);
                  }
                }}
              >
                <b>Intro:</b>
                <div
                  className={
                    editDescIdx != s.visual_id ? "clamp-lines-3 mb-1" : "mb-1"
                  }
                  style={{ minHeight: "1.5rem" }}
                >
                  {editDescIdx == idx ? (
                    <Form.Control
                      as="textarea"
                      onChange={(e) => {
                        const p = shallowCopy(presentation);
                        p.slides = [...p.slides];
                        p.slides[idx] = shallowCopy(p.slides[idx]);
                        p.slides[idx].intro = e.target.value;
                        setPresentation(p);
                      }}
                      rows={isVertical ? 6 : 3}
                      value={s.intro}
                    ></Form.Control>
                  ) : (
                    <div tabIndex="0">{s.intro}</div>
                  )}
                </div>
              </Card.Body>
            </Card>
          ))}
        </CardWrapper>
      </Offcanvas.Body>
    </Offcanvas>
  ) : null;
};

export default PresentationEditCanvas;

import { useState } from "react";
import { Modal, Form, Button, Row, Spinner } from "react-bootstrap";
import { APIEditAudio } from "../../helper/APIFunctions";
const EditModal = (props) => {
  const [isCallingAPI, setIsCallingAPI] = useState(false);
  const [editError, setEditError] = useState(null);
  const [newAudio, setNewAudio] = useState(null);
  const [newAutoplay, setNewAutoplay] = useState(props.vis.audio_autoplay);
  const invalidAudio =
    newAudio && newAudio[0] && newAudio[0].size > 1024 * 1024 * 7;
  const validAudio =
    newAudio && newAudio[0] && newAudio[0].size <= 1024 * 1024 * 7;

  return (
    <Modal
      show={props.show}
      onHide={() => {
        setEditError(null);
        setNewAudio(null);
        setNewAutoplay(false);
        setIsCallingAPI(false);
        props.onCancel();
      }}
    >
      <Modal.Header closeButton closeVariant="white">
        Edit Visual Audio Track
      </Modal.Header>
      <Modal.Body>
        <p className="text-danger text-center">{editError}</p>
        <Form.Group className="mb-3">
          <Row>
            <Form.Label className="col-auto">New Audio Track</Form.Label>
            <Form.Control
              type="file"
              onChange={(event) => {
                setNewAudio(event.target.files);
              }}
              isInvalid={invalidAudio}
              accept="audio/mpeg"
              disabled={isCallingAPI}
            />
            <Form.Control.Feedback type="invalid">
              Selected file is too large.
            </Form.Control.Feedback>
            <Form.Text>Accepts .mp3, files with a limit of 7MB</Form.Text>
          </Row>
          <Form.Text></Form.Text>
        </Form.Group>
        <Form.Group className="mb-3">
          <Row>
            <Form.Check
              type="checkbox"
              label="Autoplay Audio (when allowed)."
              checked={newAutoplay}
              onChange={(event) => {
                setNewAutoplay(event.target.checked);
              }}
              disabled={isCallingAPI}
            />
          </Row>
        </Form.Group>
        <Button
          onClick={(event) => {
            setIsCallingAPI(true);
            setEditError(null);
            const onRead = (audioData) => {
              const other = {};
              APIEditAudio(
                props.vis,
                audioData,
                newAutoplay,
                (res) => {
                  props.onVisEdit(props.vis, res.audio_url, res.audio_autoplay);
                  setIsCallingAPI(false);
                  setEditError(null);
                },
                (err) => {
                  setEditError(err.error || err || "Internal Server Error.");
                  setIsCallingAPI(false);
                },
                props.isOrg
              );
            };
            if (!validAudio) {
              onRead(null);
            } else {
              const newReader = new FileReader();
              newReader.onload = (event) => {
                onRead(newReader.result);
              };
              newReader.onerror = () => {
                setEditError("Unable to read audio file.");
                setIsCallingAPI(false);
              };
              newReader.readAsDataURL(newAudio[0]);
            }
          }}
          disabled={isCallingAPI || invalidAudio}
        >
          {isCallingAPI ? (
            <Spinner
              animation="border"
              role="loading"
              className="text-secondary align-center"
              size="sm"
            />
          ) : (
            <>Confirm</>
          )}
        </Button>{props.vis.audio_url ? 
        <Button
          className="bg-danger"
          onClick={(event) => {
            setNewAudio(null);
            setNewAutoplay(false);
            APIEditAudio(
              props.vis,
              null,
              null,
              () => {
                props.onVisEdit(props.vis, null, false);
                setIsCallingAPI(false);
                setEditError(null);
              },
              (err) => {
                setEditError(err.error || err || "Internal Server Error.");
                setIsCallingAPI(false);
              },
              { remove_audio: true }
            );
            setIsCallingAPI(true);
          }}
          disabled={isCallingAPI}
        >{isCallingAPI ? (
            <Spinner
              animation="border"
              role="loading"
              className="text-secondary align-center"
              size="sm"
            />
          ) : (
            <>Remove Audio</>
          )}
        </Button> : null}
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;

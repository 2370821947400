import React, { useState, useEffect } from "react";
import { Button, Spinner, Table, Form, Row, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faArrowUp,
  faTrashCanArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  APIGetDeletedVisuals,
  APIRestoreDeletedVisual,
} from "../helper/APIFunctions";
import { UserInfo } from "../helper/Context";
import { getRestoreVisualWaitHrs } from "../helper/BusinessLogic";

const getTimeLeftStr = (timestamp) => {
  const diff = timestamp - (Date.now()/1000);
  if (diff > 86400){
    return `${Math.round(diff/86400)} day(s)`;
  }
  else if (diff > 3600){
    return `${Math.round(diff/3600)} hour(s)`;
  }
  return `${Math.max(Math.round(diff/60),1)} minute(s)`
}

const DeletedVisual = (props) => {
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [isCallingAPI, setIsCallingAPI] = useState(false);
  const [result, setResult] = useState({ error: null, success: false });
  return (
    <>
      {showRestoreModal ? (
        <Modal show={true}>
          <Modal.Body>
            {result.error ? (
              <p className="text-danger text-center">{result.error}</p>
            ) : null}
            {result.success ? (
              <p className="text-success text-center">Visual Restored!</p>
            ) : null}
            <p>Are you sure you want to restore {props.vis.title}?</p>
            <UserInfo.Consumer>
              {({ userInfo, setUserInfo }) => {
                if (userInfo && getRestoreVisualWaitHrs(userInfo.tier)) {
                  return (
                    <p>{`${
                      userInfo.tier.charAt(0).toUpperCase() +
                      userInfo.tier.slice(1)
                    } users can restore one visual every ${getRestoreVisualWaitHrs(
                      userInfo.tier
                    )} hours.`}</p>
                  );
                }
                return null;
              }}
            </UserInfo.Consumer>
            <Button
              onClick={(event) => {
                setIsCallingAPI(true);
                setResult({ error: null, success: false });
                APIRestoreDeletedVisual(
                  props.vis,
                  () => {
                    setIsCallingAPI(false);
                    setResult({ error: null, success: true });
                    setTimeout(() => {
                      setShowRestoreModal(false);
                      props.onVisualRestored(props.vis.id);
                    }, 2000);
                  },
                  (error) => {
                    setIsCallingAPI(false);
                    setResult({
                      success: false,
                      error:
                        error.message ||
                        error.toString() ||
                        "Internal Server Error",
                    });
                  }
                );
              }}
              disabled={isCallingAPI || result.success}
            >
              {isCallingAPI ? (
                <Spinner
                  animation="border"
                  role="loading"
                  className="text-secondary align-center"
                  size="sm"
                />
              ) : (
                <>Confirm</>
              )}
            </Button>
            <Button
              onClick={() => {
                setShowRestoreModal(false);
              }}
              disabled={isCallingAPI || result.success}
            >
              Cancel
            </Button>
          </Modal.Body>
        </Modal>
      ) : null}
      <tr>
      <td className="text-center">
          <FontAwesomeIcon
            icon={faTrashCanArrowUp}
            className="icon-btnlike"
            size="xl"
            aria-label="restore"
            role="button"
            tabIndex="0"
            title={`Restore "${props.vis.title}"`}
            onClick={(event) => {
              setShowRestoreModal(true);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setShowRestoreModal(true);
              }
            }}
          /><br />
          {getTimeLeftStr(props.vis.delete_timestamp)} left
        </td>
        <td>{props.vis.title}</td>
        <td>{props.vis.description}</td>
        <td style={{ wordBreak: "break-all" }}>
          {props.vis.visualization_url}
        </td>
      </tr>
    </>
  );
};

const DeletedVisualsPage = (props) => {
  const [visualizations, setVisualizations] = useState([]);
  const [userVisGetStatus, setUserVisGetStatus] = useState({
    sent: false,
    received: false,
  });
  const [fetchError, setFetchError] = useState(false);
  const userVisSuccessCB = (body) => {
    setVisualizations(body.sort((a,b) => (b.delete_timestamp || 0) - (a.delete_timestamp || 0))); //If <0 a appears before b
    setUserVisGetStatus({ sent: true, received: true });
  };
  const userVisFailureCB = (body) => {
    setFetchError(true);
    setUserVisGetStatus({ sent: true, received: true });
  };
  useEffect(() => {
    if (!userVisGetStatus.sent) {
      const func = APIGetDeletedVisuals;
      func(userVisSuccessCB, userVisFailureCB);
      setUserVisGetStatus({ sent: true, received: false });
    }
  }, [userVisGetStatus.sent, props.isOrg]);

  const [filterTerm, setFilterTerm] = useState("");
  const onVisualRestored = (id) => {
    setVisualizations(visualizations.filter((vis) => vis.id != id));
  };
  const prepareDeletedVisuals = (visuals, filterTermRaw) => {
    const filterTerm = filterTermRaw.toLowerCase();
    const filtered = visuals.filter(
      (vis) =>
        !filterTerm ||
        vis.title.toLowerCase().includes(filterTerm) ||
        vis.description.toLowerCase().includes(filterTerm) ||
        vis.id.toLowerCase().includes(filterTerm)
    );
    if (!visuals.length) {
      return <h5 className="TitleText">You don't have any deleted visuals.</h5>;
    }
    if (!filtered.length) {
      return (
        <h5 className="TitleText">
          No deleted visuals matching "{filterTermRaw}".
        </h5>
      );
    }
    return (
      <Table striped bordered hover size="sm" responsive="lg">
        <thead>
          <tr>
          <th>Restore?&nbsp;</th>
            <th>Title</th>
            <th>Description</th>
            <th style={{ minWidth: "20rem", width: "20%" }}>Old URL</th>
          </tr>
        </thead>
        <tbody>
          {filtered.map((vis) => {
            return (
              <DeletedVisual vis={vis} onVisualRestored={onVisualRestored} />
            );
          })}
        </tbody>
      </Table>
    );
  };
  return (
    <>
      <h3 className="TitleText">Deleted Visuals</h3>
      <p className="text-center">
        Visuals can be restored up to 90 days after being deleted.
      </p>
      {!userVisGetStatus.received ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="loading" className="text-secondary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : null}
      <div className={userVisGetStatus.received ? "" : "d-none"}>
        {fetchError ? (
          <h4 className="text-danger text-center" id="portfolio-fetch-error">
            Unable to fetch your deleted visuals. Please try again later.
          </h4>
        ) : (
          <>
            <Form.Group className="mb-3">
              <Row className="justify-content-center">
                <Form.Label className="col-auto align-self-center">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    size="lg"
                    className="align-middle"
                  />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={filterTerm}
                  className="w-75"
                  placeholder="Search Term"
                  onChange={(event) => {
                    setFilterTerm(event.target.value.toLowerCase());
                  }}
                />
              </Row>
            </Form.Group>
            {prepareDeletedVisuals(visualizations, filterTerm)}
          </>
        )}
      </div>
      {visualizations && visualizations.length > 3 ? (
        <Button
          className="scroll-up-btn"
          onClick={() => {
            window.scroll(0, 0);
            setTimeout(() => {
              var sel = document.querySelector(".scroll-up-btn");
              if (sel) {
                sel.blur();
              }
            }, 250);
          }}
        >
          <span>
            <FontAwesomeIcon icon={faArrowUp} size="lg" />
          </span>
        </Button>
      ) : null}
    </>
  );
};

export default DeletedVisualsPage;

import { shallowCopy } from "./Utils";
import JSZip from "jszip";
const APIEndpoint = process.env.REACT_APP_API_ENDPOINT;

const signalQueryStart = (id, title) => {
  window.postMessage({ description: "APIQueryStart", id: id, title: title });
}
const signalQuerySuccess = (id, url) => {
  window.postMessage({ description: "APIQuerySuccess", id: id, url: url });
}
const signalQueryFailure = (id, msg) => {
  window.postMessage({ description: "APIQueryFailure", id: id, message: msg });
}

const createUid = () =>
  String(
    Date.now().toString(32) +
    Math.random().toString(16)
  ).replace(/\./g, '')

export async function APILogin(email, pass, successCB, failureCB) {
  const url = APIEndpoint + "/login";
  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email, password: pass }),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIRegister(
  email,
  name,
  pass,
  passConfirm,
  useType,
  successCB,
  failureCB
) {
  const url = APIEndpoint + "/register";
  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: pass,
      passwordConfirm: passConfirm,
      useType: useType || "other",
      name: name,
    }),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIGetUserInfo(successCB, failureCB) {
  const url = APIEndpoint + "/userinfo";
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();
      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export function APIGetVisualizations(successCB, failureCB) {
  const url = APIEndpoint + "/visualizations";
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-Supports-Folders": "true",
    },
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export function APIWebVisualization(
  resourceId,
  resourceType,
  name,
  description,
  successCBInit,
  failureCBInit,
  isOrg,
  args
) {
  const id = createUid();
  signalQueryStart(id, `Creating ${name ? '"' + name + '"' : (resourceType.replace('_', ' ').toUpperCase() + " Visual: " + resourceId)}`)
  const successCB = (body) => { signalQuerySuccess(id, body.visualization_url); successCBInit(body) };
  const failureCB = (body) => { signalQueryFailure(id, body.error ? (Array.isArray(body.error) ? body.error[0] : body.error) : body.message || body || "There was a server error. Please try again later."); failureCBInit(body) }
  const url =
    APIEndpoint + (isOrg ? "/organization/add_visual" : "/visualizations/web");
  let req_header = { "Content-Type": "application/json" };
  return fetch(url, {
    method: "POST",
    headers: req_header,
    credentials: "include",
    body: JSON.stringify(
      Object.assign(args || {}, {
        resource_id: resourceId || "",
        resource_type: resourceType,
        title: name,
        description: description,
      })
    ),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

const pollForUploadedFile = (
  id,
  successCB,
  failureCB,
  waitSec,
  statusFunc,
  errorFunc = () => {
    "Internal Server Error";
  }
) => {
  const url = APIEndpoint + "/visualizations/" + id;
  setTimeout(() => {
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const body = await response.json();
        if (!response.ok) {
          failureCB(body);
        } else {
          const res = statusFunc(body);
          if (res === "error") {
            const res = errorFunc(body);
            failureCB(res);
          } else if (res === "complete") {
            successCB(body["visualization"]);
          } else {
            pollForUploadedFile(
              id,
              successCB,
              failureCB,
              15,
              statusFunc,
              errorFunc
            );
          }
        }
      })
      .catch((reason) => {
        failureCB(reason);
      });
  }, waitSec * 1000);
};

const uploadFileToS3 = (
  id,
  postUrl,
  fields,
  file,
  successCB,
  failureCB,
  statusFunc,
  errorFunc
) => {
  const formData = new FormData();
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key]);
  });
  formData.append("file", file);
  return fetch(postUrl, {
    method: "POST",
    body: formData,
  })
    .then(async (response) => {
      if (response.ok) {
        pollForUploadedFile(id, successCB, failureCB, 3, statusFunc, errorFunc);
        return true;
      } else {
        throw new Error("File upload failed");
      }
    })
    .catch((reason) => {
      failureCB(reason);
    });
};

export function APIFileVisualization(
  file,
  name,
  description,
  successCBInit,
  failureCBInit,
  isOrg,
  args = {}
) {
  const id = createUid();
  const dispName = name || (((args.filename || "").split('.')[0] || file.name || "FILE") + " Visual");
  signalQueryStart(id, `Creating ${dispName}`)
  const successCB = (body) => { signalQuerySuccess(id, body.visualization_url); successCBInit(body) };
  const failureCB = (body) => { signalQueryFailure(id, body.error ? (Array.isArray(body.error) ? body.error[0] : body.error) : body.message || body || "There was a server error. Please try again later."); failureCBInit(body) }
  const url =
    APIEndpoint +
    (isOrg ? "/organization/add_visual" : "/visualizations/files");
  let req_header = { "Content-Type": "application/json" };
  return fetch(url, {
    method: "POST",
    headers: req_header,
    credentials: "include",
    body: JSON.stringify(
      Object.assign(
        {
          filename: file.name || (typeof file === "string" ? file : "file"),
          title: name,
          description: description,
          resource_type: "file",
        },
        args
      )
    ),
  })
    .then(async (response) => {
      const body = await response.json();

      const vis_id = body["id"];
      const upload_url = body["url"] || null;
      if (response.ok) {
        if (upload_url) {
          uploadFileToS3(
            vis_id,
            upload_url,
            body["fields"],
            file,
            successCB,
            failureCB,
            (body) => body.status,
            (body) => body.message
          );
        } else {
          pollForUploadedFile(vis_id, successCB, failureCB, 3, (body) => body.status,
            (body) => body.message);
        }
      } else {
        failureCB(body);
      }
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APILogout() {
  const url = APIEndpoint + "/login";
  return fetch(url, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: "",
  });
}

export async function APIDeleteVisualizations(
  vis,
  successCBInit,
  failureCBInit,
  isOrg
) {
  // const id = createUid();
  // signalQueryStart(id, `Deleting ${vis.title ? '"' + vis.title + '"' : " Visual"}`)
  const successCB = successCBInit//(body) => { signalQuerySuccess(id, ""); successCBInit(body) };
  const failureCB = failureCBInit//(body) => { signalQueryFailure(id, body.error ? (Array.isArray(body.error) ? body.error[0] : body.error) : body.message || body || "There was a server error. Please try again later."); failureCBInit(body) }
  const url =
    APIEndpoint +
    (isOrg ? "/organization/remove_visual/" : "/visualizations/") +
    vis.id;
  return fetch(url, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: "",
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIResetPassword(
  newPassword,
  newPasswordConfirm,
  successCB,
  failureCB,
  resetToken,
  currentPass
) {
  const url = APIEndpoint + "/resetpass";
  let msgBody = {
    new_password: newPassword,
    new_password_confirm: newPasswordConfirm,
  };
  if (resetToken) {
    msgBody["token"] = resetToken;
  }
  if (currentPass) {
    msgBody["current_pass"] = currentPass;
  }
  return fetch(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(msgBody),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export function APIRequestPasswordReset(email, successCB, failureCB) {
  const url = APIEndpoint + "/requestpasswordreset";
  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIEditVisualizations(
  oldVis,
  newTitle,
  newDesc,
  newThumbnail,
  newShowShare,
  successCBInit,
  failureCBInit,
  isOrg,
  other = {}
) {
  //   const id = createUid();
  //   signalQueryStart(id, `Editing "${newTitle || oldVis.title}"`)
  const successCB = successCBInit; //(body) => { signalQuerySuccess(id, ""); successCBInit(body) };
  const failureCB = failureCBInit;//(body) => { signalQueryFailure(id, body.error ? (Array.isArray(body.error) ? body.error[0] : body.error) : body.message || body || "There was a server error. Please try again later."); failureCBInit(body) }
  const url =
    APIEndpoint +
    (isOrg ? "/organization/edit_visual/" : "/visualizations/") +
    oldVis.id;
  let msg_body = { newTitle: newTitle, newDescription: newDesc };
  if (newThumbnail) {
    msg_body.newThumbnail = newThumbnail;
  }
  if (newShowShare !== null) {
    msg_body.showShareDialog = newShowShare;
  }
  return fetch(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(Object.assign(other, msg_body)),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB("Internal Server Error.");
    });
}

export async function APIEditDefaultView(
  vis,
  newView,
  successCB,
  failureCB,
  isOrg
) {
  const url =
    APIEndpoint +
    (isOrg ? "/organization/edit_visual/" : "/visualizations/") +
    vis.id;
  return fetch(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ savedView: newView }),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB("Internal Server Error.");
    });
}

export async function APIGetOrgVisuals(successCB, failureCB) {
  const url = APIEndpoint + "/organization/get_visuals";
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-Supports-Folders": "true",
    },
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIGetOrgInfo(successCB, failureCB) {
  const url = APIEndpoint + "/organization/get_info";
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIInviteOrgMember(
  email,
  permissions,
  successCB,
  failureCB
) {
  const url = APIEndpoint + "/organization/add_member";
  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      permissions: permissions,
    }),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIRemoveMember(email, successCB, failureCB) {
  const url = APIEndpoint + "/organization/remove_member";
  return fetch(url, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIConfirmOrgJoin(token, successCB, failureCB) {
  const url = APIEndpoint + "/organization/confirm_join";
  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: token }),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIEditMember(email, permissions, successCB, failureCB) {
  const url = APIEndpoint + "/organization/edit_member";
  return fetch(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      permissions: permissions,
    }),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APISetBranding(
  name,
  website,
  twitter,
  icon,
  qr,
  favicon,
  profile_name,
  id,
  successCB,
  failureCB,
  isOrg,
  args = {}
) {
  const url =
    APIEndpoint + (isOrg ? "/organization/set_branding" : "/branding");
  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      Object.assign(args, {
        name: name,
        website: website,
        twitter: twitter,
        icon: icon,
        qr: qr,
        favicon: favicon,
        profile_name: profile_name,
        id: id,
      })
    ),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIRemoveBranding(id, successCB, failureCB, isOrg) {
  const url =
    APIEndpoint + (isOrg ? "/organization/set_branding" : "/branding");
  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: id, remove: true }),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIGetBranding(successCB, failureCB, isOrg) {
  const url =
    APIEndpoint + (isOrg ? "/organization/get_branding" : "/branding");
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APISetVisThumbnail(
  vis,
  newThumbnail,
  successCBInit,
  failureCBInit,
  isOrg
) {
  const id = createUid();
  signalQueryStart(id, `Set image for "${vis.title}"`);
  const successCB = (body) => { signalQuerySuccess(id, ""); successCBInit(body) };
  const failureCB = (body) => { signalQueryFailure(id, body.error ? (Array.isArray(body.error) ? body.error[0] : body.error) : body.message || body || "There was a server error. Please try again later."); failureCBInit(body) }
  const url =
    APIEndpoint +
    (isOrg ? "/organization/edit_visual/" : "/visualizations/") +
    vis.id;
  let msg_body = { newThumbnail, newThumbnail };
  return fetch(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(msg_body),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB("Internal Server Error.");
    });
}

export async function APIGetTags(successCB, failureCB) {
  const url = APIEndpoint + "/tags";
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIGetTaggedVisuals(slug, successCB, failureCB) {
  const url = APIEndpoint + "/tags/" + slug;
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIAddTags(newTags, successCB, failureCB) {
  const url = APIEndpoint + "/tags";
  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ tags: newTags }),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APISearchVisuals(term, successCB, failureCB) {
  const url = APIEndpoint + "/search?q=" + encodeURIComponent(term);
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();
      if (response.ok) {
        cbFunc(body);
      } else {
        failureCB(body);
      }
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIGetDeletedVisuals(successCB, failureCB) {
  const url = APIEndpoint + "/visualizations/deleted";
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIRestoreDeletedVisual(vis, successCBInit, failureCBInit) {
  const url = `${APIEndpoint}/visualizations/${vis.id}/restore`;
  // const id = createUid();
  // signalQueryStart(id, `Restoring "${vis.title}"`);
  const successCB = successCBInit// (body) => { signalQuerySuccess(id, ""); successCBInit(body) };
  const failureCB = failureCBInit// (body) => { signalQueryFailure(id, body.error ? (Array.isArray(body.error) ? body.error[0] : body.error) : body.message || body || "There was a server error. Please try again later."); failureCBInit(body) }
  return fetch(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIRequestSubscriptionModification(
  newTier,
  monthly,
  successCB,
  failureCB,
  password = "",
  other = {}
) {
  const url = `${APIEndpoint}/subscriptions/modify`;
  const body = shallowCopy(other);
  body.newTier = newTier;
  body.monthly = monthly;
  if (password) {
    body.password = password;
  }
  return fetch(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIGetBillingDetails(
  successCB,
  failureCB,
  password = ""
) {
  const url = `${APIEndpoint}/billing`;
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-Confirmation": password || "",
    },
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body, response.status);
    })
    .catch((reason) => {
      failureCB(reason, null);
    });
}

const DownloadS3File = (url, scb, fcb) => {
  fetch(url, {
    method: "GET",
  })
    .then(async (response) => {
      scb(await response.text());
    })
    .catch((reason) => fcb(reason));
};

export function APIGetDownloadableVersion(
  vis,
  successCBInit,
  failureCBInit,
  isOrg
) {
  const uid = createUid();
  signalQueryStart(uid, `Downloading "${vis.title}"`);
  const successCB = (body) => { signalQuerySuccess(uid, ""); successCBInit(body) };
  const failureCB = (body) => { signalQueryFailure(uid, body.error ? (Array.isArray(body.error) ? body.error[0] : body.error) : body.message || body || "There was a server error. Please try again later."); failureCBInit(body) }
  const core = (vis,
    successCB,
    failureCB,
    isOrg) => {
    const url =
      APIEndpoint +
      (isOrg
        ? `/organization/download_visual/${vis.id}`
        : `/visualizations/${vis.id}/download`);
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const body = await response.json();
        if (!response.ok) {
          failureCB(body);
        } else if (response.status == 200) {
          const downloadableUrl = body["url"];
          DownloadS3File(downloadableUrl, successCB, failureCB);
        } else if (response.status == 201) {
          setTimeout(
            core,
            5000,
            vis,
            successCB,
            failureCB,
            isOrg
          );
        } else {
          failureCB("Internal Server Error. Please try again later.");
        }
      })
      .catch((reason) => failureCB(reason));
  }
  core(vis, successCB, failureCB, isOrg);
}

export async function APICreateVisFolder(successCB, failureCB, isOrg) {
  const url = APIEndpoint + (isOrg ? "/organization/folders" : "/folders");
  return fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(null),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIEditVisualFolder(
  id,
  args,
  successCB,
  failureCB,
  isOrg
) {
  const url = `${APIEndpoint}/${isOrg ? "organization/folders" : "folders"
    }/${id}`;
  return fetch(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(args),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIDeleteVisualFolder(folder, successCB, failureCB, isOrg) {
  const url = `${APIEndpoint}/${isOrg ? "organization/folders" : "folders"
    }/${folder.id}`;
  return fetch(url, {
    method: "DELETE",
    credentials: "include",
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIAddEditPresentation(
  pres,
  successCBInit,
  failureCBInit,
  isOrg
) {
  // const uid = createUid();
  // signalQueryStart(uid, `${!pres.id ? "Creating" : "Editing"} "${pres.title}"`);
  const successCB = successCBInit //(body) => { signalQuerySuccess(uid, ""); successCBInit(body) };
  const failureCB = failureCBInit //(body) => { signalQueryFailure(uid, body.error ? (Array.isArray(body.error) ? body.error[0] : body.error) : body.message || body || "There was a server error. Please try again later."); failureCBInit(body) }
  const hasId = !!pres.id;
  let url = `${APIEndpoint}${isOrg ? "/organization" : ""}/presentation`;
  url += hasId ? "/" + pres.id : "";
  return fetch(url, {
    method: hasId ? "PUT" : "POST",
    credentials: "include",
    body: JSON.stringify(pres),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIDeletePresentation(pres, successCBInit, failureCBInit, isOrg) {
  // const uid = createUid();
  // signalQueryStart(uid, `Deleting "${pres.title}"`);
  const successCB = successCBInit // (body) => { signalQuerySuccess(uid, ""); successCBInit(body) };
  const failureCB = failureCBInit//(body) => { signalQueryFailure(uid, body.error ? (Array.isArray(body.error) ? body.error[0] : body.error) : body.message || body || "There was a server error. Please try again later."); failureCBInit(body) }
  const url = `${APIEndpoint}${isOrg ? "/organization" : ""
    }/presentation/${pres.id}`;
  return fetch(url, {
    method: "DELETE",
    credentials: "include",
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIEditVisLandmarks(
  vis,
  newLandmarks,
  successCBInit,
  failureCBInit,
  isOrg
) {
  // const uid = createUid();
  // signalQueryStart(uid, `Editing Landmarks for "${vis.title}"`);
  const successCB = successCBInit //(body) => { signalQuerySuccess(uid, ""); successCBInit(body) };
  const failureCB = failureCBInit//(body) => { signalQueryFailure(uid, body.error ? (Array.isArray(body.error) ? body.error[0] : body.error) : body.message || body || "There was a server error. Please try again later."); failureCBInit(body) }
  const url =
    APIEndpoint +
    (isOrg ? "/organization/edit_visual/" : "/visualizations/") +
    vis.id;
  return fetch(url, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ newLandmarks: JSON.stringify(newLandmarks) }),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB("Internal Server Error.");
    });
}

export async function APIAddEditBundle(bundle, successCB, failureCB) {
  const hasId = !!bundle.id;
  let url = `${APIEndpoint}/admin/bundle`;
  url += hasId ? "/" + bundle.id : "";
  return fetch(url, {
    method: hasId ? "PUT" : "POST",
    credentials: "include",
    body: JSON.stringify(bundle),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIGetBundles(successCB, failureCB) {
  const url = APIEndpoint + "/bundles";
  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIDeleteBundle(id, successCB, failureCB) {
  const url = `${APIEndpoint}/admin/bundle/${id}`;
  return fetch(url, {
    method: "DELETE",
    credentials: "include",
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIGetBundlePurchasePI(
  ids,
  billingPeriod,
  successCB,
  failureCB
) {
  const url = `${APIEndpoint}/bundles/purchase`;
  return fetch(url, {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify({ bundles: ids, period: billingPeriod }),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export async function APIUpdateUserInfo(info, successCB, failureCB) {
  const url = `${APIEndpoint}/userinfo`;
  return fetch(url, {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify(info),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();

      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export function APIContact(info, successCB, failureCB) {
  const url = `${APIEndpoint}/contact`;
  return fetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(info),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();
      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export function APIShareEmail(id, isPresentation, email, successCB, failureCB) {
  const url = `${APIEndpoint}/share`;
  const b = {
    id: id,
    isPresentation: isPresentation,
    destinationEmail: email,
  };
  return fetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(b),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();
      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export function APIEditAudio(
  vis,
  data,
  autoplay,
  successCBInit,
  failureCBInit,
  args = null
) {
  // const uid = createUid();
  // signalQueryStart(uid, `Editing audio for "${vis.title}"`);
  const successCB = successCBInit//(body) => { signalQuerySuccess(uid, ""); successCBInit(body) };
  const failureCB = failureCBInit//(body) => { signalQueryFailure(uid, body.error ? (Array.isArray(body.error) ? body.error[0] : body.error) : body.message || body || "There was a server error. Please try again later."); failureCBInit(body) }
  const url = `${APIEndpoint}/visualizations/${vis.id}/audio`;
  let body = args || {};
  Object.assign(body, {
    audio_data: data,
    autoplay: autoplay,
  });
  return fetch(url, {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify(body),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();
      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

export function APIEditLandmarksWithFiles(
  vis,
  newLandmarks,
  successCBInit,
  failureCBInit,
  isOrg,
  args = {}
) {
  // const uid = createUid();
  // signalQueryStart(uid, `Edit Landmarks for "${vis.title}"`);
  const successCB = successCBInit// (body) => { signalQuerySuccess(uid, ""); successCBInit(body) };
  const failureCB = failureCBInit// (body) => { signalQueryFailure(uid, body.error ? (Array.isArray(body.error) ? body.error[0] : body.error) : body.message || body || "There was a server error. Please try again later."); failureCBInit(body) }
  let audioFiles = [];
  for (const lm of newLandmarks) {
    if (lm.audioFile) {
      const p = {
        rename:
          (Math.random() * 64).toString(36).replace(".", "").replace(",", "") +
          ".mp3",
        file: lm.audioFile,
      };
      audioFiles.push(p);
      delete lm.audioFile;
      lm.audioRename = p.rename;
    }
    else if (lm.audioFile === null) {
      delete lm.audioFile;
    }
  }
  let zip = JSZip();
  for (const audioFile of audioFiles) {
    zip.file(audioFile.rename, audioFile.file);
  }
  zip
    .generateAsync({ type: "blob" })
    .then((blob) => {
      const url = `${APIEndpoint}/visualizations/${vis.id}/landmarkaudio`;
      let body = args || {};
      Object.assign(body, {
        landmarks: newLandmarks,
      });
      return fetch(url, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(body),
      })
        .then(async (response) => {
          if (response.ok) {
            const body = await response.json();
            const url = body.url;
            const fields = body.fields;
            const updateId = body.update_id;
            uploadFileToS3(
              vis.id,
              url,
              fields,
              blob,
              successCB,
              failureCB,
              (body) => {
                if (body.visualization.last_update_id == updateId) {
                  return "complete";
                }
                if (body.visualization.last_update_error == updateId) {
                  return "error";
                }
              },
              (body) => body.visualization.update_error
            );
          } else {
            const body = await response.json();
            failureCB(body);
          }
        })
        .catch((reason) => {
          failureCB(reason);
        });
    })
    .catch((err) => failureCB(err));
}

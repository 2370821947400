import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "react-bootstrap-typeahead/css/Typeahead.min.css"
import { BrowserRouter } from "react-router-dom";
import { APIGetUserInfo } from "./helper/APIFunctions";
import App from "./App";
{
  const userInfoSuccessCB = (body) => {
    startReactApp(body);
  };
  const userInfoFailureCB = (body) => {
    startReactApp(null);
  };
  window.addEventListener("DOMContentLoaded", (event) => {
    APIGetUserInfo(userInfoSuccessCB, userInfoFailureCB);
  });
}
const startReactApp = (userInfo) => {
  document.getElementById("loading-image-container").hidden = true;
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <App userInfo={userInfo} />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
  document.body.style.backgroundColor = "";
  document.body.style.display = "";
};
import { useState, useContext } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { APILogin } from "../helper/APIFunctions";
import { UserInfo, DisclaimerSigned } from "../helper/Context";
import * as Yup from "yup";
import { Formik } from "formik";

const loginSchema = Yup.object().shape({
  email: Yup.string().required("Required").email("Invalid Format"),
  password: Yup.string()
    .required("Required")
    .min(8, "Too short")
    .max(72, "Too long"),
});

const LoginForm = (props) => {
  const [callErrors, setCallErrors] = useState([]);
  const [sendingCall, setSendingCall] = useState(false);
  const { setUserInfo } = useContext(UserInfo);
  const { setDisclaimerSigned } = useContext(
    DisclaimerSigned
  );
  const successLoginCB = (body) => {
    setDisclaimerSigned(true);
    setUserInfo(body);
    setSendingCall(false);
    props.onSuccessCB();
  };
  const onFailureLoginCB = (body) => {
    if (!body.error) {
      setCallErrors(["There was a server error. Please try again later."]);
    } else {
      setCallErrors(body.error.map((element) => element.message));
    }
    setSendingCall(false);
  };
  return (
    <>
      {callErrors.map((error) => (
        <p className="text-danger text-center mt-2" key={error}>
          {error}
        </p>
      ))}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={loginSchema}
        onSubmit={(values, actions) => {
          setSendingCall(true);
          APILogin(
            values.email,
            values.password,
            successLoginCB,
            onFailureLoginCB
          ).then(actions.setSubmitting(false));
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <p className="text-danger text-center">{errors.global}</p>
            <Form.Group controlId="formik-email" className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Account Email"
                autoComplete="email"
                disabled={isSubmitting || sendingCall}
                isInvalid={touched.email && errors.email}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formik-password" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                autoComplete="current-password"
                value={values.password}
                disabled={isSubmitting || sendingCall}
                isInvalid={touched.password && errors.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
              <button onClick={props.showForgetPass} className="btn btn-link" type="button">
                Forgot Password?
              </button>
            </Form.Group>
            <Button
              type="submit"
              className="mt-2"
              disabled={isSubmitting || sendingCall}
            >
              {isSubmitting || sendingCall ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="login"
                  aria-hidden="true"
                />
              ) : (
                <>Login</>
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;

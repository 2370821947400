import { useState, useEffect } from "react";
import { Modal, Button, Spinner, Form, Row } from "react-bootstrap";
import {
  APIEditVisLandmarks,
  APIEditLandmarksWithFiles,
} from "../../helper/APIFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faArrowUp,
  faArrowDown,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

const AUDIOFILESIZEMB = 4;

const SubModal = (props) => {
  const lm = props.landmark || {};
  const [title, setTitle] = useState(lm.title || "");
  const [desc, setDesc] = useState(lm.description || "");
  const [markerPos, setMarkerPos] = useState(lm.position || null);
  const [cameraPos, setCameraPos] = useState(lm.cameraPos || null);
  const [duration, setDuration] = useState(lm.duration || 10);
  const [upVector, setUpVector] = useState(lm.upVector || [0, 1, 0]);
  const [durationRaw, setDurationRaw] = useState(
    (lm.duration || 10).toString()
  );
  const [durationError, setDurationError] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [removeAudio, setRemoveAudio] = useState(false);
  const [touched, setTouched] = useState(false);
  const onMsgListener = (event) => {
    const data = event.data;
    if (data.description == "NewLandmarkPosition") {
      if (!data.result) {
        props.resetLandmarks();
        props.showFcn();
      } else {
        let temp = {
          title: title,
          description: desc,
          position: Array.from(data.result.position),
        };
        if (!props.noCameraPos) {
          temp.duration = duration;
          temp.audio = data.result.audio;
          temp.audioDuration = data.result.audioDuration;
          temp.cameraPos = Array.from(data.result.cameraPos);
          temp.upVector = Array.from(data.result.upVector) || [0, 1, 0];
        }
        if (data.result.component){
          temp.component = data.result.component;
        }
        props.onFinalize(props.index, temp);
      }
      return;
    }
  };
  useEffect(() => {
    window.addEventListener("message", onMsgListener);
    return () => {
      window.removeEventListener("message", onMsgListener);
    };
  }, [title, desc]);
  useEffect(() => {
    if (/^\s*\d*(\.\d*)?\s*$/.test(durationRaw)) {
      const duration = parseFloat(durationRaw);
      if (!duration) {
        setDurationError("Duration must not be zero");
        return;
      }
      setDuration(duration);
      setDurationError(null);
      return;
    }
    setDurationError("Invalid Format");
  }, [durationRaw]);
  return (
    <Modal show={props.show} onHide={props.onCancel}>
      <Modal.Header closeButton closeVariant="white">
        Edit Landmark
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId={"landmark-title"} className="mb-3">
          <Row>
            <Form.Label className="col-sm-3">Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              placeholder="Landmark Title"
              onChange={(event) => {
                setTitle(event.target.value);
                setTouched(true);
              }}
              isInvalid={!title || !title.match(/\S/)}
            />
            <Form.Control.Feedback type="invalid">
              Title cannot be empty
            </Form.Control.Feedback>
          </Row>
        </Form.Group>
        <Form.Group controlId={"landmark-description"} className="mb-3">
          <Row>
            <Form.Label className="col-sm-3">Description</Form.Label>
            <Form.Control
              as="textarea"
              value={desc}
              placeholder="Optional Description for Landmark"
              onChange={(event) => {
                setDesc(event.target.value);
                setTouched(true);
              }}
            />
          </Row>
        </Form.Group>
        {props.canAudio ?
          <Form.Group controlId={"landmark-duration"} className="mb-3">
            <Row>
              <Form.Label>Tour Duration (sec)</Form.Label>
              <Form.Control
                value={durationRaw}
                onChange={(event) => {
                  setDurationRaw(event.target.value);
                  setTouched(true);
                }}
                isInvalid={durationError}
              />
              <Form.Control.Feedback type="invalid">
                {durationError}
              </Form.Control.Feedback>
            </Row>
          </Form.Group> : null}
        <Form.Group controlId={"landmark-pos"} className="mb-3">
          <Form.Label className="col-sm-6">
            Landmark & Camera Positions:{" "}
            <span
              className={
                markerPos && (cameraPos || props.noCameraPos) ? "text-success" : "text-danger"
              }
            >
              {markerPos && (cameraPos || props.noCameraPos) ? "Valid" : "Invalid"}
            </span>
          </Form.Label>
          <Button
            onClick={() => {
              props.hideFcn();
              document.getElementById(props.viewerId).contentWindow.postMessage(
                {
                  description: "CreateNewLandmark",
                  result: props.index >= 0 ? props.index + 1 : -1,
                },
                "https://vis.snorkle.io"
              );
            }}
            disabled={!title || !title.match(/\S/)}
          >
            Update Positions
          </Button>
        </Form.Group>
        {props.canAudio ? (
          <>
            {lm.audio ? (
              <Form.Group className="mb-3">
                <Form.Check
                  label="Remove Current Audio Track"
                  checked={removeAudio}
                  onChange={(e) => {
                    setRemoveAudio(e.target.checked);
                    setTouched(true);
                  }}
                />
              </Form.Group>
            ) : null}
            {!lm.audio || removeAudio ? (
              <Form.Group className="mb-3">
                <Form.Label className="col-auto">New Audio Track</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(event) => {
                    setAudioFile(
                      event.target.files.length ? event.target.files[0] : null
                    );
                    setTouched(true);
                  }}
                  isInvalid={
                    audioFile && audioFile.size > AUDIOFILESIZEMB * 1024 * 1024
                  }
                  accept="audio/mpeg"
                />
                <Form.Control.Feedback type="invalid">
                  Selected file is too large.
                </Form.Control.Feedback>
                <Form.Text>
                  Accepts a .mp3 file with a limit of {AUDIOFILESIZEMB}MB
                </Form.Text>
              </Form.Group>
            ) : null}
          </>
        ) : null}

        <Button
          onClick={() => {
            let t = {
              title: title,
              description: desc,
              position: markerPos,
            }
            if (!props.noCameraPos) {
              t.duration = duration;
              t.audioFile = audioFile;
              t.audio = removeAudio ? null : lm.audio;
              t.audioDuration = removeAudio ? null : lm.audioDuration;
              t.cameraPos = cameraPos;
              t.upVector = upVector;
            }
            props.onFinalize(props.index,t);
          }}
          disabled={
            !markerPos ||
            !(cameraPos || props.noCameraPos) ||
            !title ||
            !title.match(/\S/) ||
            !touched ||
            durationError ||
            (audioFile && audioFile.size > AUDIOFILESIZEMB * 1024 * 1024)
          }
        >
          Save Landmark Changes
        </Button>
      </Modal.Body>
    </Modal>
  );
};

const LandmarkModal = (props) => {
  const [statusTxt, setStatusTxt] = useState(null);
  const [isCallingAPI, setIsCallingAPI] = useState(false);
  const [currentLandmarks, setCurrentLandmarks] = useState([]);
  const [landmarksLoaded, setLandmarksLoaded] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [showSubModal, setShowSubModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [showCNS, setShowCNS] = useState(false);
  const [landmarkInterval, setLandmarkInterval] = useState(null);
  const [noCameraPos, setNoCameraPos] = useState(false);
  const [nocacheUrlSuffix, _] = useState(`?now=${Date.now()}`);
  const onMsgListener = (event) => {
    const data = event.data;
    if (data.description == "CurrentLandmarks") {
      setCurrentLandmarks(data.result);
      setNoCameraPos(data.noCameraPos || false);
      setLandmarksLoaded(true);
      clearTimeout(landmarkInterval);
      setLandmarkInterval(null);
      return;
    }
  };
  useEffect(() => {
    window.addEventListener("message", onMsgListener);
    return () => {
      window.removeEventListener("message", onMsgListener);
    };
  }, []);

  const viewerId = "landmark-viewer" + props.vis.id;
  useEffect(() => {
    document.getElementById(viewerId).onload = () => {
      const pollForLandmarks = () => {
        const element = document.getElementById(viewerId);
        if (element) {
          element.contentWindow.postMessage(
            "FetchLandmarks",
            "https://vis.snorkle.io"
          );
          setLandmarkInterval(setTimeout(pollForLandmarks, 250));
        }
      };
      setLandmarkInterval(setTimeout(pollForLandmarks, 250));
    };
  }, []);

  const updateLandmarks = (arr = null) => {
    if (isCallingAPI) {
      return;
    }
    setStatusTxt(null);
    const res = arr || currentLandmarks;
    document
      .getElementById(viewerId)
      .contentWindow.postMessage(
        { description: "UpdateLandmarks", result: res || [] },
        "https://vis.snorkle.io"
      );
    if (arr) {
      setIsDirty(true);
      setCurrentLandmarks(arr);
    }
  };

  return (
    <Modal
      show="true"
      onHide={() => {
        isDirty ? setShowCNS(true) : props.onHide();
      }}
      dialogClassName="modal-90pct"
      contentClassName="w-100 h-100"
      style={showSubModal || showCNS ? { zIndex: 100 } : null}
    >
      <Modal.Header closeButton closeVariant="white">
        Edit Model Landmarks
      </Modal.Header>
      <Modal.Body
        className="text-center d-flex flex-column"
        style={{ height: "100%" }}
      >
        <div
          style={{
            position: "absolute",
            left: "1rem",
            top: "1rem",
            bottom: "1rem",
            display: "flex",
            right: "1rem",
            flexDirection: "column",
          }}
        >
          {currentEditIndex !== null ? (
            <SubModal
              show={showSubModal}
              landmark={
                currentEditIndex != -1 ? currentLandmarks[currentEditIndex] : {}
              }
              noCameraPos={noCameraPos}
              index={currentEditIndex}
              onCancel={() => {
                updateLandmarks();
                setShowSubModal(false);
                setCurrentEditIndex(null);
              }}
              hideFcn={() => {
                setShowSubModal(false);
              }}
              showFcn={() => {
                setShowSubModal(true);
              }}
              onSave={() => { }}
              resetLandmarks={updateLandmarks}
              canAudio={props.vis.can_audio_landmark}
              viewerId={viewerId}
              onFinalize={(index, newLm) => {
                const arr = [...currentLandmarks];
                if (index == -1) {
                  arr.push(newLm);
                } else {
                  arr[index] = newLm;
                }
                updateLandmarks(arr);
                setShowSubModal(false);
                setCurrentEditIndex(null);
              }}
            />
          ) : null}
          {showCNS ? (
            <Modal show={true}>
              <Modal.Header>Discard Changes?</Modal.Header>
              <Modal.Body>
                <p>If you leave now, your changes will not be saved.</p>
                <Button
                  onClick={() => {
                    setShowCNS(false);
                  }}
                >
                  Go Back
                </Button>
                <Button onClick={props.onHide}>Discard Changes</Button>
              </Modal.Body>
            </Modal>
          ) : null}
          {statusTxt ? (
            <p
              className={
                "text-center " +
                (statusTxt.valid ? "text-success" : "text-danger")
              }
            >
              {statusTxt.msg}
            </p>
          ) : null}
          <div
            style={{
              flexGrow: 1,
              position: "relative",
              overflow: "hidden",
              width: "100%",
              minWidth: "300px",
              minHeight: "300px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <iframe
              src={props.vis.visualization_url + nocacheUrlSuffix}
              id={viewerId}
              className="border border-secondary rounded"
              style={{
                width: "75%",
                height: "100%",
              }}
            />
            <div
              style={{
                width: "25%",
                height: "100%",
              }}
            >
              <h5 className="mb-2">Landmarks:</h5>
              <div style={{ overflowY: "scroll", height: "calc(100% - 5rem)" }}>
                {currentLandmarks.map((t, ind) => {
                  return (
                    <div
                      className="border rounded border-secondary mb-3 mx-2"
                      style={{ position: "relative", height: "4rem" }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faPencil}
                        className="icon-btnlike me-2"
                        size="xl"
                        style={{
                          position: "absolute",
                          top: "0.1rem",
                          left: "0.1rem",
                        }}
                        aria-label="edit"
                        role="button"
                        tabIndex="0"
                        title={`Edit Landmark "${t.title}}"`}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setShowSubModal(true);
                          setCurrentEditIndex(ind);
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            event.stopPropagation();
                            setShowSubModal(true);
                            setCurrentEditIndex(ind);
                          }
                        }}
                      />
                      <div
                        style={{
                          maxWidth: "calc(100% - 5rem)",
                          overflowX: "hidden",
                          textOverflow: "ellipsis",
                          transform: "translate(0%, -50%)",
                          top: "50%",
                          left: "2.5rem",
                          position: "absolute",
                          wordBreak: "keep-all",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ind + 1}
                        {": "}
                        {t.title}
                      </div>
                      {ind != 0 ? (
                        <FontAwesomeIcon
                          icon={faArrowUp}
                          className="icon-btnlike me-2"
                          size="xl"
                          aria-label="edit"
                          role="button"
                          tabIndex="0"
                          title={`Move Up`}
                          style={{
                            position: "absolute",
                            top: "0rem",
                            right: "-0.4rem",
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            const arr = [...currentLandmarks];
                            [arr[ind - 1], arr[ind]] = [arr[ind], arr[ind - 1]];
                            updateLandmarks(arr);
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              event.stopPropagation();
                              const arr = [...currentLandmarks];
                              [arr[ind - 1], arr[ind]] = [
                                arr[ind],
                                arr[ind - 1],
                              ];
                              updateLandmarks(arr);
                            }
                          }}
                        />
                      ) : null}
                      {ind != currentLandmarks.length - 1 ? (
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="icon-btnlike me-2"
                          size="xl"
                          aria-label="edit"
                          role="button"
                          tabIndex="0"
                          title={`Move Down`}
                          style={{
                            position: "absolute",
                            bottom: "0rem",
                            right: "-0.4rem",
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            const arr = [...currentLandmarks];
                            [arr[ind + 1], arr[ind]] = [arr[ind], arr[ind + 1]];
                            updateLandmarks(arr);
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              event.stopPropagation();
                              const arr = [...currentLandmarks];
                              [arr[ind + 1], arr[ind]] = [
                                arr[ind],
                                arr[ind + 1],
                              ];
                              updateLandmarks(arr);
                            }
                          }}
                        />
                      ) : null}
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className="icon-btnlike me-2 text-danger"
                        size="xl"
                        aria-label="edit"
                        role="button"
                        tabIndex="0"
                        style={{
                          position: "absolute",
                          bottom: "-.1rem",
                          left: "-.1rem",
                        }}
                        title={`Delete Landmark "${t.title}"`}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          const arr = [...currentLandmarks];
                          arr.splice(ind, 1);
                          updateLandmarks(arr);
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            event.stopPropagation();
                            const arr = [...currentLandmarks];
                            arr.splice(ind, 1);
                            updateLandmarks(arr);
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <Button
                className="mt-2"
                onClick={() => {
                  setCurrentEditIndex(-1);
                  setShowSubModal(true);
                }}
                disabled={!landmarksLoaded}
              >
                New Landmark
              </Button>
            </div>
          </div>
          <Button
            onClick={() => {
              setIsCallingAPI(true);
              setStatusTxt(null);
              const APIFunc = currentLandmarks.some((l) => l.audioFile)
                ? APIEditLandmarksWithFiles
                : APIEditVisLandmarks;
              APIFunc(
                props.vis,
                currentLandmarks,
                () => {
                  setIsCallingAPI(false);
                  setStatusTxt({ valid: true, msg: "Landmarks Updated." });
                  setIsDirty(false);
                },
                (err) => {
                  setIsCallingAPI(false);
                  setStatusTxt({ valid: false, msg: err });
                },
                props.isOrg
              );
            }}
            className="mt-4"
            disabled={isCallingAPI || !isDirty}
          >
            {isCallingAPI ? (
              <Spinner
                animation="border"
                role="loading"
                className="text-secondary align-center"
                size="sm"
              />
            ) : (
              "Commit Changes"
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LandmarkModal;

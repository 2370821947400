import SearchForm from "../components/SearchForm";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { Image, Button, Row, Col, Spinner, Form, Modal } from "react-bootstrap";
import { UserInfo } from "../helper/Context";
import UploadResultDisplay from "../components/UploadResultDisplay";
import {
  APIWebVisualization,
  APIFileVisualization,
} from "../helper/APIFunctions";
import { APISearchVisuals } from "../helper/APIFunctions";
import { prepBrandProfileList } from "../helper/Utils";
import {
  pdbValidator,
  pubchemValidator,
} from "../helper/WebResourceValidators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage as imageIcon,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";

const getMaxNum = (type, extended) => {
  if (type == "snorkle") {
    return 15;
  } else {
    return extended ? 10 : type == "images" ? 0 : 5;
  }
};
const getButtonText = (status) => {
  switch (status) {
    case "complete":
      return <>Visual Added!</>
    case "error":
      return <>There was an error.</>
    default:
      return <>Add to My Visuals</>
  }
}

const VisItem = (props) => {
  const [uploadStatus, setUploadStatus] = useState({});
  const [isHover, setIsHover] = useState(false);
  const [expandText, setExpandText] = useState(false);
  return (
    <div
      className="border border-secondary m-2"
      style={{ position: "relative", width: "15vw", minWidth: "20rem", minHeight: "15rem" }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => {setIsHover(false); setExpandText(false)}}
      onFocus={() => setIsHover(true)}
      onBlur={() => {setIsHover(false);setExpandText(false)}}
      tabIndex={-1}
    >
      <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, textAlign: "center", textJustify: "center", zIndex: 10 }}
        className={uploadStatus && uploadStatus.state === "uploading" ? "blockout active" : "blockout"}>
        <h3 style={{ position: "absolute", opacity: 1., top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>
          Creating Visual
          <br />
          <Spinner
            animation="border"
            role="uploading"
            className="text-secondary"
          >
            <span className="visually-hidden">Uploading...</span>
          </Spinner></h3>
      </div>
      <div style={{ position: "absolute", backgroundColor: "rgba(0,0,0,0.75)", color: "#eaeaea", bottom: 0, left: 0, right: 0, textAlign: "center", zIndex: 1 }}>
        {props.title}
      </div>
      <img
        src={props.imgSrc || "https://static.thenounproject.com/png/2932881-200.png"}
        alt={props.title}
        style={{ position: "absolute", left: "0", right: "0", top: "0", bottom: "0", width: "100%", height: "100%", zIndex: 0, objectFit: "cover", filter: props.blurImage ? "blur(2px) hue-rotate(30deg) grayscale(20%)" : "initial" }}
      />
      {props.icon ? (<div style={{ position: "absolute", top: 0, left: 0, zIndex: 3, color: "#ffffff", backgroundColor: "rgba(0,0,0,0.66)", width: "2.828rem", height: "2.828rem", padding: "0.414rem", borderRadius: "2.828rem" }}>{
        props.icon == imageIcon ? (
          <FontAwesomeIcon
            icon={props.icon}
            title={props.iconTitle}
            size="2xl"
          />
        ) : (
          <Image
            src={props.icon}
            style={{
              width: "100%",
              maxWidth: "3em",
              filter: "invert(100%)",
            }}
            title={props.iconTitle}
          />
        )}</div>
      ) : null}
      <div className={isHover ? "blockout active" : "blockout"} style={{
        zIndex: "5 !importatnt", height: "100%", position: "relative", display: "flex", justifyContent: "space-between", flexDirection: "column"
      }}>
        <div><b>{props.title}</b></div>
        {props.description ? (
          <div style={{ overflowWrap: "anywhere" }} className={isHover && expandText ? "": "clamp-lines-search-result"} onClick={() => setExpandText(!expandText)}>
            {props.description}
          </div>
        ) : null}
        <div className="text-center" style={{ bottom: 0 }}>
          <Button onClick={() => {
            setUploadStatus({ state: "uploading" });
            props.addFcn(() => setUploadStatus({ state: "complete" }), () => { setUploadStatus({ state: "error" }) });
          }} disabled={uploadStatus && uploadStatus.state && uploadStatus.state != "error"}>
            {props.buttonText || getButtonText(uploadStatus.state || "")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const getPDBData = (ids, successCB, failureCB) => {
  const queryString = `{entries(entry_ids: ${JSON.stringify(
    ids
  )}) {rcsb_id struct{title}}}`;
  const url =
    "https://data.rcsb.org/graphql?query=" + encodeURIComponent(queryString);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const body = await response.json();
      if (response.ok) {
        successCB(
          body.data.entries.map((entry) => {
            return {
              id: entry.rcsb_id,
              title: entry.struct.title,
              description: `RCSB Protein Data Bank structure ${entry.rcsb_id}`,
              type: "pdb",
              rating: 1,
            };
          })
        );
      } else {
        failureCB(body);
      }
    })
    .catch((reason) => {
      failureCB(reason);
    });
};

const getPubchemInfo = (cids, successCB, failureCB) => {
  const descUrl = `https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/cid/${cids.join()}/description/json`;
  fetch(descUrl, {
    method: "GET",
  })
    .then(async (response) => {
      if (!response.ok) {
        failureCB("Unable to fetch results");
      }
      const body = await response.json();
      if (
        !body.InformationList ||
        !body.InformationList.Information ||
        !body.InformationList.Information.length
      ) {
        failureCB("No results");
        return;
      }
      let content = {};
      body.InformationList.Information.forEach((v) => {
        if (!content[v.CID]) {
          content[v.CID] = {
            id: v.CID,
            description: `PubChem compound ${v.CID}`,
            type: "pubchem",
            rating: 1,
          };
        }
        content[v.CID].title = v.Title || content[v.CID].title;
        content[v.CID].description = v.Description
          ? v.Description + "\n\n" + content[v.CID].description
          : content[v.CID].description;
      });
      successCB(cids.map((cid) => content[cid]));
    })
    .catch((e) => failureCB(e));
};

const SearchResults = (props) => {
  const [rawSources, setRawSources] = useState({ res: [], ready: false });
  const [sources, setSources] = useState({ res: [], ready: false });
  const [uploadCount, setUploadCount] = useState(0);
  const [uploadResult, setUploadResult] = useState([]);
  const [modalInfo, setModalInfo] = useState(null);
  const [blurb, setBlurb] = useState(null);
  const [showBlurb, setShowBlurb] = useState(true);
  const [showImages, setShowImages] = useState(false);
  const [extended, setExtended] = useState(false);
  const [forceExtended, setForceExtended] = useState(false);
  const [previewVisualInfo, setPreviewVisualInfo] = useState(null);

  useEffect(() => {
    if (!rawSources.ready) {
      setSources({ res: [], ready: false });
      return;
    }
    const fs = rawSources.res.every((s) => s.type == "images");
    const out_arr = [];
    const type_count = {};
    for (const item of rawSources.res) {
      if (
        getMaxNum(item.type, extended || fs) > (type_count[item.type] || 0) &&
        item.rating > 0.75
      ) {
        out_arr.push(item);
        type_count[item.type] = (type_count[item.type] || 0) + 1;
      }
    }
    setForceExtended(fs);
    setSources({ res: out_arr, ready: true });
  }, [rawSources, extended]);

  const webUploadSuccess = (body) => {
    setUploadResult(arr => [...arr, {
      success: true,
      payload: body,
    }]);
    setUploadCount(t => t - 1);
  };
  const webUploadFailure = (body) => {
    let err_msg = "";
    if (body.error) {
      err_msg = body.error;
    } else {
      err_msg = body.toString();
    }
    setUploadResult(arr => [...arr, {
      success: false,
      payload: err_msg,
    }]);
    setUploadCount(t => t - 1);
  };

  const getAddFcn = (item) => {
    const type = item.type;
    if (type == "pdb") {
      return (successCB, failureCB) => {
        setUploadCount(t => t + 1);
        APIWebVisualization(
          item.id,
          "pdb",
          item.title,
          item.description,
          (body) => { webUploadSuccess(body); successCB(body) },
          (body) => { webUploadFailure(body); failureCB(body) },
          false,
          { branding_profile: props.brandingProfile }
        );
      };
    } else if (type == "pubchem") {
      return (successCB, failureCB) => {
        setUploadCount(t => t + 1);
        APIWebVisualization(
          item.id,
          "pubchem",
          item.title,
          item.description,
          (body) => { webUploadSuccess(body); successCB(body) },
          (body) => { webUploadFailure(body); failureCB(body) },
          false,
          { branding_profile: props.brandingProfile }
        );
      };
    } else if (type == "astronomy") {
      return (successCB, failureCB) => {
        setUploadCount(t => t + 1);
        APIWebVisualization(
          item.layerName || "",
          "astro",
          item.title,
          item.description,
          (body) => { webUploadSuccess(body); successCB(body) },
          (body) => { webUploadFailure(body); failureCB(body) },
          false,
          {
            ra: item.ra.toString(),
            dec: item.dec.toString(),
            angularFov: item.angularFov.toString(),
            branding_profile: props.brandingProfile,
          }
        );
      };
    } else if (type == "models") {
      return (successCB, failureCB) => {
        setUploadCount(t => t + 1);
        APIWebVisualization(
          item.id,
          "sketchfab",
          item.title,
          item.description,
          (body) => { webUploadSuccess(body); successCB(body) },
          (body) => { webUploadFailure(body); failureCB(body) },
          false,
          { branding_profile: props.brandingProfile }
        );
      };
    } else if (type == "images") {
      return (successCB, failureCB) => {
        const disclaimer = `This image is courtesy of: ${item.srcUrl}`;
        setModalInfo({
          callback: () => {
            setUploadCount(t => t + 1);
            APIFileVisualization(
              item.url,
              item.title,
              item.description,
              (body) => { webUploadSuccess(body); successCB(body) },
              (body) => { webUploadFailure(body); failureCB(body) },
              false,
              {
                initialMessage: disclaimer,
                isSimple: true,
                branding_profile: props.brandingProfile,
              }
            );
          },
          disclaimer: disclaimer,
        });
      };
    } else if (type == "snorkle" || type == "snorkle-presentation") {
      return (successCB, errorCb) => {
        setPreviewVisualInfo({ url: item.visualization_url, cb: errorCb });
      }
    }
  };

  const getThumbnail = (item) => {
    const type = item.type;
    if (type == "pdb") {
      return `https://cdn.rcsb.org/images/structures/${item.id.toLowerCase()}_model-1.jpeg`;
    } else if (type == "pubchem") {
      return `https://pubchem.ncbi.nlm.nih.gov/image/img3d.cgi?cid=${item.id}&t=l`;
    } else if (type == "astronomy") {
      return item.image || "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Webb%27s_First_Deep_Field.jpg/587px-Webb%27s_First_Deep_Field.jpg";
    } else if (type == "models") {
      return item.thumbnail;
    } else if (type == "images") {
      return item.thumbnail;
    } else if (type == "snorkle" || type == "snorkle-presentation") {
      return item.thumbnail;
    }
  };

  const getIcon = (item) => {
    switch (item.type) {
      case "pdb":
        return "/portal/snorkle_vis_thumbnails/protein_vis_icon.png";
      case "pubchem":
        return "/portal/snorkle_vis_thumbnails/chem_vis_icon.png";
      case "astronomy":
        return "/portal/snorkle_vis_thumbnails/astro_vis_icon.png";
      case "models":
        return "/portal/snorkle_vis_thumbnails/3d_vis_icon.png";
      case "snorkle":
        return "/portal/snorkle_vis_thumbnails/snorkle_vis_icon.png";
      case "snorkle-presenataion":
        return "/portal/snorkle_vis_thumbnails/presentation_icon.jpg";
      case "images":
        return imageIcon;
    }
    return null;
  };

  const getIconTitle = (item) => {
    switch (item.type) {
      case "pdb":
        return "Protein Structure";
      case "pubchem":
        return "Chemical Compound";
      case "astronomy":
        return "Astronomical Viewer";
      case "models":
        return "3D Model";
      case "images":
        return "Flat Web Image";
      case "snorkle":
        return "Snorkle Visual";
      case "snorkle-presenataion":
        return "Snorkle Presentation";
    }
    return null;
  };

  const snorkle = (term) => {
    APISearchVisuals(
      term,
      (res) => {
        setRawSources({ res: res.sources || [], ready: true });
        setBlurb(res.blurb || null);
      },
      () => {
        setRawSources({ res: [], ready: true });
        setBlurb(null);
      }
    );
  };

  useEffect(() => {
    const term = props.searchTerm;
    setRawSources({ res: [], ready: false });
    setBlurb(null);
    if (term) {
      const pdbMatch = pdbValidator(term);
      const pubchemMatch = pubchemValidator(term);
      if (pdbMatch) {
        getPDBData(
          [pdbMatch.toUpperCase()],
          (res) => {
            setRawSources({ res: res, ready: true });
          },
          () => {
            setRawSources({ res: [], ready: true });
          }
        );
        setBlurb(null);
      } else if (pubchemMatch) {
        getPubchemInfo(
          [pubchemMatch],
          (res) => {
            setRawSources({ res: res, ready: true });
          },
          () => {
            setRawSources({ res: [], ready: true });
          }
        );
        setBlurb(null);
      } else {
        snorkle(term);
      }
    }
    setUploadResult([]);
  }, [props.searchTerm]);
  let arr = [sources];
  return (
    <>
      <div>
        {blurb && sources.ready && sources.res.length ? (
          <div
            className="border rounded border-secondary my-2"
            style={{
              backgroundColor: "gray",
              color: "white",
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Form.Group
              style={{ float: "right" }}
              className="text-center mb-3 ms-4"
            > */}
            {/* <Form.Label>Description</Form.Label>
              <div>
                Hide
                <Form.Check
                  type="switch"
                  inline
                  className="mx-2"
                  checked={showBlurb}
                  title="Toggle Description"
                  onChange={(event) => {
                    setShowBlurb(event.target.checked);
                  }}
                />
                Show
              </div> */}
            {/* </Form.Group> */}

            {showBlurb ? (
              <p className="ms-4" style={{ fontSize: "125%" }}>
                {blurb}
              </p>
            ) : null}
          </div>
        ) : null}
        <div style={{ clear: "both" }} />
      </div>
      {modalInfo ? (
        <Modal show={true}>
          <Modal.Body>
            <p>The following disclaimer will be added to your visual:</p>
            <p className="bg-light">{modalInfo.disclaimer}</p>
            <div>
              <Button
                className="bg-success"
                onClick={(event) => {
                  modalInfo.callback();
                  setModalInfo(null);
                }}
              >
                Continue
              </Button>
              <Button onClick={() => setModalInfo(null)}>Cancel</Button>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      {uploadResult && uploadResult.length && !uploadCount ? (
        <UploadResultDisplay result={uploadResult[uploadResult.length - 1]} isOrg={false} />
      ) : null}
      {uploadCount ? (
        <div className="text-center w-100">
          <h5>Creating {uploadCount > 1 ? `${uploadCount} Visuals` : "Visual"}</h5>
          <p>Please wait...</p>
          <Spinner
            animation="border"
            role="uploading"
            className="text-secondary"
          >
            <span className="visually-hidden">Uploading...</span>
          </Spinner>
        </div>
      ) : null}
      {sources.ready ? (
        sources.res.length ? (
          <>
            <div style={{ display: "flex", flexFlow: "row wrap" }}>
              {sources.res
                .filter((f) => f.rating > 0.75 || f.type == "snorkle")
                .map((item) => {
                  return <VisItem
                    title={item.title}
                    icon={getIcon(item)}
                    description={item.description}
                    addFcn={getAddFcn(item)}
                    key={item.id || item.url||item.visualization_url}
                    imgSrc={getThumbnail(item)}
                    iconTitle={getIconTitle(item)}
                    blurImage={item.type == "astronomy" && !item.image}
                    buttonText={item.type.startsWith("snorkle") ? "Preview" : null}
                  />
                })}
            </div>
            {!forceExtended ? (
              <div className="text-center mt-4">
                <Button onClick={() => setExtended(!extended)}>
                  {extended ? "Show Less" : "Show More"}
                </Button>
              </div>
            ) : null}
            {previewVisualInfo ? (
              <Modal
                show={!!previewVisualInfo}
                onHide={() => {
                  previewVisualInfo.cb();
                  setPreviewVisualInfo(null);
                }}
                dialogClassName="modal-90pct"
                contentClassName="w-100 h-100"
              >
                <Modal.Header closeButton closeVariant="white">
                  Visual Preview
                </Modal.Header>
                <Modal.Body
                  className="text-center d-flex flex-column"
                  style={{ height: "100%" }}
                >
                  <div
                    className="d-flex flex-column"
                    style={{
                      flexGrow: 1,
                      position: "relative",
                      overflow: "hidden",
                      width: "100%",
                      minWidth: "300px",
                      minHeight: "300px",
                    }}
                  >
                    <iframe
                      src={previewVisualInfo.url}
                      id={`preview-modal`}
                      className="border border-secondary rounded"
                      style={{
                        width: "100%",
                        height: "100%",
                        flexGrow: 1,
                      }}
                    />
                  </div>
                </Modal.Body>
              </Modal>
            ) : null}
          </>
        ) : (
          <h5 className="TitleText mt-3">
            No matching visuals found. Please try another search term.
          </h5>
        )
      ) : props.searchTerm ? (
        <div className="d-flex justify-content-center">
          <Spinner
            animation="border"
            role="loading"
            className="text-secondary"
            size="xl"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : null}
    </>
  );
};

const SearchPage = (props) => {
  let location = useLocation();
  const { userInfo } = useContext(UserInfo);
  const searchTerm = new URLSearchParams(location.search).get("q");
  return (
    <>
      <h2 className="TitleText mb-4">Search</h2>
      <h5 className="TitleText mb-1">
        Find interactive visuals and explainers for science and medicine
      </h5>
      <SearchForm searchTerm={searchTerm} />
      <div style={{ position: "relative" }}>
        <SearchResults
          searchTerm={searchTerm}
          brandingProfile={(() => {
            if (!userInfo) {
              return null;
            }
            if (!userInfo.branding_profiles) {
              return null;
            }
            const bp = userInfo.branding_profiles;
            const p = prepBrandProfileList(bp);
            return p && p.length ? p[0].id : null;
          })()}
        />
      </div>
      <Button
        className="scroll-up-btn"
        onClick={() => {
          window.scroll(0, 0);
          setTimeout(() => {
            var sel = document.querySelector(".scroll-up-btn");
            if (sel) {
              sel.blur();
            }
          }, 250);
        }}
      >
        <span>
          <FontAwesomeIcon icon={faArrowUp} size="lg" title="Scroll to Top" />
        </span>
      </Button>
    </>
  );
};
export default SearchPage;

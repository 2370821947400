const PrivacyPolicy = (props) => {
  return (
    <>

<div>
		
    <p>PRIVACY POLICY</p>
    
    
    
    <p>Last Revised: October 15th, 2019</p>
    
    
    
    <p class="has-small-font-size">This Privacy Policy
    explains how information about you is collected, used and disclosed by Snorkle,
    Inc. (“Snorkle”). This Privacy Policy applies to information we collect when
    you use our web or mobile applications (“Snorkle App”) or otherwise interact with us.</p>
    
    
    
    <p class="has-small-font-size">We may change this
    Privacy Policy from time-to-time. If we make changes, we will notify you by
    revising the date at the top of the policy and, in some cases, we may provide
    you with additional notice (such as sending you an email notification). We
    encourage you to review the Privacy Policy whenever you access the Snorkle App
    to stay informed about our information practices and the ways you can help
    protect your privacy.</p>
    
    
    
    <p class="has-small-font-size">If you have questions, comments, or
    concerns regarding our Privacy Policy or practices, please contact us at
    info@snorkle.io.</p>
    
    
    
    <p>COLLECTION OF
    INFORMATION</p>
    
    
    
    <p class="has-small-font-size">Information You
    Provide to Us</p>
    
    
    
    <p class="has-small-font-size">We collect
    information you provide directly to us. For example, we collect information
    when you create an account, fill out a form, or otherwise communicate with us.
    The types of information we may collect include your name, email address,
    location and other contact or identifying information you choose to provide.</p>
    
    
    
    <p class="has-small-font-size">Information We
    Collect Automatically When You Use the Snorkle App:</p>
    
    
    
    <ul><li>When
         you access or use the Snorkle App, we automatically collect information
         about you, including:</li><li>Location
         Information: We collect information about the location of your device each
         time you access or use the Snorkle App. For more details, please see “Your
         Choices” below.</li><li>Device
         Information: We collect information about the device you use to access the
         Snorkle App, including the operating system platform, hardware model and
         unique device identifiers.</li><li>Log
         Information: We log information about your use of the Snorkle App,
         including the type of browser you use, access times, pages viewed, your IP
         address and the page you visited before navigating to the Snorkle App.</li></ul>
    
    
    
    <p class="has-small-font-size">Information Collected
    by Cookies and Other Tracking Technologies: </p>
    
    
    
    <p class="has-small-font-size">We use various
    technologies to collect information, and this may include sending cookies to
    your computer or mobile device. Cookies are small data files stored on your
    hard drive or in device memory that help us to improve the Snorkle App and your
    experience. We may also collect information using web beacons (also known as
    “tracking pixels”). Web beacons are electronic images that may be used in the
    Snorkle App or emails and help deliver cookies, count visits, understand usage
    and campaign. For more information about cookies, and how to disable them,
    please see “Your Choices” below.</p>
    
    
    
    <p class="has-small-font-size">Information We
    Collect From Other Sources</p>
    
    
    
    <p class="has-small-font-size">We may also obtain
    information from other sources and combine that with information we collect
    through the Snorkle App. For example, we may collect information about you from
    third parties including, but not limited to, identity verification services and
    publicly available sources. </p>
    
    
    
    <p class="has-small-font-size">USE OF INFORMATION</p>
    
    
    
    <p class="has-small-font-size">We may use
    information about you for various purposes, including to:</p>
    
    
    
    <ul><li>Send
         you technical notices, updates, security alerts and support and
         administrative messages;</li><li>Provide
         and deliver services you request and send you related information,
         including confirmations and invoices;</li><li>Provide,
         maintain and improve the Snorkle App;</li><li>Respond
         to your comments, questions and requests and provide customer service;</li><li>Monitor
         and analyze trends, usage and activities in connection with the Snorkle
         App;&nbsp;</li><li>Personalize
         and improve the Snorkle App and provide advertisements, content or
         features that match user profiles or interests;</li><li>Link
         or combine with information we get from others to help understand your
         needs and provide you with better service;</li><li>Carry
         out any other purpose for which the information was collected;&nbsp;</li></ul>
    
    
    
    <p class="has-small-font-size">Snorkle is based in
    the United States, and the information we collect is governed by U.S. law. By
    accessing or using the Snorkle App or otherwise providing information to us,
    you consent to the processing and transfer of information in and to the U.S.
    and other countries.</p>
    
    
    
    <p class="has-small-font-size">SHARING INFORMATION</p>
    
    
    
    <p class="has-small-font-size">We may share
    information about you as follows or as otherwise described in this Privacy
    Policy:</p>
    
    
    
    <ul><li>With
         vendors, consultants and other service providers who need access to such
         information to carry out work on our behalf;&nbsp;</li><li>In
         response to a request for information if we believe disclosure is in
         accordance with any applicable law, regulation or legal process, or as
         otherwise required by any applicable law, rule or regulation;&nbsp;</li><li>If
         we believe your actions are inconsistent with the spirit or language of
         our user agreements or policies, or to protect the rights, property and
         safety of Snorkle or others;</li><li>In
         connection with, or during negotiations of, any merger, sale of Snorkle&#8217;
         assets, financing or acquisition of all or a portion of our business to
         another company; and</li><li>With
         your consent or at your direction, including if we notify you through the
         Snorkle App that the information you provide will be shared in a
         particular manner and you provide such information.&nbsp;</li><li>We
         may also share aggregated or anonymized information that does not directly
         identify you.</li><li>In
         addition to other information described in this policy, we may collect and
         share precise location information, through methods such as mobile &#8216;SDKs&#8217;.
         This location information may be used by itself, aggregated, or combined
         with mobile identifiers (such as identifiers for advertisers (IDFAs) and
         Android IDs), and shared with third parties, for purposes related to
         advertising, attribution (e.g., measuring ad performance) analytics and
         research. You can remove your consent to having your location data
         collected by changing the settings on your device. (Certain services may
         lose functionality as a result.)&nbsp;</li></ul>
    
    
    
    <p class="has-small-font-size">ANALYTICS SERVICES</p>
    
    
    
    <p class="has-small-font-size">We may allow others
    to provide analytics services in connection with the Snorkle App. These entities
    may use cookies, web beacons and other technologies to collect information
    about your use of the Snorkle App and other websites, including your IP
    address, web browser, pages viewed, time spent on pages, links clicked and
    conversion information. We and others may use this information to, among other
    things, analyze and track data, determine the popularity of certain content,
    personalize the user experience, and better understand your activity.</p>
    
    
    
    <p class="has-small-font-size">SECURITY</p>
    
    
    
    <p class="has-small-font-size">Snorkle takes
    reasonable measures to help protect information about you from loss, theft,
    misuse and unauthorized access, disclosure, alteration and destruction.</p>
    
    
    
    <p class="has-small-font-size">YOUR CHOICES</p>
    
    
    
    <p class="has-small-font-size">Account Information</p>
    
    
    
    <p class="has-small-font-size">You may update,
    correct or delete information about you at any time by emailing us at
    support@snorkle.io. If you wish to delete or deactivate your account, please email
    us at support@snorkle.io but note that we may retain certain information as
    required by law or for legitimate business purposes. We may also retain cached
    or archived copies of information about you for a certain period. </p>
    
    
    
    <p class="has-small-font-size">Location Information</p>
    
    
    
    <p class="has-small-font-size">You may stop our
    collection of location information by following the standard uninstall process
    to remove the Snorkle App from your device.</p>
    
    
    
    <p class="has-small-font-size">Cookies</p>
    
    
    
    <p class="has-small-font-size">Most web browsers are
    set to accept cookies by default. If you prefer, you can usually choose to set
    your browser to remove or reject browser cookies.</p>
    
    
    
    <p class="has-small-font-size">Promotional
    Communications</p>
    
    
    
    <p class="has-small-font-size">You may opt out of
    receiving promotional communications from Snorkle by following the instructions
    in those communications or by changing your notification settings in the
    application’s settings menu. If you opt out, we may still send you
    non-promotional communications, such as those about your account or our ongoing
    business relations.</p>
    
    
    
    <p class="has-small-font-size">Your California Privacy
    Rights</p>
    
    
    
    <p class="has-small-font-size">California law
    permits residents of California to request certain details about how their
    information is shared with third parties for direct marketing purposes. If you
    are a California resident and would like to make such a request, please contact
    us at info@snorkle.io. However, please note that under the law, services such
    as the Snorkle App that permit California residents to opt into or opt out of
    this type of sharing are not required to provide such information upon
    receiving a request, but rather may respond by notifying the user of his or her
    right to prevent the disclosure. To opt out of having information about you
    shared with third parties for direct marketing purposes, please email us at
    info@snorkle.io.</p>
    
    
    
    <p class="has-small-font-size">CONTACT US</p>
    
    
    
    <p class="has-small-font-size">If you have any questions
    about this Privacy Policy, please contact us at: info@snorkle.io. </p>
      </div>


    </>
  );
};

export default PrivacyPolicy;

import { useState, useContext } from "react";
import { Form, Button, Spinner, Row, Col } from "react-bootstrap";
import { UserInfo } from "../helper/Context.js";
import * as Yup from "yup";
import { Formik } from "formik";

import { APIContact } from "../helper/APIFunctions.js";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Required").email("Invalid Format"),
  comment: Yup.string().required("Required"),
  header: Yup.string().required("Required"),
});

const ContactPage = (props) => {
  const { userInfo } = useContext(UserInfo);
  const [status, setStatus] = useState({});
  return (
    <>
      <h2 className="TitleText mb-4">Contact Us</h2>
      {status.error ? <p className="text-danger">{status.error}</p> : null}
      {status.success ? (
        <p>
<center>       <h4> Thanks for contacting us! We look forward to helping you out. We typically respond within 24 hours.</h4></center>  
        </p>
      ) : (
        <Formik
          initialValues={{
            email: (userInfo || {}).email || "",
            comment: "",
            header: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            APIContact(
              values,
              () => {
                actions.setSubmitting(false);
                setStatus({ success: true });
              },
              (err) => {
                actions.setSubmitting(false);
                setStatus({ error: err });
              }
            );
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
          }) => {
            return (
              <Form noValidate onSubmit={handleSubmit}>
                <h4>How can we help? We'd love to hear from you.</h4>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group className="my-3">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        name="header"
                        value={values.header}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        isInvalid={touched.header && errors.header}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.header}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={"my-3"}>
                      <Form.Label>Your contact email</Form.Label>
                      <Form.Control
                        placeholder="Your email."
                        type="email"
                        name="email"
                        value={values.email}
                        autoComplete="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        isInvalid={touched.email && errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="my-3">
                      <Form.Label>Comment Body</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="comment"
                        value={values.comment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        isInvalid={touched.comment && errors.comment}
                        rows={4}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.comment}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  type="submit"
                  className="mt-2"
                  disabled={isSubmitting || status.success}
                >
                  {isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="login"
                      aria-hidden="true"
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default ContactPage;

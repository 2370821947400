import { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { APIEditDefaultView } from "../../helper/APIFunctions";

const InitialViewModal = (props) => {
  const [statusTxt, setStatusTxt] = useState(null);
  const [isCallingAPI, setIsCallingAPI] = useState(false);
  const [nocacheUrlSuffix, _] = useState(`?now=${Date.now()}`)
  const onMsgListener = (event) => {
    const data = event.data;
    if (data.description !== "CurrentView") {
      return;
    }
    if (!data.result) {
      setStatusTxt({
        valid: false,
        msg: "Invalid View. Please make sure the visual is fully loaded.",
      });
      return;
    }
    setIsCallingAPI(true);
    APIEditDefaultView(
      props.vis,
      JSON.stringify(data.result),
      () => {
        setStatusTxt({
          valid: true,
          msg: "Default View Updated.",
        });
        setIsCallingAPI(false);
      },
      (err) => {
        setStatusTxt({
          valid: false,
          msg: err,
        });
        setIsCallingAPI(false);
      },
      props.isOrg
    );
  };
  useEffect(() => {
    window.addEventListener("message", onMsgListener);
    return () => {
      window.removeEventListener("message", onMsgListener);
    };
  }, []);

  const viewerId = "new-view-viewer" + props.vis.id;
  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById(viewerId)
        .contentWindow.postMessage("ForceViewerOptions", "*");
    }, 100);
  }, []);

  return (
    <Modal
      show="true"
      onHide={props.onClose}
      dialogClassName="modal-90pct"
      contentClassName="w-100 h-100"
    >
      <Modal.Header closeButton closeVariant="white">Edit Default Visual View</Modal.Header>
      <Modal.Body
        className="text-center d-flex flex-column"
        style={{ height: "100%" }}
      >
        {statusTxt ? (
          <p
            className={
              "text-center " +
              (statusTxt.valid ? "text-success" : "text-danger")
            }
          >
            {statusTxt.msg}
          </p>
        ) : null}
        <div
          style={{
            flexGrow: 1,
            position: "relative",
            overflow: "hidden",
            width: "100%",
            minWidth: "300px",
            minHeight: "300px",
          }}
        >
          <iframe
            src={props.url+nocacheUrlSuffix}
            id={viewerId}
            className="border border-secondary rounded"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <Button
          onClick={() => {
            document
              .getElementById(viewerId)
              .contentWindow.postMessage("GetCurrentView", "*");
          }}
          className="mt-4"
          disabled={isCallingAPI}
        >
          {isCallingAPI ? (
            <Spinner
              animation="border"
              role="loading"
              className="text-secondary align-center"
              size="sm"
            />
          ) : (
            "Save Current View"
          )}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default InitialViewModal;

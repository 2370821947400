import { useState } from "react";
import { UserInfo } from "../helper/Context";
import { Spinner, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import LoginForm from "../components/LoginForm";
import { APIConfirmOrgJoin } from "../helper/APIFunctions";
import {shallowCopy} from "../helper/Utils";

const OrganizationJoinPage = (props) => {
  const [showForgetPass, setShowForgetPass] = useState(false);
  const [requestState, setRequestState] = useState({
    inFlight: false,
  });
  const { encodedName, joinToken } = useParams();
  const decodeFunc = (encodedName) => {
    try {
      const decoder = new TextDecoder("utf-8");
      return decoder.decode(
        Uint8Array.from(atob(encodedName.replace(/_/g, '/').replace(/-/g, '+')), (c) => c.charCodeAt(0))
      );  
    } catch (error) {
      return "an Organization"
    }
  };
  const decodedName = decodeFunc(encodedName);

  return (
    <UserInfo.Consumer>
      {({ userInfo, setUserInfo }) =>
        userInfo ? (
          userInfo.organization_permissions && !requestState.success ? (
            <div className="text-center">
              This account is already part of an organization.
            </div>
          ) : (
            <>
              <h3 className="TitleText">You have been invited to join {decodedName}</h3>
              {requestState.error ? (
                <div className="text-center text-danger mb-2">
                  {requestState.error}
                </div>
              ) : null}
              {requestState.success ? (
                <div className="text-center text-success mb-2">
                  {requestState.success}
                </div>
              ) : (
                <div className="text-center mt-4">
                  <Button
                    onClick={() => {
                      APIConfirmOrgJoin(
                        joinToken,
                        (body) => {
                          let temp = shallowCopy(userInfo);
                          temp.organization_permissions = body.permissions;
                          setRequestState({
                            inFlight: false,
                            success:
                              "You have successfully joined the organization.",
                          });
                          setUserInfo(temp);
                        },
                        (err) => {
                          setRequestState({
                            inFlight: false,
                            error: err.error || err || "Internal Server Error",
                          });
                        }
                      );
                      setRequestState({ inFlight: true, error: null });
                    }}
                    disabled={requestState.inFlight}
                  >
                    {requestState.inFlight ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="Adding Member"
                        aria-hidden="true"
                      />
                    ) : (
                      "Join " + decodedName
                    )}
                  </Button>
                </div>
              )}
            </>
          )
        ) : (
          <>
            <div className="text-center">
              You must be logged in to accept this invitation.
            </div>
            {!showForgetPass ? (
              <LoginForm
                showForgetPass={() => setShowForgetPass(true)}
                onSuccessCB={() => {}}
              />
            ) : (
              <ForgotPasswordForm
                closeForgetPass={() => setShowForgetPass(false)}
              />
            )}
          </>
        )
      }
    </UserInfo.Consumer>
  );
};

export default OrganizationJoinPage;

import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { APIDeleteVisualizations } from "../../helper/APIFunctions";

const DeleteModal = (props) => {
  const [isCallingAPI, setIsCallingAPI] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [askConfirm, setAskConfirm] = useState(!!props.vis.favorite);
  const [modalBody, setModalBody] = useState(
    `Are you sure you want to delete "${props.vis.title}"?`
  );
  return (
    <Modal show={props.show}>
      <Modal.Body>
        <p className="text-danger text-center">{deleteError}</p>
        <p>{modalBody}</p>
        <Button
          onClick={(event) => {
            if (askConfirm) {
              setModalBody("This visual is a favorite visual. Are you really sure you want to delete it?")
              setAskConfirm(false);
            } else {
              setIsCallingAPI(true);
              setDeleteError(null);
              APIDeleteVisualizations(
                props.vis,
                (res) => {
                  setIsCallingAPI(false);
                  props.onVisDelete(props.vis);
                },
                (err) => {
                  setDeleteError(err.message || err.error ||  err);
                  setIsCallingAPI(false);
                },
                props.isOrg
              );
            }
          }}
          disabled={isCallingAPI}
        >
          {isCallingAPI ? (
            <Spinner
              animation="border"
              role="loading"
              className="text-secondary align-center"
              size="sm"
            />
          ) : (
            <>Confirm</>
          )}
        </Button>
        <Button onClick={()=>{props.onCancel(); setAskConfirm(props.vis.favorite); setModalBody(`Are you sure you want to delete "${props.vis.title}"?`)}} disabled={isCallingAPI}>
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;

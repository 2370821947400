import {useState} from 'react';
import {useParams} from 'react-router-dom';
import { Form, Button, Spinner } from "react-bootstrap";
import { APIResetPassword } from "../helper/APIFunctions";
import * as Yup from "yup";
import { Formik } from "formik";

const PasswordResetPage = (props) => {
    const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
        .required("Required")
        .min(8, "Password must be at least 8 characters")
        .max(72, "Password cannot be have more than 72 characters"),
      newPasswordConfirm: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    });      

    const [result, setResult] = useState(null);
    const params = useParams();

    return(
        <>
        <h3 className="TitleText">Reset Password</h3>
        {result ? <p className={result.style}>{result.message}</p> : null}
      <Formik
        initialValues={{
          newPassword: "",
          newPasswordConfirm: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setResult(null);
          APIResetPassword(
            values.newPassword,
            values.newPasswordConfirm,
            (body) => {
              setResult({
                style: "text-success text-center",
                message: "Success!",
              });
              actions.setSubmitting(false)
            },
            (body) => {
              setResult({
                style: "text-danger text-center",
                message: body.toString(),
              });
              actions.setSubmitting(false);
            },
            params.token,
            null
          );
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="change-password-new" className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                value={values.newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                isInvalid={touched.newPassword && errors.newPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.newPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              controlId="change-password-new-confirm"
              className="mb-3"
            >
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                name="newPasswordConfirm"
                value={values.newPasswordConfirm}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                isInvalid={
                  touched.newPasswordConfirm && errors.newPasswordConfirm
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.newPasswordConfirm}
              </Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="resetting password"
                  aria-hidden="true"
                />
              ) : (
                <>Reset Password</>
              )}
            </Button>
          </Form>
        )}
      </Formik>

        </>
    )
}

export default PasswordResetPage;
export function canSetPersonalBranding(tier) {
  return tier === "premium" || tier === "unlimited";
}

export function getVisualLifetimeHrs(tier, isOrg) {
  tier = isOrg ? "unlimited" : tier;
  switch (tier) {
    case "unlimited":
      return null;
    case "premium":
      return null;
    case "free":
      return 10 * 24;
    case "guest":
    default:
      return 48;
  }
}

export function getVisualLimit(tier, isOrg) {
  tier = isOrg ? "unlimited" : tier;
  switch (tier) {
    case "unlimited":
      return null;
    case "premium":
      return 50;
    case "free":
    default:
      return 10;
  }
}

export function getMaxUploadSizeMB(tier, isOrg) {
  tier = isOrg ? "unlimited" : tier;
  switch (tier) {
    case "unlimited":
      return 250;
    case "premium":
      return 100;
    case "free":
    default:
      return 50;
  }
}

export function getMaxUploadSizeBytes(tier, isOrg) {
  return 1024 * 1024 * getMaxUploadSizeMB(tier, isOrg);
}

export function getMonthlyPriceUSD(tier) {
  switch (tier) {
    case "unlimited":
      return 30;
    case "premium":
      return 10;
    case "free":
    default:
      return 0;
  }
}

export function getAnnualPriceUSD(tier) {
  switch (tier) {
    case "unlimited":
      return 252;
    case "premium":
      return 84;
    case "free":
    default:
      return 0;
  }
}

export function canDownloadVisuals(tier) {
  return tier === "premium" || tier === "unlimited";
}

export function canRestoreVisuals(tier) {
  return tier === "premium" || tier === "unlimited";
}

export function getRestoreVisualWaitHrs(tier) {
  return null;
}

export function canSortVisuals(tier) {
  return true;
}

export function getPresentationLimit(tier, isOrg) {
  tier = isOrg ? "unlimited" : tier;
  switch (tier) {
    case "unlimited":
      return null;
    case "premium":
      return 1;
    case "free":
    default:
      return 0;
  }
}

export function getUseTypes() {
  return [
    { slug: "student", name: "Student" },
    { slug: "dental", name: "Health Care Provider: Dental" },
    { slug: "patient-outreach", name: "Health Care Provider: General" },
    
    { slug: "researcher", name: "Scientist / Researcher" },
    { slug: "educator", name: "Educator" },
    { slug: "other", name: "Unspecified/Other" },
  ];
}

export function getDefaultPage(userInfo) {
  switch ((userInfo || {}).use_type) {
    case "dental":
      return "/dashboard/myvisuals?view=premium";
    case "patient-outreach":
      return "/dashboard/myvisuals";
    case "student":
    case "educator":
    case "researcher":
      return "/search";
    default:
      return "/home";
  }
}

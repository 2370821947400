import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { AvailableTags } from "../helper/Context";
const SearchForm = (props) => {
  const [searchTerm, setSearchTerm] = useState(props.searchTerm || "");
  const { availableTags, setAvailableTags } = useContext(AvailableTags);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentSuggestion, setCurrentSuggestion] = useState(null);
  useEffect(() => {
    if (
      !availableTags ||
      !availableTags.length ||
      !searchTerm ||
      searchTerm.length < 3
    ) {
      setSuggestions([]);
    } else {
      setSuggestions(
        availableTags
          .filter((tag) =>
            tag.title.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .sort((a, b) => a.title.length - b.title.length)
          .map((tag) => tag.title)
      );
    }
  }, [searchTerm, availableTags]);
  useEffect(() => {
    if (
      currentSuggestion !== null &&
      suggestions &&
      suggestions.length &&
      showSuggestions
    ) {
      const e = document.getElementById(
        `suggestion-autofill-entry-${currentSuggestion}`
      );
      if (e && suggestions.length > 3) {
        e.scrollIntoView({ block: "nearest", inline: "nearest" });
      }
    }
  }, [currentSuggestion]);
  let navigate = useNavigate();
  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        navigate("/search?q=" + encodeURIComponent(searchTerm));
      }}
    >
      <Form.Group className="mb-3">
        <Row className="justify-content-center align-items-center">
          <Col
            xs={9}
            sm={6}
            className="position-relative"
            onBlur={() =>
              setTimeout(() => {
                setShowSuggestions(false);
                setCurrentSuggestion(null);
              }, 110)
            }
            onKeyDown={(event) => {
              if (showSuggestions && suggestions && suggestions.length) {
                if (event.key === "ArrowDown") {
                  setCurrentSuggestion(
                    (currentSuggestion + 1) % suggestions.length
                  );
                } else if (event.key === "ArrowUp") {
                  let t = (currentSuggestion - 1) % suggestions.length;
                  t = t < 0 ? suggestions.length + t : t;
                  setCurrentSuggestion(t);
                } else if (event.key === "Enter" && currentSuggestion) {
                  event.preventDefault();
                  setSearchTerm(suggestions[currentSuggestion]);
                  setCurrentSuggestion(null);
                }
              }
            }}
          >
            <div className="field">
              <Form.Control
                type="text"
                value={searchTerm}
                placeholder="e.g. Jupiter, influenza, glucose..."
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                onFocus={() => setShowSuggestions(true)}
              />
              <div className="line" />
            </div>
            {showSuggestions && suggestions && suggestions.length ? (
              <div className="search-autofill-container">
                {suggestions.map((suggestion, i) => {
                  return (
                    <div
                      className={
                        "search-autofill-entry" +
                        (i === currentSuggestion ? " active" : "")
                      }
                      tabIndex="-1"
                      onClick={() => setSearchTerm(suggestion)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          setSearchTerm(suggestion);
                        }
                      }}
                      id={`suggestion-autofill-entry-${i}`}
                    >
                      {suggestion}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </Col>
          <Col xs="auto">
            <Button type="submit">Search (beta)</Button>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
};

export default SearchForm;

import { Row, Col } from "react-bootstrap";
import FeaturedViewers from "../components/FeaturedViewers";
import { Link } from "react-router-dom";
const AboutPage = (props) => {
  return (
    <>

      <Row className="justify-content-center">
        {/* <Col xs={9} sm={6} md={5} lg={4} className="align-contents-center mb-4">
          <h4 className="text-center mb-2">Featured Visualizations</h4>
          <FeaturedViewers className="w-100" />
        </Col> */}
        <div className="main-container-narrower">
          <h2 className="TitleText mb-4">Theater mode, by default.</h2>
          <p>Have the perfect pathology image that you wish you could embed into your presentation at full resolution? Want to interactively find and show your team the binding site on a drug target? Trying to describe what a torn meniscus is to a patient who doesn't know what a meniscus looks like? Want to take your class on a journey to Mars? If you can envision it, Snorkle can help you show it. (<a href="#supported_types">see supported visual types</a>) Snorkle also offers a <Link to="/powerpoint">free add-in</Link> for Microsoft PowerPoint.
          </p>

          <center>
          <h7>SARS-CoV-2 macrodomain in complex with remdesivir metabolite (interactive visual, rotate / zoom)</h7>
            <iframe width="100%" height="300" src="https://vis.snorkle.io/e9-6yJ6kTLbpoglQN7hcCLO_Sa38mEeP_ymNagtUCUI=.html" frameborder="0" allowfullscreen> </iframe>

          {/* <h2 className="TitleText mb-4">Theater mode, by default.</h2>
          <p>Have the perfect pathology image that you wish you could embed into your presentation at full resolution? Want to interactively find and show your team the binding site on a drug target? Trying to describe what a torn meniscus is to a patient who doesn't know what a meniscus looks like? Want to take your class on a journey to Mars? If you can envision it, Snorkle can help you show it.
          </p>

          <h2 className="TitleText mb-4">Theater mode, by default.</h2>
          <p>Have the perfect pathology image that you wish you could embed into your presentation at full resolution? Want to interactively find and show your team the binding site on a drug target? Trying to describe what a torn meniscus is to a patient who doesn't know what a meniscus looks like? Want to take your class on a journey to Mars? If you can envision it, Snorkle can help you show it.
          </p> */}
          <h7>Diffusion tensor imaging of the brain (interactive visual, rotate / zoom)</h7>
<iframe width="100%" height="300" src="https://vis.snorkle.io/HVVb_RNG12xbOYzCesGHVsL1NMsbl-ztMlrq0ZcPZ5k=.html" frameborder="0" allowfullscreen> </iframe>

{/* <h7>Pathology of the vagus nerve (interactive visual, zoom)</h7>

<iframe width="100%" height="300" src="https://www.snorkle.io/imageviewer/?name=https%3A//snorkle-output-files.s3.amazonaws.com//P824_LRL2_01_output/P824_LRL2_01_files/&width=5284&height=5925" frameborder="0" allowfullscreen> </iframe> */}

{/* <h7>Model depicting trajectories of satellites (interactive visual, rotate / zoom)</h7>
<iframe width="100%" height="300" src="https://vis.snorkle.io/tEbxtnDAGnI7Gy-WK7K8x24Pp7ApaEO_fmKx6b4YtOo=.html" frameborder="0" allowfullscreen> </iframe> */}

{/* <h7>"Stephan's Quintet" image from the Webb Telescope (interactive visual, zoom)</h7>
<iframe width="100%" height="300" src="https://www.snorkle.io/imageviewer/?name=https%3A//snorkle-output-files.s3.amazonaws.com//bRB56tGLvYHBM6wvG9meTf2TBm21FRnUu4ykbnVsnHg%3D_output/StephansQuintet_Webb2022_files/&width=10000&height=9587" frameborder="0" allowfullscreen> </iframe>

 */}




                    </center>
 <p></p>
          <h2 className="TitleText mb-4">Let your attendees take control</h2>
          <p>For each visual created, Snorkle automatically generates QR codes that link to mobile-friendly, interactive visuals that give attendees the same amount of control and data inspection capabilities as the presenter.
          </p>
          <center>
          {/* <img src="https://snorkle-output-files.s3.amazonaws.com/NkBVzrYG1BqffvdblNcva6MnmvWeHm77A6kZepEPZ84%3D_qr.jpg" width="150"></img> */}
          {/* <img src="https://snorkle-output-files.s3.amazonaws.com/StephansQuintet_Webb2022_qr.jpg" width="250"></img> */}
          <img src="https://snorkle-output-files.s3.amazonaws.com/LeZkRsr0eTW2Hwed8r62u8v1tL4Lbufp-zkhs59smpE%3D_qr.jpg" width="150"></img>
          
          </center>
          <p></p>
          




          <p></p>
          {/* <h2 className="TitleText mb-4">Integrate into PowerPoint</h2>
          <p>Using the free Snorkle Viewer add-in for Microsoft PowerPoint, you can embed Snorkle visuals and mobile-activating QR codes directly into your presentations. Because data items are loaded from the cloud, the actual files remain small even if you have massive amounts of data.
          </p>
<center>

          <img src="https://snorkle-output-files.s3.amazonaws.com/snorkle-ppt-addin-downloadscreen.png" width="500"></img>
          <p></p>
          <img src="https://snorkle-output-files.s3.amazonaws.com/snorkle-ppt-anatomy-slide.png" width="500"></img>
          <p></p>
          <img src="https://snorkle-output-files.s3.amazonaws.com/snorkle-digital-pathology-ppt.png" width="500"></img>
          <p></p>

          </center> */}
          
          {/* <Col className="align-contents-center mb-4">
            <h4 className="text-center mb-2">Featured Visuals</h4>
            <FeaturedViewers />
          </Col> */}
<div>
          <a id="supported_types"> <h2 className="TitleText mb-4">Supported file, data, and web resource types</h2></a>
                    <p></p>
                    - DICOM or TIFF image stacks (e.g. radiological scans or 3D microscopy data) <br></br>
                    - Extremely high resolution images such as pathology slides or astronomical sky surveys <br></br>
                    - Protein molecular structures, from the <a href="https://www.rcsb.org/" target="_blank">Protein Data Bank (PDB)</a><br></br>
                    - 3D models from <a href="https://www.sketchfab.com" target="_blank"> Sketchfab</a>. <p></p>
                    </div>

        </div>

      </Row>
    </>
  );
};

export default AboutPage;

import PrivacyPolicy from "../components/PrivacyPolicy";
import { Container, Row } from "react-bootstrap";

const PrivacyPage = (props) => {
  return (
    <>
      <h2 className="TitleText">Privacy Policy</h2>
      <Container>
        <Row>
          <PrivacyPolicy />
        </Row>
      </Container>
    </>
  );
};

export default PrivacyPage;

import React, { useState, useContext } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import {ActiveTour} from "../helper/Context";
import IntroTour from "../tours/IntroductoryTour";

const LoginModal = (props) => {
  const [showForgetPass, setShowForgetPass] = useState(false);
  const {setActiveTour} = useContext(ActiveTour);
  let navigate = useNavigate();
  const ohcb = () => {
    setShowForgetPass(false);
    props.onClose();
  };
  return (
    <>
      <Modal show={props.isOpen} onHide={ohcb} onExited={props.onClosed}>
        <Modal.Header closeButton closeVariant="white">
          <h3>{showForgetPass ? "Reset Password" : "Login / Register"}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className={showForgetPass ? "d-none" : "mb-4"}>
            <Tabs defaultActiveKey={props.defaultTab || "login"}>
              <Tab eventKey="login" title="Login">
                <LoginForm
                  onSuccessCB={() => {
                    ohcb();
                    navigate("/home");
                  }}
                  showForgetPass={() => setShowForgetPass(true)}
                />
              </Tab>
              <Tab eventKey="register" title="Register">
                <RegisterForm
                  onSuccessCB={() => {
                    ohcb();
                    navigate("/home");
                    setActiveTour(IntroTour);
                  }}
                />
              </Tab>
            </Tabs>
          </div>
          <div className={showForgetPass ? "mb-4" : "d-none"}>
            <ForgotPasswordForm
              closeForgetPass={() => setShowForgetPass(false)}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginModal;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Image, Spinner, Button, Form } from "react-bootstrap";
import { useState } from "react";
import { faXTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faCode, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { Formik } from "formik";
import { APIShareEmail } from "../../helper/APIFunctions.js";
const emailShareSchema = Yup.object().shape({
  email: Yup.string().required("Required").email("Invalid Format"),
});

const ShareModal = (props) => {
  const [showState, setShowState] = useState({});
  const [shareEmailErr, setShareEmailErr] = useState("");
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton className="h3" closeVariant="white">
        Share This {!props.isPresentation ? "Visual" : "Presentation"}
      </Modal.Header>
      <Modal.Body className="text-center">
        <a
          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
            `Check out this ${
              !props.isPresentation ? "visual" : "presentation"
            } from snorkle.io`
          )}&url=${encodeURIComponent(props.url)}`}
          className="mx-2 link-dark"
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faXTwitter}
            size="2x"
            title="Share to X/Twitter"
          />
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            props.url
          )}&src=sdkpreparse`}
          className="mx-2 link-dark"
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faFacebook}
            size="2x"
            title="Share to Facebook"
          />
        </a>
        {props.canEmail ? (
          <FontAwesomeIcon
            icon={faEnvelope}
            className="mx-2"
            size="2x"
            tabIndex="0"
            onClick={() => {
              setShowState({ email: !showState.email });
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setShowState({ email: !showState.email });
              }
            }}
            title="Embed in your website"
          />
        ) : null}

        <FontAwesomeIcon
          icon={faCode}
          className="mx-2"
          size="2x"
          tabIndex="0"
          onClick={() => {
            setShowState({ embedCode: !showState.embedCode });
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setShowState({ embedCode: !showState.embedCode });
            }
          }}
          title="Embed in your website"
        />
        <button
          onClick={() => {
            setShowState({ qr: !showState.qr });
          }}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: "rgba(0,0,0,0)",
          }}
          className="mx-2"
        >
          <Image
            height="1em"
            src="/portal/snorkle_vis_thumbnails/qr_icon.png"
            style={{ imageRendering: "pixelated", height: "1em" }}
            className="svg-inline--fa fa-2x"
            title="Share QR Code"
          />
        </button>

        {showState.embedCode ? (
          <div className="w-100">
            Copy this into your web page to include this{" "}
            {!props.isPresentation ? "visual" : "presentation"}:
            <br />
            <div style={{ background: "lightgray" }} className="w-100">
              <code>{`<iframe src="${props.url}" width="640" height="360"></iframe>`}</code>
            </div>
          </div>
        ) : null}
        <Image
          src={props.qr}
          className={"w-75 m-2" + (showState.qr ? "" : " d-none")}
        />
        {props.canEmail && showState.email ? (
          <>
            <div className="text-danger">{shareEmailErr || ""}</div>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={emailShareSchema}
              onSubmit={(values, actions) => {
                setShareEmailErr("");
                APIShareEmail(
                  props.id,
                  !!props.isPresentation,
                  values.email,
                  () => {
                    props.onHide();
                    actions.setSubmitting(false);
                  },
                  (err) => {
                    setShareEmailErr(err);
                    actions.setSubmitting(false);
                  }
                );
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isSubmitting,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Control
                      value={values.email}
                      onChange={handleChange}
                      name="email"
                      onBlur={handleBlur}
                      invalid={touched.email && errors.email}
                      placeholder="Destination Email"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="login"
                        aria-hidden="true"
                      />
                    ) : (
                      "Email"
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;

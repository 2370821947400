import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useState, useContext } from "react";
import { AvailableTags } from "../helper/Context";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { APIAddTags } from "../helper/APIFunctions";

const shallowCopyArray = (arr) => [...(arr.length ? arr : [])];

const AddTagForm = (props) => {
  const validParents = props.validParents.filter( (t) => t.slug !== props.tag.slug);
  const parentFilter = validParents.filter(
    (e) => e.slug === props.tag.parent
  );
  const parentTag = parentFilter.length ? [parentFilter[0]] : [];
  return (
    <div style={{ position: "relative" }}>
      <Form.Group>
        <Form.Label>Title</Form.Label>
        <Form.Control
          name="name"
          placeholder="Tag Title"
          value={props.tag.title}
          onChange={(event) => {
            const newName = event.target.value;
            props.onChange({
              title: newName,
              slug: newName
                .replace(/[\u{0080}-\u{FFFF}]/gu, "")
                .toLowerCase()
                .replace(/[^a-z0-9]/gu, "-"),
            });
          }}
          disabled={props.disabled}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Slug</Form.Label>
        <Form.Control
          name="name"
          placeholder="tag-slug"
          value={props.tag.slug}
          onChange={(event) => {
            props.onChange({ slug: event.target.value });
          }}
          isInvalid={props.tag.slug && /[^-a-z0-9]+$/.test(props.tag.slug)}
          disabled={props.disabled}
        />
        <Form.Control.Feedback type="invalid">
          Tag slug can only contain the lower case letter a-z, the digits 0-9
          and the dash (-)
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Optional Parent Tag</Form.Label>

        <Typeahead
          value={parentTag}
          options={validParents}
          labelKey="title"
          onChange={(e) =>
            props.onChange({ parent: e.length ? e[0].slug : null })
          }
          disabled={props.disabled}
          title={props.title}
          id={props.id}
        >
          {({ onClear, selected }) => (
            <div className="rbt-aux">
              {!!selected.length && (
                <Button
                  className="rbt-close btn-close close"
                  onClick={onClear}
                  variant="light"
                  disabled={props.disabled}
                />
              )}
            </div>
          )}
        </Typeahead>
      </Form.Group>
      <Form.Group>
        <Form.Label>Tag Description</Form.Label>
        <Form.Control
          as="textarea"
          value={props.tag.description}
          placeholder="Optional Description for Tag"
          onChange={(event) => {
            props.onChange({ description: event.target.value });
          }}
          disabled={props.disabled}
        />
      </Form.Group>
      <FontAwesomeIcon
        icon={faX}
        className="icon-btnlike"
        style={{ position: "absolute", top: 0, right: 0 }}
        size="xl"
        aria-label="delete"
        role="button"
        tabIndex="0"
        title={`Delete "${props.tag.title}"`}
        onClick={() => {
          if (!props.disabled) {
            props.onDelete();
          }
        }}
        onKeyDown={() => {
          if (!props.disabled) {
            props.onDelete();
          }
        }}
      />
    </div>
  );
};

const getEmptyTagObj = () => {
  return { title: "", description: "", parent: null, slug: "" };
};

const AddTagsPage = (props) => {
  const [newTags, setNewTags] = useState([getEmptyTagObj()]);
  const { availableTags, setAvailableTags } = useContext(AvailableTags);
  const [APIResponse, setAPIResponse] = useState(null)
  const validParents = (availableTags || [])
    .concat(newTags)
    .filter((e) => e.title && e.slug);
  return (
    <>
      <div>
        <h2 className="TitleText">Add Visual Tags</h2>
        {APIResponse ? <p className={"text-center " + (APIResponse.error? "text-danger" : "text-success")}>{APIResponse.msg}</p> : null}
      </div>
      <Container>
        <Row>
          {newTags.map((tag, ind) => {
            return (
              <Col key={ind} xs={12} sm={6} md={4} lg={3}>
                <div className="border rounded border-secondary m-1">
                  <AddTagForm
                    tag={tag}
                    validParents={validParents}
                    id={`add-tag-parent-field${ind + 1}`}
                    onChange={(newProps) => {
                      let temp = shallowCopyArray(newTags);
                      Object.assign(temp[ind], newProps);
                      setNewTags(temp);
                    }}
                    onDelete={() =>
                      setNewTags(newTags.filter((_, i) => i !== ind))
                    }
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Button
        onClick={() => {
          const temp = shallowCopyArray(newTags);
          temp.push(getEmptyTagObj());
          setNewTags(temp);
        }}
        disabled={newTags.length >= 75}
      >
        Add More
      </Button>
      <Button
        onClick={() => {
          setAPIResponse(null);
          APIAddTags(newTags, ()=>{
            setAPIResponse({error: false, msg:"Success"});
            setAvailableTags(availableTags.concat(newTags));
            setNewTags([getEmptyTagObj()]);
          }, (err)=>{
            console.log(err);
            setAPIResponse({error: true, msg:"There was an error."})
          })
        }}
        disabled={!newTags.length && newTags.every((t) => t.slug && t.tile)}
      >
        Submit
      </Button>
    </>
  );
};

export default AddTagsPage;

import { AvailableTags } from "../helper/Context";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button } from "react-bootstrap";

const TagSelector = (props) => {
  return (
    <AvailableTags.Consumer>
      {({ availableTags, setAvailableTags }) => (
        <Typeahead
          defaultSelected={props.defaultSelected || null}
          options={availableTags}
          labelKey="title"
          onChange={props.onChange}
          multiple={props.multiple}
          disabled={props.disabled}
          title={props.title}
          id={props.id}
        >
          {({ onClear, selected }) => (
            <div className="rbt-aux">
              {!!selected.length && (
                <Button
                  className="rbt-close btn-close close"
                  onClick={onClear}
                  variant="light"
                />
              )}
            </div>
          )}
        </Typeahead>
      )}
    </AvailableTags.Consumer>
  );
};
export default TagSelector;

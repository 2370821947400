import { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Spinner,
  Image,
  Carousel,
  Row,
  Col,
} from "react-bootstrap";
import { UserInfo, Bundles } from "../helper/Context.js";

function APISendToTerminal(
  name,
  email,
  term,
  isPremium,
  isWaitingRoom,
  successCB,
  failureCB
) {
  const url = process.env.REACT_APP_API_ENDPOINT + "/admin/gnydm2023/special";
  const b = {
    email: email,
    name: name,
    period: term,
    premium: isPremium,
    type: isWaitingRoom ? "waiting" : null,
  };
  return fetch(url, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(b),
  })
    .then(async (response) => {
      const cbFunc = response.ok ? successCB : failureCB;
      const body = await response.json();
      cbFunc(body);
    })
    .catch((reason) => {
      failureCB(reason);
    });
}

const BundleDisplay = (props) => {
  const [slideId, setSlideId] = useState(0);
  const [slideDims, setSlideDims] = useState([undefined, undefined]);
  const maxImageDims = [500, (500 * 9) / 16];
  return (
    <>
      <h4 className="TitleText">{props.bundle.name}</h4>
      <p>{props.bundle.description}</p>
      <p>Includes {props.bundle.visuals.length} visuals.</p>
      <Carousel
        fade
        pause="hover"
        touch
        id={`carousel-${props.bundle.id}`}
        style={{
          maxWidth: maxImageDims[0] + "px",
          maxHeight: maxImageDims[1] + "px",
          height: "initial",
        }}
        onSelect={(i) => {
          const s = document.getElementById(`carousel-${props.bundle.id}`);
          setSlideDims([
            slideDims[0] || s.clientWidth,
            slideDims[1] || s.clientHeight,
          ]);
          setSlideId(i);
        }}
        indicatorLabels={props.bundle.visuals.map(
          (v, i) => `Visual ${i + 1}: ${v.title}`
        )}
      >
        {props.bundle.visuals.map((v, i) => (
          <Carousel.Item
            key={v.id}
            style={{
              background: "rgba(0,0,0,0.075)",
            }}
          >
            <a href={v.visualization_url} target="_blank" rel="noreferrer">
              <Image
                src={v.thumbnail}
                className="w-100"
                width={slideDims[0]}
                height={slideDims[1]}
                style={{
                  maxWidth: maxImageDims[0] + "px",
                  maxHeight: maxImageDims[1] + "px",
                }}
              />
              <Carousel.Caption
                style={{
                  background: "var(--carousel-background)",
                  borderRadius: "10px",
                }}
              >
                <h5 className="TitleText">
                  Visual {i + 1}: {v.title}
                </h5>
              </Carousel.Caption>
            </a>
          </Carousel.Item>
        ))}
      </Carousel>
      {!props.hideDesc ? (
        <p className="text-muted">
          {props.bundle.visuals[slideId || 0].description}
        </p>
      ) : null}
    </>
  );
};

const GNYDM2023 = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isMonthly, setIsMonthly] = useState(true);
  const [apiResult, setApiResult] = useState({});
  const [isPremium, setIsPremium] = useState(false);
  const [isWaitingRoom, setIsWaitingRoom] = useState(false);
  const { userInfo, setUserInfo } = useContext(UserInfo);
  useEffect(() => {
    if (userInfo && userInfo.admin) {
      document.querySelector("nav.navbar").classList.remove("navbar-expand-lg");
      document.querySelector("button.navbar-toggler").hidden = true;
      document.querySelector("a.navbar-brand").style.pointerEvents = "none";
      document
        .querySelectorAll("footer .visualization_link")
        .forEach((e) => (e.hidden = true));
    }
    return () => {
      document.querySelector("nav.navbar").classList.add("navbar-expand-lg");
      document.querySelector("button.navbar-toggler").hidden = false;
      document.querySelector("a.navbar-brand").style.pointerEvents = "";
      document
        .querySelectorAll("footer .visualization_link")
        .forEach((e) => (e.hidden = false));
    };
  }, []);
  return (
    <>
      <Image
        src="https://www.gnydm.com/images/logo-2023.png"
        style={{ maxWidth: "350px", float: "right" }}
      />
      {!userInfo || !userInfo.admin ? (
        <>
          <p className="mt-2">
            We are extremely excited to be an exhibitor at the 2023 Greater New
            York Dental Meeting!
          </p>
          <p>
            During the meeting, we will be providing a special in-person deal
            where new Unlimited tier users will get a Dental Patient Education
            Bundle for free! If you are interested in signing up, upgrading your
            plan, or in learning more, please come visit us at the Javits Center
            between November 26th and 29th.
          </p>
          <p className="mb-4">
            We are located at booth 422, right next to the poster presentation
            area. We hope to see you there!
          </p>
        </>
      ) : null}
      <Row>
        <Col xs={12} lg={6}>
          <Bundles.Consumer>
            {({ bundles, setBundles }) => {
              return (bundles || [])
                .filter((b) => b.id == "bTrdZC_FzDEs")
                .map((b) => (
                  <BundleDisplay
                    bundle={b}
                    hideDesc={userInfo && userInfo.admin}
                  />
                ));
            }}
          </Bundles.Consumer>
        </Col>
      </Row>
      {userInfo && userInfo.admin ? (
        <div className="mt-4">
          {apiResult.error ? (
            <div className="text-danger">{apiResult.error}</div>
          ) : null}
          {apiResult.success ? (
            <div className="text-success">
              Please continue to card payment terminal.
            </div>
          ) : null}
          <h3 className="my-2 TitleText">Customer Information</h3>
          <Row>
          <Form.Group className="my-2 col-12">
            <div style={{width: "fit-content", marginLeft:"auto", marginRight:"auto"}}>
                  Snorkle Plan
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={isWaitingRoom}
                    inline={true}
                    className="ms-3"
                    title={`Switch to a ${
                      !isPremium ? "premium" : "unlimited"
                    } plan`}
                    onChange={(e) => setIsWaitingRoom(e.target.checked)}
                    disabled={apiResult.sending}
                  />
                  Waiting Room Materials</div>
                </Form.Group>
            <Form.Group className="my-2 col-12 col-md-6">
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Customer Name"
                disabled={apiResult.sending}
              />
            </Form.Group>
            <Form.Group className="my-2 col-12 col-md-6">
              <Form.Control
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Customer Email"
                disabled={apiResult.sending}
              />
            </Form.Group>
            {!isWaitingRoom ? (
              <>
                <Form.Group className="my-2 col-12 col-md-6 ">
                  <div>
                    <Form.Label style={{ marginLeft: "5rem" }}>Plan</Form.Label>
                  </div>
                  Premium
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={!isPremium}
                    inline={true}
                    className="ms-3"
                    title={`Switch to a ${
                      !isPremium ? "premium" : "unlimited"
                    } plan`}
                    onChange={(e) => setIsPremium(!e.target.checked)}
                    disabled={apiResult.sending}
                  />
                  Unlimited with Dental Bundle
                </Form.Group>
                <Form.Group className="my-2 col-12 col-md-6 ">
                  <div>
                    <Form.Label style={{ marginLeft: "5.75rem" }}>
                      Billing Period
                    </Form.Label>
                  </div>
                  Monthly Billing
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={!isMonthly}
                    inline={true}
                    className="ms-3"
                    title={`Switch to ${
                      isMonthly ? "annual" : "monthly"
                    } billing`}
                    onChange={(e) => setIsMonthly(!e.target.checked)}
                    disabled={apiResult.sending}
                  />
                  Annual Billing
                  <p style={{ marginLeft: "5.5rem" }}>
                    {!isPremium
                      ? isMonthly
                        ? "$30.00/Month"
                        : "$21.00/Month"
                      : null}
                    {isPremium
                      ? isMonthly
                        ? "$10.00/Month"
                        : "$7.00/Month"
                      : null}
                  </p>
                </Form.Group>
              </>
            ) : null}
          </Row>
          <Form.Group className="my-2 col-12 col-md-6 ">
            <Button
              onClick={() => {
                setApiResult({ sending: true });
                APISendToTerminal(
                  name,
                  email,
                  isMonthly ? "monthly" : "annual",
                  isPremium,
                  isWaitingRoom,
                  () => {
                    setApiResult({ success: true });
                    setName("");
                    setEmail("");
                    setIsMonthly(true);
                    setIsPremium(false);
                  },
                  (err) => {
                    setApiResult({ error: err });
                  }
                );
              }}
              disabled={apiResult.sending}
            >
              {apiResult.sending ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="login"
                  aria-hidden="true"
                />
              ) : (isWaitingRoom ? "Buy ($10.00)":
                "Get Started! (" +
                (isPremium
                  ? isMonthly
                    ? "$10.00/Month"
                    : "$84.00/Year"
                  : isMonthly
                  ? "$30.00/Month"
                  : "$252.00/Year") +
                " After 14 Day Trial)"
              )}
            </Button>
          </Form.Group>
        </div>
      ) : null}
    </>
  );
};

export default GNYDM2023;

import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button, Row, Col, Spinner, Image } from "react-bootstrap";
import { APIDeletePresentation } from "../helper/APIFunctions";
import { shallowCopy } from "../helper/Utils";
import {
  faTrashCan as deleteIcon,
  faSave,
  faClipboard,
  faCopy,
  faStar as faStarEmpty,
} from "@fortawesome/free-regular-svg-icons";
import {
  faPencil as editIcon,
  faShareFromSquare,
  faStar as faStarFill,
  faGlobe as faPublicIcon,
  faLink as faUnlistedIcon,
  faLock as faPrivateIcon,
} from "@fortawesome/free-solid-svg-icons";
import ShareModal from "./VisualModals/Share";
const highlightText = (text, highlightPhrase) => {
  const lower = text.toLowerCase();
  const inds = [];
  let currInd = lower.indexOf(highlightPhrase);
  if (highlightPhrase && currInd >= 0) {
    inds[0] = currInd;
    currInd = lower.indexOf(highlightPhrase, inds[0] + highlightPhrase.length);
    while (currInd >= 0) {
      inds.push(currInd);
      currInd = lower.indexOf(
        highlightPhrase,
        inds[inds.length - 1] + highlightPhrase.length
      );
    }
    let output = [];
    output.push(text.substring(0, inds[0]));
    for (let i = 0; i < inds.length - 1; i++) {
      output.push(
        <mark className="p-0 bg-warning" key={i}>
          {text.substring(inds[i], inds[i] + highlightPhrase.length)}
        </mark>
      );
      output.push(
        text.substring(inds[i] + highlightPhrase.length, inds[i + 1])
      );
    }
    let i = inds.length - 1;
    output.push(
      <mark className="p-0  bg-warning" key={inds.length}>
        {text.substring(inds[i], inds[i] + highlightPhrase.length)}
      </mark>
    );
    output.push(text.substring(inds[i] + highlightPhrase.length));
    return output;
  } else {
    return text;
  }
};

const DeleteModal = (props) => {
  const [isCallingAPI, setIsCallingAPI] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [askConfirm, setAskConfirm] = useState(!!props.presentation.favorite);
  const [modalBody, setModalBody] = useState(
    `Are you sure you want to delete "${props.presentation.title}"?`
  );
  return (
    <Modal show={props.show}>
      <Modal.Body>
        <p className="text-danger text-center">{deleteError}</p>
        <p>{modalBody}</p>
        <Button
          onClick={(event) => {
            if (askConfirm) {
              setModalBody(
                "This visual is a favorite presentation. Are you really sure you want to delete it?"
              );
              setAskConfirm(false);
            } else {
              setIsCallingAPI(true);
              setDeleteError(null);
              APIDeletePresentation(
                props.presentation,
                (res) => {
                  setIsCallingAPI(false);
                  props.onPresDelete(props.presentation);
                },
                (err) => {
                  setDeleteError(err.message || err);
                  setIsCallingAPI(false);
                },
                props.isOrg
              );
            }
          }}
          disabled={isCallingAPI}
        >
          {isCallingAPI ? (
            <Spinner
              animation="border"
              role="loading"
              className="text-secondary align-center"
              size="sm"
            />
          ) : (
            <>Confirm</>
          )}
        </Button>
        <Button
          onClick={() => {
            props.onCancel();
            setAskConfirm(props.presentation.favorite);
            setModalBody(
              `Are you sure you want to delete "${props.presentation.title}"?`
            );
          }}
          disabled={isCallingAPI}
        >
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

const Presentations = (props) => {
  const [copyNotice, setCopyNotice] = useState(null);
  const [titleOnly, setTitleOnly] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [favoriteCall, setFavoriteCall] = useState(false);
  let dateString = null;
  const formatter = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
  });

  if (props.presentation.created_timestamp) {
    const uploadDate = new Date(props.presentation.created_timestamp * 1000);
    dateString = formatter.format(uploadDate);
  }
  let privacyIcon = null;
  let privacyTitle = "";
  if (!props.presentation.privacy || props.presentation.privacy == "unlisted") {
    privacyIcon = faUnlistedIcon;
    privacyTitle = "Unlisted";
  } else if (props.presentation.privacy == "public") {
    privacyIcon = faPublicIcon;
    privacyTitle = "Public";
  }
  return (
    <>
      <ShareModal
        url={props.presentation.url}
        show={showShareModal}
        qr={props.presentation.qr_url}
        canEmail={props.canEmail}
        onHide={() => {
          setShowShareModal(false);
        }}
        isPresentation={true}
        id={props.presentation.id}
      />
      {props.canDelete ? (
        <DeleteModal
          presentation={props.presentation}
          show={showDeleteModal}
          onPresDelete={(localVis) => {
            setShowDeleteModal(false);
            props.onPresDelete(props.presentation);
          }}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          isOrg={props.isOrg}
        />
      ) : null}
      <div
        className={"my-1 p-3 w-100 vis-card position-relative" + (titleOnly ? " d-none": "")}
        id={`vis-card-${props.presentation.id}`}
      >
        <>
          <div className="position-absolute top-0 end-0">
            {
              props.canAdd ? <> <FontAwesomeIcon
              icon={faCopy}
              className="icon-btnlike me-2"
              size="xl"
              aria-label="copy"
              role="button"
              tabIndex="0"
              title={`Create a copy of "${props.presentation.title}"`}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                const t = shallowCopy(props.presentation);
                t.id = "";
                t.title += " - Copy";
                props.setEdit(t);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  event.stopPropagation();
                  const t = shallowCopy(props.presentation);
                  t.id = "";
                  t.title += " - Copy";
                  props.setEdit(t);
                  }
              }}
            /></> : null
            }
            {props.canEdit ? (
              <FontAwesomeIcon
                icon={editIcon}
                className="icon-btnlike me-2"
                size="xl"
                aria-label="edit"
                role="button"
                tabIndex="0"
                title={`Edit "${props.presentation.title}"`}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  props.setEdit(props.presentation);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    event.stopPropagation();
                    props.setEdit(props.presentation);
                  }
                }}
              />
            ) : null}
            {props.canDelete ? (
              <FontAwesomeIcon
                icon={deleteIcon}
                className="text-danger icon-btnlike"
                size="xl"
                aria-label="delete"
                role="button"
                tabIndex="0"
                title={`Delete "${props.presentation.title}"`}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setShowDeleteModal(true);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setShowDeleteModal(true);
                  }
                }}
              />
            ) : null}
          </div>
        </>
        {!props.hideFavorite ? (
          <FontAwesomeIcon
            icon={
              (
                favoriteCall
                  ? !props.presentation.favorite
                  : props.presentation.favorite
              )
                ? faStarFill
                : faStarEmpty
            }
            className={"icon-btnlike"}
            style={{
              position: "absolute",
              top: "-1rem",
              left: "-0.75rem",
              opacity: 1,
              backgroundColor: "#eee", //TODO
              borderRadius: "50%",
              color: `rgba(${
                (
                  favoriteCall
                    ? !props.presentation.favorite
                    : props.presentation.favorite
                )
                  ? "255, 193, 7"
                  : "33, 37, 41" //TODO
              },${favoriteCall ? 0.5 : 1})`,
            }}
            size="lg"
            aria-label="edit"
            role={props.canEdit ? "button" : null}
            tabIndex={props.canEdit ? "0" : null}
            title={`${
              props.presentation.favorite ? "Unfavorite" : "Favorite"
            } "${props.presentation.title}"`}
            onClick={
              props.canEdit
                ? (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (favoriteCall) {
                      return;
                    }

                    setFavoriteCall(true);
                  }
                : null
            }
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                if (favoriteCall) {
                  return;
                }
                setFavoriteCall(true);
              }
            }}
            disabled={favoriteCall}
          />
        ) : null}
        <Row>
          <div className="col-2 col-md-1 pe-0">
          {(props.canEdit || !props.isOrg) &&!props.hidePrivacyIcon ? (
                <FontAwesomeIcon
                  icon={privacyIcon}
                  size="xl"
                  aria-label="share"
                  title={privacyTitle}
                  style={{ marginLeft: "-.5rem" }}
                />
              ) : null}

            {props.showTypeIcon ? (
              <Image
                src="/portal/snorkle_vis_thumbnails/presentation_icon.jpg"
                style={{
                  aspectRatio: 1,
                  width: "100%",
                  maxWidth: "3em",
                }}
                title={"Snorkle Presentation"}
              />
            ) : null}
          </div>
          <Row className="col-10 col-md-11">
            <Row>
              <Col
                xs="auto"
                className="flex-grow-1"
                onClick={() => {
                  if (props.canShrink) {
                    setTitleOnly(true);
                  }
                }}
              >
                <h3 style={{ overflowWrap: "anywhere" }} className="me-3 vis-card-title">
                  {props.presentation.title}
                </h3>
              </Col>
              <Col className="text-end" xs="auto">
                <span className="me-4 text-muted">{dateString}</span>
              </Col>
            </Row>
            <Col xs={12} sm={6} md={4} lg={3}>
              <Row className="vis-card-images">
                {props.presentation.thumbnail ? (
                  <Col xs={6} sm={12}  lg={props.hideQr ? 12 : 6}  className="mb-2">
                    <Image
                      src={props.presentation.thumbnail}
                      alt={props.presentation.title}
                      style={{width: "100%", height:"auto", maxWidth:"10rem", maxHeight: "10rem"}}
                    />
                  </Col>
                ) : null}
                {!props.hideQr ? (
                  <Col xs={6} sm={12} lg={props.presentation.thumbnail ? 6 : 12} className="mb-2">
                    <Image
                      className="vis-card-qrcode"
                      src={props.presentation.qr_url}
                      alt={props.presentation.title + " QR Code"}
                      style={{width: "100%", height:"auto", maxWidth:"10rem", maxHeight: "10rem"}}
                    />
                  </Col>
                ) : null}
              </Row>
            </Col>
            <Col xs={12} sm={6} md={8} lg={9}>
              <p style={{ overflowWrap: "anywhere" }}>
                <b>Description: </b>
                <span className="vis-card-description">{props.presentation.description}</span>
              </p>
              <b>Presentation URL: </b>
              <a
                href={props.presentation.url}
                target="_blank"
                rel="noreferrer"
                className="visualization_link me-3"
                style={{ overflowWrap: "anywhere" }}
              >
                {props.presentation.url}
              </a>
              {navigator.clipboard ? (
                <span>
                  <FontAwesomeIcon
                    icon={faClipboard}
                    size="xl"
                    aria-label="copy url"
                    role="button"
                    tabIndex="0"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      navigator.clipboard
                        .writeText(props.presentation.url)
                        .then(() => {
                          setCopyNotice("Copied URL");
                          setTimeout(() => {
                            setCopyNotice(null);
                          }, 1500);
                        });
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        navigator.clipboard
                          .writeText(props.presentation.url)
                          .then(() => {
                            setCopyNotice("Copied URL");
                            setTimeout(() => {
                              setCopyNotice(null);
                            }, 1500);
                          });
                      }
                    }}
                    className="icon-btnlike"
                  />
                  {copyNotice}
                </span>
              ) : null}
            </Col>
          </Row>
        </Row>
        <FontAwesomeIcon
          icon={faShareFromSquare}
          className="icon-btnlike position-absolute bottom-0 end-0 mb-3 me-2"
          size="xl"
          aria-label="share"
          title={`Share "${props.presentation.title}"`}
          role="button"
          tabIndex="0"
          onClick={(event) => {
            setShowShareModal(true);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setShowShareModal(true);
            }
          }}
        />
      </div>
      <div className={"p-3 my-1 " + (!titleOnly ? "d-none" : "")}>
        <Row>
          <Col xs={1} />
          <Col
            xs={11}
            onClick={() => {
              if (props.canShrink) {
                setTitleOnly(false);
              }
            }}
          >
            <h3 style={{ overflowWrap: "anywhere" }} className="me-3">
              {highlightText(props.presentation.title, props.highlightText)}
            </h3>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Presentations;

import { useState, useContext, useEffect } from "react";
import {
  Form,
  Tabs,
  Tab,
  Modal,
  Button,
  Spinner,
  ListGroup,
  Table,
  Collapse,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  faPencil,
  faCheck,
  faXmark,
  faCrown,
} from "@fortawesome/free-solid-svg-icons";
import {
  APIGetOrgInfo,
  APIInviteOrgMember,
  APIRemoveMember,
  APIEditMember,
} from "../helper/APIFunctions";
import { UserInfo } from "../helper/Context";
import * as Yup from "yup";
import { Formik } from "formik";
import { shallowCopy } from "../helper/Utils";
import BrandingForm from "../components/BrandingForm";

const PermissionsForm = (props) => {
  return (
    <>
      <Form.Label>Permissions</Form.Label>
      <Table bordered striped="columns" className="text-center align-middle">
        <thead>
          <tr>
            <th></th>
            <th>Visuals</th>
            <th>Presentations</th>
            <th>Members</th>
            <th>Branding</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Add</th>
            <td>
              <Form.Check
                type="checkbox"
                name="add_visuals"
                checked={props.values.add_visuals}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                disabled={props.isSubmitting}
              />
            </td>
            <td>
              <Form.Check
                type="checkbox"
                name="add_presentations"
                checked={props.values.add_presentations}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                disabled={props.isSubmitting}
              />
            </td>
            <td>
              <Form.Check
                type="checkbox"
                name="add_members"
                checked={props.values.add_members}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                disabled={props.isSubmitting}
              />
            </td>
            <td rowSpan="3">
              <Form.Check
                type="checkbox"
                name="set_branding"
                checked={props.values.set_branding}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                disabled={props.isSubmitting}
              />
            </td>
          </tr>
          <tr>
            <th>Edit</th>
            <td>
              <Form.Check
                type="checkbox"
                name="edit_visuals"
                checked={props.values.edit_visuals}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                disabled={props.isSubmitting}
              />
            </td>
            <td>
              <Form.Check
                type="checkbox"
                name="edit_presentations"
                checked={props.values.edit_presentations}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                disabled={props.isSubmitting}
              />
            </td>
            <td>
              <Form.Check
                type="checkbox"
                name="edit_members"
                checked={props.values.edit_members}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                disabled={props.isSubmitting}
              />
            </td>
          </tr>
          <tr>
            <th>Remove</th>
            <td>
              <Form.Check
                type="checkbox"
                name="remove_visuals"
                checked={props.values.remove_visuals}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                disabled={props.isSubmitting}
              />
            </td>
            <td>
              <Form.Check
                type="checkbox"
                name="remove_presentations"
                checked={props.values.remove_presentations}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                disabled={props.isSubmitting}
              />
            </td>
            <td>
              <Form.Check
                type="checkbox"
                name="remove_members"
                checked={props.values.remove_members}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                disabled={props.isSubmitting}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

const DeleteModal = (props) => {
  const [isCallingAPI, setIsCallingAPI] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Modal
        show={props.show}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Modal.Body>
          <p className="text-danger text-center">{deleteError}</p>
          <p>
            Are you sure you want to remove{" "}
            {props.email === props.loggedInEmail ? "yourself" : props.email}{" "}
            from your organization?
          </p>
          <Button
            onClick={(event) => {
              setIsCallingAPI(true);
              setDeleteError(null);
              APIRemoveMember(
                props.email,
                (res) => {
                  setIsCallingAPI(false);
                  props.onDelete(props.email);
                },
                (err) => {
                  setDeleteError(err);
                  setIsCallingAPI(false);
                }
              );
              event.stopPropagation();
            }}
            disabled={isCallingAPI}
          >
            {isCallingAPI ? (
              <Spinner
                animation="border"
                role="loading"
                className="text-secondary align-center"
                size="sm"
              />
            ) : (
              <>Confirm</>
            )}
          </Button>
          <Button onClick={props.onCancel} disabled={isCallingAPI}>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const EditModal = (props) => {
  const [newPermissions, setNewPermission] = useState(props.permissions);
  const [isCallingAPI, setIsCallingAPI] = useState(false);
  const [editError, setEditError] = useState(null);
  const failureCB = (err) => {
    setEditError(
      (err && err.error) || err.toString() || "Internal Server Error"
    );
    setIsCallingAPI(false);
  };

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Modal show={props.show} onHide={props.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-danger text-center">{editError}</p>
          <p>{props.email}</p>
          <PermissionsForm
            values={newPermissions}
            handleBlur={() => {}}
            isSubmitting={isCallingAPI}
            handleChange={(event) => {
              let vals = shallowCopy(newPermissions);
              vals[event.target.name] = event.target.checked;
              setNewPermission(vals);
            }}
          />
          <Button
            onClick={(event) => {
              setIsCallingAPI(true);
              setEditError(null);
              APIEditMember(
                props.email,
                newPermissions,
                (res) => {
                  setIsCallingAPI(false);
                  props.onEdit(props.email, newPermissions);
                },
                failureCB
              );
              event.stopPropagation();
            }}
            disabled={isCallingAPI}
          >
            {isCallingAPI ? (
              <Spinner
                animation="border"
                role="loading"
                className="text-secondary align-center"
                size="sm"
              />
            ) : (
              <>Confirm</>
            )}
          </Button>
          <Button onClick={props.onCancel} disabled={isCallingAPI}>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const MemberCard = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const member = props.member;
  const canEdit = props.canEditPermission && member.email != props.currentEmail;
  const canDelete =
    props.canDeletePermission || member.email === props.currentEmail;
  const checkFa = (
    <FontAwesomeIcon
      icon={faCheck}
      className="text-success text-center"
      size="2x"
    />
  );
  const xFa = (
    <FontAwesomeIcon
      icon={faXmark}
      className="text-danger text-center"
      size="2x"
    />
  );
  return (
    <ListGroup.Item
      onClick={() => setShowDetails(!showDetails)}
      tabIndex={
        props.currentEmail === member.email || props.canViewPermissions
          ? "0"
          : null
      }
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          setShowDetails(!showDetails);
        }
      }}
      className={member.email == props.currentEmail ? "current-email" : ""}
    >
      {member.email}
      {member.admin ? (
        <FontAwesomeIcon
          icon={faCrown}
          title="Administrator"
          className="ms-4"
          size="xl"
        />
      ) : (
        <div className="position-absolute top-0 end-0 mt-2 me-2">
          {canEdit ? (
            <FontAwesomeIcon
              icon={faPencil}
              className="icon-btnlike me-2"
              size="xl"
              aria-label="edit"
              role="button"
              tabIndex="0"
              title={`Edit Permissions for"${member.email}"`}
              onClick={(event) => {
                setShowEditModal(true);
                event.stopPropagation();
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setShowEditModal(true);
                  event.stopPropagation();
                }
              }}
            />
          ) : null}
          {canDelete ? (
            <FontAwesomeIcon
              icon={faTrashCan}
              className="text-danger icon-btnlike"
              size="xl"
              aria-label="delete"
              role="button"
              tabIndex="0"
              title={`Remove "${member.email}"`}
              onClick={(event) => {
                setShowDeleteModal(true);
                event.stopPropagation();
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setShowDeleteModal(true);
                  event.stopPropagation();
                }
              }}
            />
          ) : null}
        </div>
      )}
      {canEdit ? (
        <EditModal
          show={showEditModal}
          onCancel={() => setShowEditModal(false)}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onEdit={(email, permissions) => {
            props.onEditCB(email, permissions);
            setShowEditModal(false);
          }}
          permissions={member.permissions}
          email={member.email}
        />
      ) : null}
      {canDelete ? (
        <DeleteModal
          show={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          onDelete={(email) => {
            props.onDeleteCB(email);
            setShowDeleteModal(false);
          }}
          email={member.email}
          loggedInEmail={props.currentEmail}
        />
      ) : null}
      {props.currentEmail === member.email || props.canViewPermissions ? (
        <Collapse in={showDetails}>
          <div className="mt-3">
            {member.admin ? (
              <div className="text-center">Administrator</div>
            ) : (
              <>
                <div className="text-center">User Permissions</div>
                <Table
                  bordered
                  striped="columns"
                  className="text-center align-middle"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Visuals</th>
                      <th>Presentations</th>
                      <th>Members</th>
                      <th>Branding</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Add</th>
                      <td>{member.permissions.add_visuals ? checkFa : xFa}</td>
                      <td>{member.permissions.add_presentations ? checkFa : xFa}</td>
                      <td>{member.permissions.add_members ? checkFa : xFa}</td>
                      <td rowSpan="3">
                        {member.permissions.set_branding ? checkFa : xFa}
                      </td>
                    </tr>
                    <tr>
                      <th>Edit</th>
                      <td>{member.permissions.edit_visuals ? checkFa : xFa}</td>
                      <td>{member.permissions.edit_presentations ? checkFa : xFa}</td>
                      <td>{member.permissions.edit_members ? checkFa : xFa}</td>
                    </tr>
                    <tr>
                      <th>Remove</th>
                      <td>
                        {member.permissions.remove_visuals ? checkFa : xFa}
                      </td>
                      <td>{member.permissions.remove_presentations ? checkFa : xFa}</td>
                      <td>
                        {member.permissions.remove_members ? checkFa : xFa}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            )}
          </div>
        </Collapse>
      ) : null}
    </ListGroup.Item>
  );
};
const AddMemberModal = (props) => {
  const [result, setResult] = useState(null);
  const validationSchema = Yup.object().shape({
    newMemberEmail: Yup.string().required("Required").email("Invalid Format"),
    addMembers: Yup.bool(),
    addVisuals: Yup.bool(),
    editMembers: Yup.bool(),
    editVisuals: Yup.bool(),
    removeMembers: Yup.bool(),
    removeVisuals: Yup.bool(),
    setBranding: Yup.bool(),
  });
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {result ? <p className={result.style}>{result.message}</p> : null}
        <Formik
          initialValues={{
            newMemberEmail: "",
            add_members: false,
            add_visuals: false,
            set_branding: false,
            edit_visuals: false,
            edit_members: false,
            remove_visuals: false,
            remove_members: false,
            add_presentations: false,
            edit_presentations: false,
            remove_presentations: false,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            setResult(null);
            let permissionMap = shallowCopy(values);
            delete permissionMap.newMemberEmail;
            APIInviteOrgMember(
              values.newMemberEmail,
              permissionMap,
              (body) => {
                setResult({
                  style: "text-center text-success",
                  message: "New Member Invited",
                });
                actions.setSubmitting(false);
                props.addMemberCB(values.newMemberEmail, permissionMap);
              },
              (body) => {
                setResult({
                  style: "text-danger text-center",
                  message:
                    (body && body.error) ||
                    body.toString() ||
                    "Internal Server Error",
                });
                actions.setSubmitting(false);
              }
            );
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="new-member-email" className="mb-3">
                <Form.Label>New Member Email</Form.Label>
                <Form.Control
                  type="text"
                  name="newMemberEmail"
                  value={values.newEmail}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  isInvalid={touched.newMemberEmail && errors.newMemberEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.newMemberEmail}
                </Form.Control.Feedback>
              </Form.Group>
              <PermissionsForm
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
              />
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="Adding Member"
                    aria-hidden="true"
                  />
                ) : (
                  "Add Member"
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const MembershipInfo = (props) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const canViewPermissions =
    props.userInfo.organization_permissions.add_members ||
    props.userInfo.organization_permissions.edit_members ||
    props.userInfo.organization_permissions.remove_members;
  return !props.fetchError ? (
    <div className={props.orgInfoGetStatus.received ? "" : "d-none"}>
      {props.userInfo.organization_permissions.add_members ? (
        <AddMemberModal
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
          addMemberCB={(email, permissions) => {
            let temp = shallowCopy(props.orgInfo);
            temp.pending_members = props.orgInfo.pending_members.concat([
              { email: email, permissions: permissions },
            ]);
            props.setOrgInfo(temp);
          }}
        />
      ) : null}
      <h5 className="TitleText">
        {props.orgInfo ? props.orgInfo.organization.name : null}
      </h5>
      <div className="mt-3">
        <div className="text-center">Current Members</div>
        <ListGroup>
          {props.orgInfo && props.orgInfo.current_members
            ? props.orgInfo.current_members.map((member) => (
                <MemberCard
                  member={member}
                  key={member.email}
                  currentEmail={props.userInfo.email}
                  canViewPermissions={canViewPermissions}
                  canEditPermission={
                    props.userInfo.organization_permissions.edit_members
                  }
                  canDeletePermission={
                    props.userInfo.organization_permissions.remove_members
                  }
                  onDeleteCB={(email) => {
                    if (email === props.userInfo.email) {
                      let temp = shallowCopy(props.userInfo);
                      temp.organization_permissions = null;
                      props.setUserInfo(temp);
                    } else {
                      let temp = shallowCopy(props.orgInfo);
                      temp.current_members = props.orgInfo.current_members.filter(
                        (member) => member.email != email
                      );
                      props.setOrgInfo(temp);
                    }
                  }}
                  onEditCB={(email, permissions) => {
                    let temp = {};
                    temp.organization = props.orgInfo.organization;
                    temp.pending_members = props.orgInfo.pending_members;
                    temp.current_members = props.orgInfo.current_members.map(
                      (member) =>
                        member.email === email
                          ? {
                              email: member.email,
                              permissions: permissions,
                            }
                          : member
                    );
                    props.setOrgInfo(temp);
                  }}
                />
              ))
            : null}
        </ListGroup>
      </div>
      {canViewPermissions ? <div className="mt-3">
        <div className="text-center">Invitations Pending</div>
        <ListGroup>
          {props.orgInfo && props.orgInfo.pending_members
            ? props.orgInfo.pending_members.map((member) => (
                <MemberCard
                  member={member}
                  key={member.email}
                  currentEmail={props.userInfo.email}
                  canViewPermissions={canViewPermissions}
                  canEditPermission={
                    props.userInfo.organization_permissions.edit_members &&
                    member.email != props.userInfo.email
                  }
                  canDeletePermission={
                    props.userInfo.organization_permissions.remove_members &&
                    member.email != props.userInfo.email
                  }
                  pendingInvite={true}
                  onDeleteCB={(email) => {
                    let temp = shallowCopy(props.orgInfo);
                    temp.pending_members = props.orgInfo.pending_members.filter(
                      (member) => member.email != email
                    );
                    props.setOrgInfo(temp);
                  }}
                  onEditCB={(email, permissions) => {
                    let temp = {};
                    temp.organization = props.orgInfo.organization;
                    temp.current_members = props.orgInfo.current_members;
                    temp.pending_members = props.orgInfo.pending_members.map(
                      (member) =>
                        member.email === email
                          ? {
                              email: member.email,
                              permissions: permissions,
                            }
                          : member
                    );
                    props.setOrgInfo(temp);
                  }}
                />
              ))
            : null}
        </ListGroup>
      </div> : null}
      {props.userInfo.organization_permissions.add_members ? (
        <div className="mt-3">
          <Button onClick={() => setShowAddModal(true)}>
            Add a new member
          </Button>
        </div>
      ) : null}
    </div>
  ) : (
    <div className="text-center text-danger">
      Unable to fetch organization info
    </div>
  );
};

const OrganizationInfo = (props) => {
  const [orgInfoGetStatus, setOrgInfoGetStatus] = useState({
    sent: false,
    received: false,
  });
  const [fetchError, setFetchError] = useState(false);
  const [orgInfo, setOrgInfo] = useState(null);
  const orgInfoSuccessCB = (body) => {
    setOrgInfo(body);
    setOrgInfoGetStatus({ sent: true, received: true });
  };
  const orgInfoFailureCB = (body) => {
    setFetchError(true);
    setOrgInfoGetStatus({ sent: true, received: true });
  };
  useEffect(() => {
    if (!orgInfoGetStatus.sent) {
      APIGetOrgInfo(orgInfoSuccessCB, orgInfoFailureCB);
      setOrgInfoGetStatus({ sent: true, received: false });
    }
  }, [orgInfoGetStatus.sent]);
  return (
    <>
      <h3 className="TitleText">Organization Info</h3>
      {!orgInfoGetStatus.received ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="loading" className="text-secondary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : null}
      <UserInfo.Consumer>
        {({ userInfo, setUserInfo }) => {
          return userInfo.organization_permissions.set_branding ? (
            <Tabs
              defaultActiveKey="info"
              id="account-settings-tabs"
              className="mb-3"
            >
              <Tab eventKey="info" title="Organization Members">
                <MembershipInfo
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                  orgInfo={orgInfo}
                  setOrgInfo={setOrgInfo}
                  fetchError={fetchError}
                  orgInfoGetStatus={orgInfoGetStatus}
                />
              </Tab>
              <Tab eventKey="branding" title="Organization Branding">
                <BrandingForm isOrg={true} />
              </Tab>
            </Tabs>
          ) : (
            <MembershipInfo
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              orgInfo={orgInfo}
              setOrgInfo={setOrgInfo}
              fetchError={fetchError}
              orgInfoGetStatus={orgInfoGetStatus}
            />
          );
        }}
      </UserInfo.Consumer>
    </>
  );
};

export default OrganizationInfo;

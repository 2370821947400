import TOS from "../components/TOS";
import { Container, Row } from "react-bootstrap";

const TermsPage = (props) => {
  return (
    <>
      <h2 className="TitleText">Terms of Use</h2>
      <Container>
        <Row>
          <TOS />
        </Row>
      </Container>
    </>
  );
};

export default TermsPage;

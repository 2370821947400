import { useState, useEffect } from "react";
import { Button, Breadcrumb, Row, Col } from "react-bootstrap";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
import { DisclaimerSigned, UserInfo } from "../helper/Context";
import { FileUploadForm, WebUploadForm } from "../components/CreateVisForms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faImage, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {getVisualLifetimeHrs, getVisualLimit} from "../helper/BusinessLogic";

const sampleVisuals = [
  {
    title: "Brain MRI (DICOM upload)",
    url:
      "https://vis.snorkle.io/tJxkTPRP9sOG1SAy97rjJcQ_DObcO8JasyXECGrSHNM=/viewer.html",
  },
  {
    title: "Dental intraoral scanning sample",
    url:
      "https://vis.snorkle.io/X8hS8JTj0EBHwZfWDDEdvdrAK-iToJwvNB8lF9TJCD4=/viewer.html",
  },
  {
    title: "Green fluorescent protein (Molecule via PDB)",
    url:
      "https://vis.snorkle.io/xSMeDhXWG0-dhl-hCRrf09Br_387TGi-ULKLbW0xnfc=.html",
  },
  {
    title: "Fluorescence image stack of a developing brain (TIFF stack upload)",
    url:
      "https://vis.snorkle.io/fShXPHuJ9IPxSygagOEPiFzggi5ssgtAf2uyZJGmMrA=/viewer.html",
  },
];

const UnsignedBody = (props) => {
  return (
    <>
      <div className="text-center">
        <p>
          I understand that this software is not intended for clinical use, and
          that it is currently in beta. I also agree to the{" "}
          <Link to="/tos" target="_blank" className="visualization_link">
            {/* <Link to="https://www.snorkle.io/terms-of-use/" target="_blank" className="visualization_link"> */}
            terms of use
          </Link>
          .
        </p>
        <Button onClick={props.agree}>Agree</Button>
      </div>
    </>
  );
};

const SelectType = (props) => {
  const navigate = useNavigate();
  const goTo = (title, dest) => {
    navigate(dest);
  };
  useEffect(() => {
    const len = props.breadcrumbs.length;
    if (
      len !== props.baseCrumbs.length ||
      props.breadcrumbs[len - 1].url !== props.baseCrumbs[len - 1].url
    ) {
      props.setBreadcrumbs(props.baseCrumbs);
    }
  });
  return (
    <Row>
      <Col xs={12} sm={6} md={props.isLoggedIn ? 4 : 6}>
        <Button
          className="w-100 dashboard-item"
          id="add-image-vis-btn"
          onClick={(event) => {
            goTo("Image Source", "image");
          }}
        >
          <FontAwesomeIcon icon={faImage} size="10x" />
          <h4 className="text-center mt-4">Add Image(s)</h4>
        </Button>
      </Col>
      {props.isLoggedIn ?
      <Col xs={12} sm={6} md={4}>
        <Button
          id="search-vis-btn"
          className="w-100 dashboard-item"
          onClick={(event) => {
            goTo("Web Search", "/search");
          }}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} size="10x" />
          <h4 className="text-center mt-4">Search</h4>
        </Button>
      </Col> : null}

      <Col xs={12} sm={6} md={props.isLoggedIn ? 4 : 6}>
        <Button
          id="add-web-vis-btn"
          className="w-100 dashboard-item"
          onClick={(event) => {
            goTo("Web Source", "web");
          }}
        >
          <FontAwesomeIcon icon={faGlobe} size="10x" />
          <h4 className="text-center mt-4">Add Web Resource</h4>
        </Button>
      </Col>

      <p></p>
      <div className="pricing-container">
        <center>
          <p></p>
          <p></p>
          <h8>
            <b>Sample visuals</b> <br></br>
            These can be added to your Visuals portfolio by pasting as a Web
            Resource in <i>Add Web Resource</i>
          </h8>
        </center>
        {sampleVisuals.map((vis) => (
          <div key={vis.title} className="mt-3">
            <div>
              <h8>{vis.title}</h8>
            </div>
            <h9>
              <a className="gray-color" style={{wordBreak: "break-all"}} href={vis.url} target="_blank">
                {vis.url}
              </a>
            </h9>
          </div>
        ))}
      </div>
    </Row>
  );
};

const UploadPage = (props) => {
  const baseCrumbs = props.breadcrumbs ? [...props.breadcrumbs] : null; //make a deep copy
  const [breadcrumbs, setBreadcrumbs] = useState(baseCrumbs);
  const location = useLocation();
  return (
    <UserInfo.Consumer>
      {({ userInfo, setUserInfo }) => {
        const isLoggedIn = userInfo != null;
        return (
          <DisclaimerSigned.Consumer>
            {({ disclaimerSigned, setDisclaimerSigned }) => (
              <div>
                {breadcrumbs ? (
                  <Breadcrumb>
                    {breadcrumbs.map((entry) => (
                      <Breadcrumb.Item
                        linkAs={Link}
                        key={entry.url}
                        active={entry.url === location.pathname}
                        linkProps={{ to: entry.url }}
                      >
                        {entry.name}
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                ) : null}
                <h3 className="TitleText mb-2">{props.isOrg ? "Create a New Organization Visual": "Create a New Visual"}</h3>
                <UserInfo.Consumer>
                  {({ userInfo, setUserInfo }) => {
                    return userInfo && userInfo.tier === "free"  && !props.isOrg? (
                      <h5 className="text-center text-muted font-italic mb-4">
                        Free plan: {getVisualLimit("free")} visuals max, links persist {Math.round(getVisualLifetimeHrs("free")/24)} days
                      </h5>
                    ) : null;
                  }}
                </UserInfo.Consumer>
                {disclaimerSigned ? (
                  <Routes>
                    <Route
                      path="image"
                      element={
                        <FileUploadForm
                          isLoggedIn={isLoggedIn}
                          baseCrumbs={baseCrumbs}
                          breadcrumbs={breadcrumbs}
                          setBreadcrumbs={setBreadcrumbs}
                          isOrg={props.isOrg}
                        />
                      }
                    />
                    <Route
                      path="web"
                      element={
                        <WebUploadForm
                          isLoggedIn={isLoggedIn}
                          baseCrumbs={baseCrumbs}
                          breadcrumbs={breadcrumbs}
                          setBreadcrumbs={setBreadcrumbs}
                          isOrg={props.isOrg}
                        />
                      }
                    />
                    <Route
                      path="*"
                      element={
                        <SelectType
                          baseCrumbs={baseCrumbs}
                          breadcrumbs={breadcrumbs}
                          setBreadcrumbs={setBreadcrumbs}
                          isLoggedIn={isLoggedIn}
                        />
                      }
                    />
                  </Routes>
                ) : (
                  <UnsignedBody
                    agree={() => {
                      setDisclaimerSigned(true);
                    }}
                  />
                )}
              </div>
            )}
          </DisclaimerSigned.Consumer>
        );
      }}
    </UserInfo.Consumer>
  );
};

export default UploadPage;

import { useState, useContext } from "react";
import VisCard from "../components/VisCard";
import { UserInfo, AvailableTags, VisualCache } from "../helper/Context";
import { shallowCopy } from "../helper/Utils";

const UploadResultDisplay = (props) => {
  const res = props.result;
  const [vis, setVis] = useState(res.payload || null);
  const {visCache, setVisCache} = useContext(VisualCache);
  return (
    <div id="resource-upload-result">
      {props.result.success ? (
        <>
          <h4 className="text-success text-center">Success</h4>
          {vis ? (
            <UserInfo.Consumer>
              {({ userInfo, setUserInfo }) => {
                return (
                  <AvailableTags.Consumer>
                    {({ availableTags, setAvailableTags }) => (
                      <VisCard
                        vis={vis}
                        isOrg={props.isOrg}
                        canEdit={
                          userInfo &&
                          (!props.isOrg ||
                            (userInfo.organization_permissions &&
                              userInfo.organization_permissions.edit_visuals))
                        }
                        canEmail={(userInfo||{}).tier === "unlimited"}
                        canDelete={
                          userInfo &&
                          (!props.isOrg ||
                            (userInfo.organization_permissions &&
                              userInfo.organization_permissions.remove_visuals))
                        }
                        addCopyToOrg={
                          userInfo &&
                          !props.isOrg &&
                          userInfo.organization_permissions &&
                          userInfo.organization_permissions.add_visuals
                        }
                        showModal={true}
                        onVisEdit={(
                          nvis,
                          newTitle,
                          newDesc,
                          thumbData,
                          newHasShare,
                          other = { tags: [] }
                        ) => {
                          let temp = shallowCopy(vis);
                          const tempVisCache = shallowCopy(visCache);
                          temp.title = newTitle;
                          temp.description = newDesc;
                          if (thumbData) {
                            temp.thumbnail = thumbData;
                          }
                          temp.has_share_dialog = newHasShare;
                          const newObj = {};
                          if (other) {
                            for (const [key, value] of Object.entries(other)) {
                              const snakeKey = key
                                .split(/(?=[A-Z])/)
                                .map((e) => e.toLowerCase())
                                .join("_");
                              newObj[snakeKey] = value;
                            }
                            newObj.tags = availableTags.filter((t) =>
                              other.tags.some((slug) => t.slug === slug)
                            );
                          }
                          const out = Object.assign(temp, newObj);
                          tempVisCache[out.id] = out;
                          setVisCache(tempVisCache);
                          setVis(out);
                        }}
                        onVisDelete={(vis) => {
                          const tempVisCache = shallowCopy(visCache);
                          delete tempVisCache[vis.id];
                          setVisCache(tempVisCache);
                          setVis(null);
                        }}
                        hideFavorite={
                          !userInfo ||
                          !(
                            userInfo.organization_permissions &&
                            userInfo.organization_permissions.edit_visuals
                          )
                        }
                        doubleExpire={!userInfo}
                      />
                    )}
                  </AvailableTags.Consumer>
                );
              }}
            </UserInfo.Consumer>
          ) : (
            <h6 className="text-success text-center">Visual Deleted</h6>
          )}
        </>
      ) : (
        <h4 className="text-danger text-center">{res.payload}</h4>
      )}
    </div>
  );
};

export default UploadResultDisplay;

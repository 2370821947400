import { useState, useEffect, useContext } from "react";
import { Modal, Spinner, Form, Button, Row, Col } from "react-bootstrap";
import {
  Elements,
  PaymentElement,
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StripePromise } from "../helper/Context";
import { stripeElementsAppearance } from "../helper/Utils";
import { APIGetBillingDetails } from "../helper/APIFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import PaySetupCompleteComponent from "./PaySetupCompleteComponent";
import * as Yup from "yup";
const statusPhase = {
  initial: 0,
  requiresPassword: 1,
  invalidPassword: 2,
  retrievedDetails: 3,
  error: 4,
};

const DisplayDetails = (props) => {
  const [editMode, setEditMode] = useState(false);
  const bd = props.paymentDetails.billingDetails;
  const [email, setEmail] = useState(bd.email || "");
  const validateEmail = () => {
    if (!email) {
      return "Your email is incomplete.";
    }
    if (!Yup.string().email().isValidSync(email)) {
      return "Your email is invalid.";
    } else {
      return null;
    }
  };

  const [emailError, setEmailError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [successIntent, setSuccessIntent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const emailError = validateEmail();
    if (emailError) {
      setEmailError(emailError);
      return;
    }
    if (!stripe || !elements) {
      return;
    }
    setSubmitting(true);

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment/setup`,
        payment_method_data: {
          billing_details: {
            email: email,
          },
        },
      },
      redirect: "if_required",
    });
    if (error) {
      setErrorMessage(error.message);
    } else {
      setSuccessIntent(setupIntent);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    setErrorMessage("");
  }, [editMode]);

  return (
    <div style={{ position: "relative" }}>
      {successIntent ? (
        <Modal
          show
          onHide={() => {
            if (successIntent.status == "succeeded") {
              props.onUpdate();
            }
            setSuccessIntent(null);
            setEditMode(false);
          }}
        >
          <Modal.Header closeButton closeVariant="white">
            Payment Update Result
          </Modal.Header>
          <Modal.Body>
            <PaySetupCompleteComponent setupIntent={successIntent} />
          </Modal.Body>
        </Modal>
      ) : null}
      <div style={{ position: "absolute", top: "-2.5rem", right: "1rem" }}>
        <FontAwesomeIcon
          icon={faPencil}
          className="icon-btnlike"
          size="xl"
          aria-label="edit"
          role="button"
          tabIndex="0"
          title={`Edit Billing Details`}
          onClick={(event) => {
            setEditMode(true);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setEditMode(true);
            }
          }}
        />
      </div>
      {errorMessage && editMode ? (
        <div className="text-danger TitleText">{errorMessage}</div>
      ) : null}
      <Row>
        <Col xs={12} md={6} className="mb-4">
          <h5 className="TitleText">Billing Details</h5>
          {editMode ? (
            <>
              <AddressElement
                options={{
                  mode: "billing",
                  defaultValues: bd,
                }}
              />
              <Form.Group style={{ paddingRight: "15px" }}>
                <Form.Label>Contact email</Form.Label>
                <Form.Control
                  placeholder="Email for payment confirmation"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setEmailError(null);
                  }}
                  onBlur={() => setEmailError(validateEmail())}
                  isInvalid={emailError}
                />
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          ) : Object.values(bd).some((e) => e) ? (
            <>
              {bd.name ? <div>{bd.name}</div> : null}
              {bd.address.line1 ? <div>{bd.address.line1}</div> : null}
              {bd.address.line2 ? <div>{bd.address.line2}</div> : null}
              <div>
                {bd.address.city ? bd.address.city + ", " : null}
                {bd.address.state ? bd.address.state + ", " : null}
                {bd.address.postal_code ? bd.address.postal_code : null}
              </div>
              {bd.address.country ? <div>{bd.address.country}</div> : null}
              {bd.email ? <div>{bd.email}</div> : null}
            </>
          ) : (
            <p>No Billing Details</p>
          )}
        </Col>
        <Col xs={12} md={6}>
          <h5 className="TitleText">Payment Information</h5>
          <div>
            {editMode ? (
              <div className="mt-4">
                <PaymentElement options={{ mode: "setup" }} />
              </div>
            ) : (
              <>
                {props.paymentDetails.type == "cashapp" ? (
                  <h5 className="TitleText">Cash App</h5>
                ) : null}
                {props.paymentDetails.type == "card" ? (
                  <>
                    <h5 className="TitleText">Credit Card</h5>
                    <Row style={{ maxWidth: "25rem" }} className="mx-auto">
                      <Col className="text-start">
                        {props.paymentDetails.cardDetails.brand.toUpperCase()}
                      </Col>
                      <Col xs="auto" className="text-center">
                        **** ***** ****{" "}
                        {props.paymentDetails.cardDetails.numEnd.padStart(
                          4,
                          "*"
                        )}{" "}
                      </Col>
                      <Col className="text-end">
                        {props.paymentDetails.cardDetails.expMonth}/
                        {props.paymentDetails.cardDetails.expYear}
                      </Col>
                    </Row>
                  </>
                ) : null}
              </>
            )}
          </div>
        </Col>
      </Row>
      {editMode ? (
        <>
          <Button onClick={() => setEditMode(false)}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            disabled={submitting || !stripe || !elements}
          >
            Update
          </Button>
        </>
      ) : null}
    </div>
  );
};

const CurrentBillingDetails = (props) => {
  const [status, setStatus] = useState({
    phase: statusPhase.initial,
    data: null,
  });
  const [pass, setPass] = useState("");
  const [passMeta, setPassMeta] = useState({
    touched: false,
    error: "Required",
  });
  const [sending, setSending] = useState(false);
  const fetchDetails = () => {
    setSending(true);
    APIGetBillingDetails(
      (res) => {
        setSending(false);
        setStatus({ phase: statusPhase.retrievedDetails, data: res });
      },
      (err, httpStatus) => {
        setSending(false);
        if (httpStatus == 401) {
          setStatus({
            phase: pass
              ? statusPhase.invalidPassword
              : statusPhase.requiresPassword,
            data: err,
          });
        } else {
          setStatus({
            phase: statusPhase.error,
            data:
              "Unable to fetch payment details. Please refresh your page and try again.",
          });
        }
      },
      pass
    );
  };
  useEffect(fetchDetails, []);
  useEffect(() => {
    if (!pass) {
      setPassMeta({ touched: passMeta.touched, error: "Required" });
    } else if (pass.length < 8) {
      setPassMeta({ touched: passMeta.touched, error: "Too short" });
    } else if (pass.length > 72) {
      setPassMeta({ touched: passMeta.touched, error: "Too long" });
    } else {
      setPassMeta({ touched: passMeta.touched, error: null });
    }
  }, [pass]);
  return (
    <>
      {status.phase == statusPhase.initial ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="loading" className="text-secondary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : null}
      {status.phase == statusPhase.requiresPassword ||
      status.phase == statusPhase.invalidPassword ? (
        <Modal show={true}>
          <Modal.Header>
            <h3>Confirm Password</h3>
          </Modal.Header>
          <Modal.Body>
            {status.phase == statusPhase.invalidPassword ? (
              <h5 className="Title-Text text-danger">Invalid Password.</h5>
            ) : null}
            Please enter your password in order to view and edit your billing
            details.
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                fetchDetails();
              }}
            >
              <Form.Group controlId="formik-password" className="mb-3">
                <Form.Label>Enter your password to confirm:</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  value={pass}
                  disabled={sending}
                  isInvalid={passMeta.touched && passMeta.error}
                  onChange={(event) => setPass(event.target.value)}
                  onBlur={() =>
                    setPassMeta({ error: passMeta.error, touched: true })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {passMeta.error}
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                type="submit"
                className="mt-2"
                disabled={sending || passMeta.error}
              >
                {sending ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="login"
                    aria-hidden="true"
                  />
                ) : (
                  <>Confirm</>
                )}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      ) : null}
      {status.phase == statusPhase.retrievedDetails ? (
        <>
          <h4 className="TitleText">Payment Details</h4>
          <StripePromise.Consumer>
            {(stripePromise) => {
              return (
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret: status.data.setupIntentSecret,
                    appearance: stripeElementsAppearance,
                  }}
                >
                  <DisplayDetails
                    paymentDetails={status.data}
                    onUpdate={() => {
                      setStatus({ phase: statusPhase.initial, data: null });
                      setTimeout(fetchDetails, 10000);
                    }}
                  />
                </Elements>
              );
            }}
          </StripePromise.Consumer>
        </>
      ) : null}
      {status.phase == statusPhase.error ? (
        <h4 className="TitleText text-danger">{status.data}</h4>
      ) : null}
    </>
  );
};

export default CurrentBillingDetails;

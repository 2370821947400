const expandMenu = () => {
  if (
    window.getComputedStyle(document.getElementById("responsive-navbar-nav"))
      .display === "none"
  ) {
    document.getElementsByClassName("navbar-toggler")[0].click();
  }
  if (
    !document.getElementsByClassName("dropdown")[0].classList.contains("show")
  ) {
    document.getElementsByClassName("dropdown-toggle")[0].click();
  }
};

const JoyrideBtnStyle = {
  backgroundColor: "rgb(255, 0, 68)",
  border: "0px",
  borderRadius: "4px",
  color: "rgb(255, 255, 255)",
  cursor: "pointer",
  fontSize: "16px",
  lineHeight: 1,
  padding: "8px",
  appearance: "none",
  marginLeft: "5px",
  marginRight: "5px",
};
const setInputText = (id, newVal) => {
  const elem = document.getElementById(id);
  elem.value = newVal;
  elem._valueTracker.setValue("");
  elem.dispatchEvent(new Event("input", { bubbles: true }));
};

const introTour = {
  hideCloseButton: true,
  disableOverlayClose: true,
  showProgress: false,
  hideBackButton: true,
  showSkipButton: false,

  steps: [
    {
      target: "#main-body",
      content:
        "Welcome to Snorkle! Let's get you started by adding your first visual.",
      disableBeacon: true,
    },
    {
      target: ".id_new-visual-nav-link",
      content:
        "This will take you to the page where you can add visuals to your account.",
      preCB: expandMenu,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableBeacon: true,
    },
    {
      target: "#add-image-vis-btn",
      content:
        "Here you can create visuals from local files. We take common image formats and some clinical files.",
      placement: "top",
      dest: "/dashboard/upload",
      disableBeacon: true,
    },
    {
      target: "#search-vis-btn",
      content:
        "Here you can search for data to visualize in Snorkle. You can search protein structures, 3D chemical compound model, and even some astronomical objects by name. There is also a search bar on the home page if you are logged in.",
      placement: "top",
      dest: "/dashboard/upload",
      disableBeacon: true,
    },
    {
      target: "#add-web-vis-btn",
      content:
        "Here you can add visuals from web resources if you know their URL or ID. This is what we will be doing in this tutorial.",
      dest: "/dashboard/upload",
      disableBeacon: true,
    },
    {
      target: "#upload-web-resource",
      content: (
        <>
          <div>
            Here you can specify the web resource that you want to create a
            visual of. This includes creating personal copies of existing
            visuals. Choose a visual to get started
          </div>
          {/* <button id="intro-tour-back-upload" style={JoyrideBtnStyle}>
          Back
        </button> */}
          <button id="intro-tour-btn-mri" style={JoyrideBtnStyle}>
            Brain MRI
          </button>
          <button id="intro-tour-btn-lungs" style={JoyrideBtnStyle}>
            3D model of lungs
          </button>
          <button id="intro-tour-btn-dna" style={JoyrideBtnStyle}>
            DNA base pair in a rhombohedral lattice
          </button>
        </>
      ),
      optionButtons: [
        // {
        //   id: "intro-tour-back-upload",
        //   callback: () => -1,
        // },
        {
          id: "intro-tour-btn-mri",
          callback: () => {
            setInputText(
              "upload-web-resource",
              "https://vis.snorkle.io/tJxkTPRP9sOG1SAy97rjJcQ_DObcO8JasyXECGrSHNM=/viewer.html"
            );
            return +1;
          },
        },
        {
          id: "intro-tour-btn-lungs",
          callback: () => {
            setInputText(
              "upload-web-resource",
              "https://vis.snorkle.io/LeZkRsr0eTW2Hwed8r62u8v1tL4Lbufp-zkhs59smpE=.html"
            );
            return +1;
          },
        },
        {
          id: "intro-tour-btn-dna",
          callback: () => {
            setInputText(
              "upload-web-resource",
              "https://vis.snorkle.io/NkBVzrYG1BqffvdblNcva6MnmvWeHm77A6kZepEPZ84=.html"
            );
            return +1;
          },
        },
      ],
      dest: "/dashboard/upload/web",
      hideFooter: true,
      disableBeacon: true,
    },
    {
      target: "#upload-web-title",
      content:
        "You can specify a title for your new visual here. If you do not provide one, we will fetch a suitable title from the chosen resource. You CANNOT specify a new title if you are copying a visual, but can edit the title once the visual has been copied.",
      dest: "/dashboard/upload/web",
      disableBeacon: true,
    },
    {
      target: "#upload-web-description",
      content:
        "You can also add an optional description for your new visual. Note that descriptions of copied visuals must be edited after the copy is created.",
      disableBeacon: true,
      dest: "/dashboard/upload/web",
    },
    {
      target: "#upload-web-submit-btn",
      content: "Create your copy of the sample visual.",
      disableBeacon: true,
      postCB: () => {
        document.getElementById("upload-web-submit-btn").click();
      },
      dest: "/dashboard/upload/web",
    },
    {
      target: "#upload-web-processing",
      content:
        "Your copy of the visual is being created. Please wait a moment.",
      hideFooter: true,
      disableBeacon: true,
      autoRouting: {
        function: () => {
          if (document.querySelector("#resource-upload-result .text-success")) {
            return +2;
          }
          if (
            !!document.querySelector("#resource-upload-result .text-danger")
          ) {
            return +1;
          }
          return null;
        },
        retryInterval: 100,
      },
      dest: "/dashboard/upload/web",
    },
    {
      target: "#resource-upload-result",
      content:
        "There was an error creating your visual. You can always take this tour again by visiting the Tutorials page.",
      isTerminal: true,
      disableBeacon: true,
      dest: "/dashboard/upload/web",
    },
    {
      target: ".modal-content",
      content: "Congrats! Your visual has been uploaded successfully! This is what it looks like.",
      disableBeacon: true,
      dest: "/dashboard/upload/web",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      postCB: ()=>{
        document.querySelectorAll(".modal-header .btn-close").forEach(e => e.click());
      }
    },
    {
      target: ".id_my-visuals-nav-link",
      preCB: expandMenu,
      content: "You can see your visual portfolio here.",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      dest: "/dashboard/myvisuals",
      target: "#main-body",
      content:
        "Your visuals are being fetched by the back end. Please wait a moment.",
      autoRouting: {
        function: () => {
          if(document.querySelector(".vis-card")){
            return +2;
          }
          if (document.getElementById("portfolio-fetch-error")){
            return +1;
          }
          return null;
        },
        retryInterval: 100,
      },
      hideFooter: true,
    },
    {
      dest: "/dashboard/myvisuals",
      target: "#portfolio-fetch-error",
      content: "There was an error fetching your visual. You can always take this tour again by visiting the Tutorials page.",
      isTerminal: true,
    },
    {
      dest: "/dashboard/myvisuals",
      target: ".last-vis .vis-card",
      content: "This is your visual.",
    },
    {
      dest: "/dashboard/myvisuals",
      target: ".last-vis .vis-card .vis-card-qrcode",
      content:
        "You can share this QR Code so that other people can view it on their mobile devices.",
    },
    {
      dest: "/dashboard/myvisuals",
      target: ".last-vis .vis-card .fa-pencil",
      content:
        "Here you can open a dialog that will allow you to edit the visual. You can change the title, description, and thumbnail of your visual. Other options may be available for you particular visual.",
    },
    {
      dest: "/dashboard/myvisuals",
      target: ".last-vis .vis-card .fa-share-from-square",
      content:
        "This opens a dialog that lets you easily share this visual to Twitter and Facebook. You can also copy code to embed it on your own website.",
    },
    {
      dest: "/dashboard/myvisuals",
      target: ".last-vis .vis-card .fa-trash-can",
      content:
        "This button allows you to delete the relevant visual if you so choose.",
    },
    {
      dest: "/dashboard/myvisuals",
      target: ".last-vis .vis-card .fa-trash-can",
      content: "",
      autoRouting: {
        function: () => {
          return document.querySelector(".fa-camera") ? +2 : +1;
        },
        retryInterval: 1,
      },
      hideFooter: true,
    },
    {
      dest: "/dashboard/myvisuals",
      target: ".last-vis .vis-card",
      content:
        "Some visuals will have a camera icon in the bottom left corner. This icon allows you to take snapshots directly from your visual. You can save these snapshots to your computer or make them the new thumbnail of your visual.",
      isTerminal: true
    },
    {
      dest: "/dashboard/myvisuals",
      target: ".fa-camera",
      content:
      "Some visuals will have a camera icon in the bottom left corner like this. This icon allows you to take snapshots directly from your visual. You can save these snapshots to your computer or make them the new thumbnail of your visual.",
    }
  ],
};
export default introTour;

import { useState, useEffect } from "react";
import { Button, Row, Col, Table, Form, Modal, Spinner } from "react-bootstrap";
import { UserInfo, StripePromise } from "../helper/Context";
import {
  canSetPersonalBranding,
  getVisualLifetimeHrs,
  getVisualLimit,
  getMonthlyPriceUSD,
  getAnnualPriceUSD,
  getMaxUploadSizeMB,
  canRestoreVisuals,
  canDownloadVisuals,
  getPresentationLimit,
} from "../helper/BusinessLogic";
import {
  Elements,
  PaymentElement,
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  contactUs,
  stripeElementsAppearance,
  shallowCopy,
} from "../helper/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faCheck } from "@fortawesome/free-solid-svg-icons";
import { APIRequestSubscriptionModification } from "../helper/APIFunctions";
import LoginModal from "../components/LoginModal";
import PaymentCompleteComponent from "../components/PaymentCompleteComponent";
import {useNavigate} from "react-router-dom";

const formatter = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "numeric",
  minute: "2-digit",
});

const getVisCountText = (tier) => {
  const l = getVisualLimit(tier, false);
  return l ? `${l}` : "Unlimited";
};

const getVisTimeText = (tier) => {
  const l = getVisualLifetimeHrs(tier, false);
  return l ? `${l > 48 ? `${l / 24} days` : `${l} hours`}` : "Permanent";
};

const getPersonalBrandingText = (tier) => {
  const v = canSetPersonalBranding(tier);
  return (
    <FontAwesomeIcon
      size="2xl"
      className={"text-center " + (v ? "text-success" : "text-danger")}
      icon={v ? faCheck : faX}
      title={v ? "Can set personal branding" : "Cannot set personal branding"}
    />
  );
};

const getFileUploadSizeText = (tier) => {
  return `${getMaxUploadSizeMB(tier)} MB`;
};

const getRecoverText = (tier) => {
  const v = canRestoreVisuals(tier);
  return (
    <FontAwesomeIcon
      size="2xl"
      className={"text-center " + (v ? "text-success" : "text-danger")}
      icon={v ? faCheck : faX}
      title={
        v ? "Can restore deleted visuals" : "Cannot restore deleted visuals"
      }
    />
  );
};

const getDownloadText = (tier) => {
  const v = canDownloadVisuals(tier);
  return (
    <FontAwesomeIcon
      size="2xl"
      className={"text-center " + (v ? "text-success" : "text-danger")}
      icon={v ? faCheck : faX}
      title={
        v
          ? "Can download standalone visuals"
          : "Cannot download standalone visuals"
      }
    />
  );
};

const getPresentationText = (tier) => {
  const l = getPresentationLimit(tier);
  return (
    <>
      <FontAwesomeIcon
        size="2xl"
        className={"text-center " + (l !== 0 ? "text-success" : "text-danger")}
        icon={l !== 0 ? faCheck : faX}
        title={l !== 0 ? "Can create slide decks" : "Cannot create slide decks"}
      />
      {l !== 0 ? (
        <div>{`(${l !== null ? l : "Unlimited"} deck${
          l != 1 ? "s" : ""
        })`}</div>
      ) : null}
    </>
  );
};

const getFreeButton = (userInfo, disabled, onClickLogin, onClickDowngrade) => {
  if (!userInfo) {
    return <Button onClick={onClickLogin}>Register to get started!</Button>;
  }
  if (userInfo.tier == "free") {
    return <Button disabled>Your current plan</Button>;
  }
  return (
    <Button onClick={onClickDowngrade} disabled={disabled}>
      Downgrade to free
    </Button>
  );
};
const getPremiumButton = (
  userInfo,
  disabled,
  billMonthly,
  onClickLogin,
  onClickFromOther,
  onClickFromFree
) => {
  if (!userInfo) {
    return <Button onClick={onClickLogin}>Register to try for free!</Button>;
  }
  const userBillMonthly = userInfo.billing_period == "month";
  if (userInfo.tier == "premium" && billMonthly == userBillMonthly) {
    return (
      <>
        <Button disabled>Your current plan</Button>
        {userInfo.free_trial_ends &&
        userInfo.free_trial_ends * 1000 > Date.now() ? (
          <div>
            Your trial ends {formatter.format(userInfo.free_trial_ends * 1000)}
          </div>
        ) : null}
      </>
    );
  }
  if (userInfo.tier == "free") {
    return (
      <Button onClick={onClickFromFree} disabled={!!disabled}>
        {userInfo.used_free_trial
          ? `Upgrade to premium (billed ${
              billMonthly ? "monthly" : "annually"
            })`
          : "Try for free for 14 days!"}
      </Button>
    );
  }
  return (
    <Button onClick={onClickFromOther} disabled={!!disabled}>
      Switch to to premium (billed {billMonthly ? "monthly" : "annually"})
    </Button>
  );
};

const getUnlimitedButton = (
  userInfo,
  disabled,
  billMonthly,
  onClickLogin,
  onClickFromOther,
  onClickFromFree
) => {
  if (!userInfo) {
    return <Button onClick={onClickLogin}>Register to try for free!</Button>;
  }
  const userBillMonthly = userInfo.billing_period == "month";
  if (userInfo.tier == "free") {
    return (
      <Button onClick={onClickFromFree} disabled={disabled}>
        {userInfo.used_free_trial
          ? `Upgrade to unlimited (billed ${
              billMonthly ? "monthly" : "annually"
            })`
          : "Try for free for 14 days!"}
      </Button>
    );
  }
  if (userInfo.tier == "unlimited" && billMonthly == userBillMonthly) {
    return (
      <>
        <Button disabled>Your current plan</Button>
        {userInfo.free_trial_ends &&
        userInfo.free_trial_ends * 1000 > Date.now() ? (
          <div>
            Your trial ends {formatter.format(userInfo.free_trial_ends * 1000)}
          </div>
        ) : null}
      </>
    );
  }
  return (
    <Button onClick={onClickFromOther} disabled={disabled}>
      Switch to unlimited (billed {billMonthly ? "monthly" : "annually"})
    </Button>
  );
};

const ConfirmModal = (props) => {
  const [sendingCall, setSendingCall] = useState(false);
  const [result, setResult] = useState({ error: null, success: null });
  const passSchema = Yup.object().shape({
    password: Yup.string()
      .required("Required")
      .min(8, "Too short")
      .max(72, "Too long"),
  });
  const get_tier_text = (tier) => {
    return tier == "unlimited" ? "an unlimited" : `a ${tier}`;
  };
  const get_bp_text = (isMonthly) => {
    return isMonthly ? "monthly" : "annually";
  };
  const [callError, setCallError] = useState(null);
  useEffect(() => {
    if (result.success && result.success.timestamp) {
      const ui = shallowCopy(props.userInfo);
      ui.pending_change = {
        timestamp: result.success.timestamp,
        new_tier: props.newPlanTier,
        billing_period: props.newPlanIsMonthly ? "month" : "year",
      };
      props.setUserInfo(ui);
    }
  }, [result]);
  const requiresPayment = () => {
    const sameTier = props.newPlanTier == props.oldPlanTier;
    const upgradeTier =
      (props.newPlanTier == "unlimited" && props.oldPlanTier == "premium") ||
      props.oldPlanTier == "free";
    const upgradePeriod = !props.newPlanIsMonthly && props.oldPlanIsMonthly;
    return (
      !props.newPlanTier != "free" &&
      (upgradeTier || (sameTier && upgradePeriod))
    );
  };
  const [show, setShow] = useState(true);
  const onHide = () => {
    setShow(false);
    setTimeout(props.onHide, 500);
  };
  const [forceOpen, setForceOpen] = useState(false);
  return (
    <Modal onHide={() => (forceOpen ? null : onHide())} show={show}>
      <Modal.Header closeButton closeVariant="white">
        <h3>Modify Current Subscription</h3>
      </Modal.Header>
      <Modal.Body>
        {result.error ? (
          <h5 className="text-center text-danger">{result.error}</h5>
        ) : null}
        {result.success ? (
          <>
            <h4 className="text-center text-success">Success</h4>
            <p>
              Your subscription plan has been updated.
              {result.success.timestamp
                ? `Your new ${
                    props.newPlanTier
                  } plan will take effect on ${new Intl.DateTimeFormat(
                    undefined,
                    {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                      hour: "numeric",
                      minute: "2-digit",
                    }
                  ).format(result.success.timestamp * 1000)}.`
                : ` You have been ${
                    result.success.total < 0 ? "credited" : "charged"
                  } ${new Intl.NumberFormat(undefined, {
                    style: "currency",
                    currency: result.success.currency.toUpperCase(),
                    currencyDisplay: "symbol",
                  }).format(
                    Math.abs(result.success.total / 100)
                  )} for activation of your new ${
                    props.newPlanTier
                  } plan. Please refresh your page in a few minutes to see your new plan. If you do not see your new plan active after 15 minutes, please contact us.`}
            </p>
          </>
        ) : (
          <>
            <p>
              By proceeding you will be switching from{" "}
              {get_tier_text(props.oldPlanTier)} plan{" "}
              {props.oldPlanTier != "free" ? (
                <> billed {get_bp_text(props.oldPlanIsMonthly)}</>
              ) : null}{" "}
              to {get_tier_text(props.newPlanTier)} plan
              {props.newPlanTier != "free" ? (
                <> billed {get_bp_text(props.newPlanIsMonthly)}</>
              ) : null}
              .
            </p>
            {requiresPayment() && !props.oldPlanTier == "free" ? (
              props.oldPlanIsMonthly != props.newPlanIsMonthly ? (
                <p>
                  You will immediately be charged the difference between your
                  new plan's price and the prorated value of the remainder of
                  your current plan. Your subscription billing period will also
                  be changed and your next payment will be due 1{" "}
                  {props.newPlanIsMonthly ? "month" : "year"} from today.
                </p>
              ) : (
                <p>
                  You will immediately be charged the difference between your
                  the prorated value of the remainder of your current plan, and
                  your new plan for the same duration. Your subscription billing
                  period will remain unchanged, and you will pay the full price
                  for your new plan starting with your next billing cycle.
                </p>
              )
            ) : null}
            {requiresPayment() && props.oldPlanTier == "free" ? (
              <p>
                The payment method on file will be charged the full price of
                your new subscription,{" "}
                {new Intl.NumberFormat(undefined, {
                  style: "currency",
                  currency: "USD",
                  currencyDisplay: "symbol",
                }).format(
                  props.newPlanIsMonthly
                    ? getMonthlyPriceUSD(props.newPlanTier)
                    : getAnnualPriceUSD(props.newPlanTier)
                )}
                . This amount will be charged{" "}
                {get_bp_text(props.newPlanIsMonthly)} until your subscription is
                canceled or modified.
              </p>
            ) : null}
            {!requiresPayment() ? (
              <p>
                This change will take place at the end of your current billing
                period.
              </p>
            ) : null}
            <Formik
              initialValues={{
                password: "",
                comment: "",
                reason: "",
              }}
              validationSchema={passSchema}
              onSubmit={(values, actions) => {
                setSendingCall(true);
                setForceOpen(true);
                setResult({ error: null, success: null });
                APIRequestSubscriptionModification(
                  props.newPlanTier,
                  props.newPlanIsMonthly,
                  (result) => {
                    setForceOpen(false);
                    setSendingCall(false);
                    setResult({ error: null, success: result });
                  },
                  (error) => {
                    setForceOpen(false);
                    setSendingCall(false);
                    setResult({ error: error, success: null });
                  },
                  values.password,
                  { cancelReason: values.reason, cancelComment: values.comment }
                ).then(actions.setSubmitting(false));
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                isSubmitting,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <p className="text-danger text-center">{errors.global}</p>
                  <Form.Group controlId="formik-password" className="mb-3">
                    <Form.Label>Enter your password to confirm:</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      autoComplete="current-password"
                      value={values.password}
                      disabled={isSubmitting || sendingCall || result.success}
                      isInvalid={touched.password && errors.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {props.newPlanTier == "free" ? (
                    <>
                      <Form.Group controlId="formik-reason" className="mb-3">
                        <Form.Label>
                          Reason For Canceling (optional):
                        </Form.Label>
                        <Form.Select
                          name="reason"
                          value={values.reason}
                          disabled={
                            isSubmitting || sendingCall || result.success
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">Unspecified</option>
                          {cancelReasons.map((r) => {
                            return (
                              <option key={r[0]} value={r[0]}>
                                {r[1]}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group controlId="formik-comment" className="mb-3">
                        <Form.Label>Feedback (optional):</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="comment"
                          rows={3}
                          value={values.comment}
                          disabled={
                            isSubmitting || sendingCall || result.success
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </>
                  ) : null}
                  <Button
                    type="submit"
                    className="mt-2"
                    disabled={isSubmitting || sendingCall || result.success}
                  >
                    {isSubmitting || sendingCall ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="login"
                        aria-hidden="true"
                      />
                    ) : (
                      <>Confirm</>
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
const cancelReasons = [
  ["too_expensive", "It’s too expensive"],
  ["missing_features", "Some features are missing"],
  ["switched_service", "I’m switching to a different service"],
  ["unused", "I don’t use the service enough"],
  ["customer_service", "Customer service was less than expected"],
  ["too_complex", "Ease of use was less than expected"],
  ["low_quality", "Quality was less than expected"],
  ["other", "Other reason"],
];

const StripeCheckout = (props) => {
  const [email, setEmail] = useState(
    props.paymentDetails.defaultEmail || props.userInfo.email
  );
  const validateEmail = () => {
    if (!email) {
      return "Your email is incomplete.";
    }
    if (!Yup.string().email().isValidSync(email)) {
      return "Your email is invalid.";
    } else {
      return null;
    }
  };
  const [resultPI, setResultPI] = useState(null);
  const [forceModal, setForceModal] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [emailError, setEmailError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const emailError = validateEmail();
    if (emailError) {
      setEmailError(emailError);
      return;
    }
    if (!stripe || !elements) {
      return;
    }
    setSubmitting(true);
    let isPayment = !!props.paymentDetails.stripePaymentSecret;
    const { error, paymentIntent, setupIntent } = await (isPayment
      ? stripe.confirmPayment
      : stripe.confirmSetup)({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment/${props.paymentDetails.pendingTier}`,
        payment_method_data: {
          billing_details: {
            email: email,
          },
        },
      },
      redirect: "if_required",
    });
    setSubmitting(false);
    if (error) {
      setErrorMessage(error.message);
    } else {
      setResultPI(paymentIntent || setupIntent);
    }
  };
  return (
    <>
      {resultPI ? (
        <Modal
          show={true}
          onHide={() => {
            if (!forceModal) {
              props.onCheckout();
            }
          }}
        >
          <Modal.Header closeButton={!forceModal} closeVariant="white">
            {props.paymentDetails.stripePaymentSecret
              ? "Payment"
              : "Subscription"}{" "}
            Result
          </Modal.Header>
          <Modal.Body>
            <PaymentCompleteComponent
              paymentIntent={resultPI}
              newTier={props.paymentDetails.pendingTier}
              onTierUpdate={() => setForceModal(false)}
            />
          </Modal.Body>
        </Modal>
      ) : null}
      <form onSubmit={handleSubmit}>
        <Row>
          {props.paymentDetails.requireAddress ? (
            <Col xs={12} md={6}>
              <h5 className="TitleText">Billing Details</h5>
              <AddressElement
                options={{
                  mode: "billing",
                  defaultValues: {
                    name:
                      props.paymentDetails.defaultName || props.userInfo.name,
                  },
                }}
              />
              <Form.Group style={{ paddingRight: "15px" }}>
                <Form.Label>Contact email</Form.Label>
                <Form.Control
                  placeholder="Email for payment confirmation"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setEmailError(null);
                  }}
                  onBlur={() => setEmailError(validateEmail())}
                  isInvalid={emailError}
                />
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          ) : null}
          <Col xs={12} md={props.paymentDetails.requireAddress ? 6 : 12}>
            <h5 className="TitleText">Payment Information</h5>
            <div className="mt-4">
              <PaymentElement options={{ mode: "setup" }} />
            </div>
          </Col>
        </Row>
        <div className="mt-3">
          <ul>
            {(props.paymentDetails.invoiceDescription || []).map((d, i) => {
              return (
                <li key={i}>
                  <Row>
                    <Col xs="auto">{d.description}</Col>
                    <Col xs="auto" className="ms-auto">
                      {new Intl.NumberFormat(undefined, {
                        style: "currency",
                        currency: d.currency.toUpperCase(),
                        currencyDisplay: "symbol",
                      }).format(d.price / 100)}
                    </Col>
                  </Row>
                </li>
              );
            })}
            {props.paymentDetails.fromBalance ? (
              <li>
                <Row>
                  <Col xs="auto">
                    Applied Account Credit (will be returned after 24 hours if
                    payment is not completed)
                  </Col>
                  <Col xs="auto" className="ms-auto">
                    {new Intl.NumberFormat(undefined, {
                      style: "currency",
                      currency: props.paymentDetails.currency.toUpperCase(),
                      currencyDisplay: "symbol",
                    }).format(props.paymentDetails.fromBalance / 100)}
                  </Col>
                </Row>
              </li>
            ) : null}
          </ul>
        </div>
        {errorMessage && <div className="text-danger">{errorMessage}</div>}
        <Button
          type="submit"
          disabled={
            !stripe || !elements || submitting || (resultPI && forceModal)
          }
          className="w-100"
        >
          {submitting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="login"
              aria-hidden="true"
            />
          ) : (
            <>
              {props.paymentDetails.price ? (
                <>
                  Pay{" "}
                  {new Intl.NumberFormat(undefined, {
                    style: "currency",
                    currency: props.paymentDetails.currency,
                    currencyDisplay: "symbol",
                  }).format(props.paymentDetails.price / 100)}
                </>
              ) : null}
              {props.paymentDetails.nextChargeDays ? (
                <>
                  You will be charged{" "}
                  {new Intl.NumberFormat(undefined, {
                    style: "currency",
                    currency: props.paymentDetails.currency,
                    currencyDisplay: "symbol",
                  }).format(props.paymentDetails.pendingPrice / 100)}{" "}
                  when the {props.paymentDetails.nextChargeDays} day trial has
                  elapsed
                </>
              ) : null}
            </>
          )}
        </Button>
      </form>
    </>
  );
};

const PricingPage = (props) => {
  const tiers = ["free", "premium", "unlimited"];
  const getPriceText = (tier) => {
    const pm = getMonthlyPriceUSD(tier);
    const pa = getAnnualPriceUSD(tier);
    const formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
    });
    return pm ? (
      billMonthly ? (
        <div>
          {formatter.format(pm)} / month
          <div className="text-bold">
            (Save{" "}
            {new Intl.NumberFormat(undefined, {
              style: "percent",
            }).format(1 - pa / (pm * 12))}{" "}
            with annual billing)
          </div>
        </div>
      ) : (
        <div>
          {formatter.format(pa / 12)} / month
          {/* <div className="text-bold">
            <i>Best deal!</i>
          </div> */}
        </div>
      )
    ) : (
      "Free"
    );
  };

  const iter = [
    [
      "Create interactive visuals from any field of science and medicine, no app needed",
      (tier) => (
        <FontAwesomeIcon
          size="2xl"
          className="text-center text-success"
          icon={faCheck}
          title="Can create visuals"
        />
      ),
    ],
    [
      "Share interactive visuals and slide decks through QR, links and embed codes",
      (tier) => {
        return (
          <>
            {" "}
            <FontAwesomeIcon
              size="2xl"
              className="text-center text-success"
              icon={faCheck}
              title="Can share visuals"
            />
            {tier == "unlimited" ? (
              <div>Email sharing also included</div>
            ) : null}
          </>
        );
      },
    ],
    [
      "Search the universe and find 3D models and other interactive visuals (Beta)",
      () => (
        <FontAwesomeIcon
          size="2xl"
          className="text-center text-success"
          icon={faCheck}
          title="Search visuals"
        />
      ),
    ],
    [
      "Create interactive, 3D embeddable visuals of molecules and proteins",
      () => (
        <FontAwesomeIcon
          size="2xl"
          className="text-center text-success"
          icon={faCheck}
          title="Create molecule and protein visuals"
        />
      ),
    ],
    [
      "Permanently embed interactive visuals in your website",
      (tier) => (
        <FontAwesomeIcon
          size="2xl"
          className={
            "text-center " + (tier == "free" ? "text-danger" : "text-success")
          }
          icon={tier == "free" ? faX : faCheck}
          title={(tier != "free" ? "Can" : "Cannot") + " embed your visuals"}
        />
      ),
    ],
    [
      "Create interactive slide decks using Snorkle Slides (Beta)",
      getPresentationText,
    ],
    ["Use your own logo to brand visuals", getPersonalBrandingText],
    [
      "Access premium bundles of 3D visuals for clinical specialties",
      (tier) => (
        <FontAwesomeIcon
          size="2xl"
          className={
            "text-center " +
            (tier != "unlimited" ? "text-danger" : "text-success")
          }
          icon={tier != "unlimited" ? faX : faCheck}
          title={
            (tier == "unlimited" ? "Can" : "Cannot") + " embed your visuals"
          }
        />
      ),
    ],
    ["Duration visuals remain live", getVisTimeText],
    ["Number of visuals kept live ", getVisCountText],
    ["Max file upload size", getFileUploadSizeText],
    ["Enable offline viewing", getDownloadText],
    ["Restore deleted visuals", getRecoverText],
    ["Price", getPriceText],
  ];
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [apiResult, setApiResult] = useState({
    sending: false,
    result: null,
    error: null,
  });
  const onRequiresPaymentMethodError = (err) => {
    setPaymentDetails(null);
    setApiResult({
      sending: false,
      result: null,
      error: err.msg || err.toString() || "Internal Server Error",
    });
  };
  const onRequiresPaymentMethodSuccess = (msg) => {
    setPaymentDetails(msg);
    setApiResult({ sending: false, result: "Success", error: null });
  };

  const onRequiresPaymentMethod = (tier) => {
    setApiResult({ sending: true, result: null, error: null });
    APIRequestSubscriptionModification(
      tier,
      billMonthly,
      onRequiresPaymentMethodSuccess,
      onRequiresPaymentMethodError
    );
  };
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [billMonthly, setBillMonthly] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(null);
  let navigate = useNavigate();

  return (
    <>
      <LoginModal
        isOpen={showLoginModal}
        onClose={() => {
          setShowLoginModal(false);
        }}
      />
      <UserInfo.Consumer>
        {({ userInfo, setUserInfo }) => {
          return (
            <>
              {showConfirmModal ? (
                <ConfirmModal
                  onHide={() => setShowConfirmModal(null)}
                  oldPlanIsMonthly={userInfo.billing_period == "month"}
                  oldPlanTier={userInfo.tier}
                  newPlanTier={showConfirmModal}
                  newPlanIsMonthly={billMonthly}
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                />
              ) : null}
              <div className="pricing-container">
                <h2 className="TitleText">Compare plans</h2>
                <p>
                  Snorkle provides a variety of plans to meet your needs. These
                  include tiers of use for individual accounts and group plans
                  for managing multiple users. {" "}
                </p>
                <div className="col-auto text-center">
                  <div>
                    <Form.Select
                      aria-label="Set billing period"
                      title="Set billing period"
                      value={billMonthly ? "month" : "year"}
                      onChange={(e) =>
                        setBillMonthly(e.target.value == "month")
                      }
                      style={{ width: "unset" }}
                      className="ms-auto me-auto"
                    >
                      {" "}
                      <option value="year">Annual Billing</option>
                      <option value="month">Monthly Billing</option>
                    </Form.Select>
                    {/* Monthly Billing
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={!billMonthly}
                      inline={true}
                      className="ms-3"
                      title={`Switch to ${
                        billMonthly ? "annual" : "monthly"
                      } billing`}
                      onChange={(e) => setBillMonthly(!e.target.checked)}
                      disabled={apiResult.sending || paymentDetails}
                    />
                    Annual Billing */}
                  </div>
                  <br></br>
                  <Table striped="columns" responsive bordered size="sm">
                    <thead>
                      <tr>
                        <th />
                        <th>Free</th>
                        <th>Premium</th>
                        <th>Unlimited (single user)</th>
                        <th>Group (multi-user)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td />
                        <td>
                          For exploring basic functionality (non-permanent
                          visuals)
                        </td>
                        <td>For educational use and basic website embedding</td>
                        <td>
                          Ideal for an independent researcher, scientist, or
                          clinician
                        </td>
                        <td>Perfect for a team, department, or practice</td>
                      </tr>
                      {iter.map((obj, index) => (
                        <tr key={obj[0]}>
                          <th>{obj[0]}</th>
                          {tiers.map((tier) => (
                            <td key={obj[0] + tier}>{obj[1](tier)}</td>
                          ))}
                          {index == 0 ? (
                            <td rowSpan={iter.length - 1}>
                              <p>
                                Collaboratively share, add, or edit visuals or
                                slide decks
                              </p>
                              <p>Manage multiple users</p>
                              <p>
                                Enable individual users to maintain their own
                                private content
                              </p>
                              <p>Manage organization-wide branding</p>
                              <p>
                                Dedicated assistance for onboarding and account
                                support
                              </p>
                              <p></p>
                            </td>
                          ) : null}
                          {obj[0] == "Price" ? <td>Custom pricing</td> : null}
                        </tr>
                      ))}
                      <tr>
                        <td />
                        <td>
                          {getFreeButton(
                            userInfo,
                            apiResult.sending || paymentDetails,
                            () => setShowLoginModal(true),
                            () => setShowConfirmModal("free")
                          )}
                        </td>
                        <td>
                          {getPremiumButton(
                            userInfo,
                            apiResult.sending || paymentDetails,
                            billMonthly,
                            () => setShowLoginModal(true),
                            () =>
                              userInfo.has_default_payment_method
                                ? setShowConfirmModal("premium")
                                : onRequiresPaymentMethod("premium"),
                            () =>
                              userInfo.has_default_payment_method
                                ? setShowConfirmModal("premium")
                                : onRequiresPaymentMethod("premium")
                          )}
                        </td>
                        <td>
                          {getUnlimitedButton(
                            userInfo,
                            apiResult.sending || paymentDetails,
                            billMonthly,
                            () => setShowLoginModal(true),
                            () =>
                              userInfo.has_default_payment_method
                                ? setShowConfirmModal("unlimited")
                                : onRequiresPaymentMethod("unlimited"),
                            () =>
                              userInfo.has_default_payment_method
                                ? setShowConfirmModal("unlimited")
                                : onRequiresPaymentMethod("unlimited")
                          )}
                        </td>
                        <td>
                          <Button onClick={()=>navigate("/contact")}>Contact us</Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  {userInfo && userInfo.pending_change ? (
                    <em>
                      You will transition to a{" "}
                      {userInfo.pending_change.new_tier} plan
                      {userInfo.pending_change.new_tier != "free"
                        ? userInfo.pending_change.billing_period == "month"
                          ? " billed monthly"
                          : "billed annually"
                        : ""}{" "}
                      starting on{" "}
                      {new Intl.DateTimeFormat(undefined, {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                        hour: "numeric",
                        minute: "2-digit",
                      }).format(userInfo.pending_change.timestamp * 1000)}
                    </em>
                  ) : null}
                </div>
                {apiResult.error ? (
                  <div className="text-center text-danger">
                    {apiResult.error}
                  </div>
                ) : null}
                {paymentDetails ? (
                  paymentDetails.stripePaymentSecret ||
                  paymentDetails.stripeSetupSecret ? (
                    <div>
                      <h4 className="TitleText">Payment Details</h4>
                      <StripePromise.Consumer>
                        {(stripePromise) => {
                          return (
                            <Elements
                              stripe={stripePromise}
                              options={{
                                clientSecret:
                                  paymentDetails.stripePaymentSecret ||
                                  paymentDetails.stripeSetupSecret,
                                appearance: stripeElementsAppearance,
                              }}
                            >
                              <StripeCheckout
                                userInfo={userInfo}
                                paymentDetails={paymentDetails}
                                onCheckout={() => setPaymentDetails(null)}
                              />
                            </Elements>
                          );
                        }}
                      </StripePromise.Consumer>
                    </div>
                  ) : (
                    <div className="text-center">
                      <h4 className="text-success">Success</h4>
                      Your {paymentDetails.pendingTier} plan has been paid by
                      credit on your account. Your current account credit is{" "}
                      {new Intl.NumberFormat(undefined, {
                        style: "currency",
                        currency: paymentDetails.currency.toUpperCase(),
                        currencyDisplay: "symbol",
                      }).format(-paymentDetails.endBalance / 100)}
                      .
                    </div>
                  )
                ) : null}
              </div>
            </>
          );
        }}
      </UserInfo.Consumer>
    </>
  );
};

export default PricingPage;

import { useContext, useState } from "react";
import { Bundles, UserInfo, StripePromise } from "../helper/Context.js";
import {
  Spinner,
  Carousel,
  Row,
  Col,
  Button,
  Image,
  Modal,
  Form,
} from "react-bootstrap";
import { APIGetBundlePurchasePI } from "../helper/APIFunctions.js";
import {
  Elements,
  PaymentElement,
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  stripeElementsAppearance,
  shallowCopy,
} from "../helper/Utils";
import PaymentCompleteComponent from "../components/PaymentCompleteComponent.js";

const StripeCheckout = (props) => {
  const [email, setEmail] = useState(
    props.paymentDetails.defaultEmail || props.userInfo.email
  );
  const validateEmail = () => {
    if (!email) {
      return "Your email is incomplete.";
    }
    if (!Yup.string().email().isValidSync(email)) {
      return "Your email is invalid.";
    } else {
      return null;
    }
  };
  const [resultPI, setResultPI] = useState(null);
  const [forceModal, setForceModal] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [emailError, setEmailError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const emailError = validateEmail();
    if (emailError) {
      setEmailError(emailError);
      return;
    }
    if (!stripe || !elements) {
      return;
    }
    setSubmitting(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment/bundles`,
        payment_method_data: {
          billing_details: {
            email: email,
          },
        },
      },
      redirect: "if_required",
    });
    setSubmitting(false);
    if (error) {
      if (!error.message.endsWith("is incomplete.")) {
        setErrorMessage(error.message);
      }
    } else {
      setResultPI(paymentIntent);
    }
  };
  return (
    <>
      {resultPI ? (
        <Modal
          show={true}
          onHide={() => {
            if (!forceModal) {
              props.onCheckout();
            }
          }}
        >
          <Modal.Header closeButton={!forceModal} closeVariant="white">
            Payment Result
          </Modal.Header>
          <Modal.Body>
            <PaymentCompleteComponent
              paymentIntent={resultPI}
              onSuccess={() => setForceModal(false)}
            />
          </Modal.Body>
        </Modal>
      ) : null}
      <form onSubmit={handleSubmit}>
        <Row>
          {props.paymentDetails.requireAddress ? (
            <Col xs={12} md={6}>
              <h5 className="TitleText">Billing Details</h5>
              <AddressElement
                options={{
                  mode: "billing",
                  defaultValues: {
                    name:
                      props.paymentDetails.defaultName || props.userInfo.name,
                  },
                }}
              />
              <Form.Group style={{ paddingRight: "15px" }}>
                <Form.Label>Contact email</Form.Label>
                <Form.Control
                  placeholder="Email for payment confirmation"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setEmailError(null);
                  }}
                  onBlur={() => setEmailError(validateEmail())}
                  isInvalid={emailError}
                />
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          ) : null}
          <Col xs={12} md={props.paymentDetails.requireAddress ? 6 : 12}>
            <h5 className="TitleText">Payment Information</h5>
            <div className="mt-4">
              <PaymentElement options={{ mode: "setup" }} />
            </div>
          </Col>
        </Row>
        <div className="mt-3">
          <ul>
            {props.paymentDetails.invoiceDescription.map((d, i) => {
              return (
                <li key={i}>
                  <Row>
                    <Col xs="auto">{d.description}</Col>
                    <Col xs="auto" className="ms-auto">
                      {new Intl.NumberFormat(undefined, {
                        style: "currency",
                        currency: d.currency.toUpperCase(),
                        currencyDisplay: "narrowSymbol",
                      }).format(d.price / 100)}
                    </Col>
                  </Row>
                </li>
              );
            })}
            {props.paymentDetails.fromBalance ? (
              <li>
                <Row>
                  <Col xs="auto">
                    Applied Account Credit (will be returned after 24 hours if
                    payment is not completed)
                  </Col>
                  <Col xs="auto" className="ms-auto">
                    {new Intl.NumberFormat(undefined, {
                      style: "currency",
                      currency: props.paymentDetails.currency.toUpperCase(),
                      currencyDisplay: "narrowSymbol",
                    }).format(props.paymentDetails.fromBalance / 100)}
                  </Col>
                </Row>
              </li>
            ) : null}
          </ul>
        </div>

        {errorMessage && <div className="text-danger">{errorMessage}</div>}
        <Button
          type="submit"
          disabled={
            !stripe || !elements || submitting || (resultPI && forceModal)
          }
          className="w-100"
        >
          {submitting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="login"
              aria-hidden="true"
            />
          ) : (
            <>
              Pay{" "}
              {new Intl.NumberFormat(undefined, {
                style: "currency",
                currency: props.paymentDetails.currency,
                currencyDisplay: "narrowSymbol",
              }).format(props.paymentDetails.price / 100)}
            </>
          )}
        </Button>
      </form>
    </>
  );
};
const formatter = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "USD",
  currencyDisplay: "symbol",
});

const BundleDisplay = (props) => {
  const [slideId, setSlideId] = useState(0);
  const [slideDims, setSlideDims] = useState([undefined, undefined]);
  const { userInfo, setUserInfo } = useContext(UserInfo);
  const maxImageDims = [500, (500 * 9) / 16];
  return (
    <>
      <h4 className="TitleText">{props.bundle.name}</h4>
      <p>{props.bundle.description}</p>
      <p>Includes {props.bundle.visuals.length} visuals.</p>
      <Carousel
        fade
        pause="hover"
        touch
        id={`carousel-${props.bundle.id}`}
        style={{
          maxWidth: maxImageDims[0] + "px",
          maxHeight: maxImageDims[1] + "px",
          height: "initial",
        }}
        onSelect={(i) => {
          const s = document.getElementById(`carousel-${props.bundle.id}`);
          setSlideDims([
            slideDims[0] || s.clientWidth,
            slideDims[1] || s.clientHeight,
          ]);
          setSlideId(i);
        }}
        indicatorLabels={props.bundle.visuals.map(
          (v, i) => `Visual ${i + 1}: ${v.title}`
        )}
      >
        {props.bundle.visuals.map((v, i) => (
          <Carousel.Item
            key={v.id}
            style={{
              background: "rgba(0,0,0,0.075)",
            }}
          >
            <a href={v.visualization_url} target="_blank" rel="noreferrer">
              <Image
                src={v.thumbnail}
                className="w-100"
                width={slideDims[0]}
                height={slideDims[1]}
                style={{
                  maxWidth: maxImageDims[0] + "px",
                  maxHeight: maxImageDims[1] + "px",
                }}
              />
              <Carousel.Caption
                style={{
                  background: "var(--carousel-background)",
                  borderRadius: "10px",
                }}
              >
                <h5 className="TitleText">
                  Visual {i + 1}: {v.title}
                </h5>
              </Carousel.Caption>
            </a>
          </Carousel.Item>
        ))}
      </Carousel>
      <p className="text-muted">
        {props.bundle.visuals[slideId || 0].description}
      </p>
      <p>
        {props.billMonthly ? (
        <div>
          {formatter.format(props.bundle.price/100)} per month billed monthly
          <div className="text-bold">
            <i>Save{" "}
            {new Intl.NumberFormat(undefined, {
              style: "percent",
            }).format(1 - props.bundle.annual_price / (props.bundle.price * 12))}{" "}
            with annual billing</i>
          </div>
        </div>
      ) : (
        <div>
          {formatter.format(props.bundle.annual_price / 12/100)} per month billed annually
          <div className="text-bold">
            <i>Best deal!</i>
          </div>
        </div>
      )}
      </p>
      {userInfo ? (
        props.bundle.purchased ? (
          <Button disabled={true}>Already Purchased</Button>
        ) : (
          <>
            {userInfo.tier != "unlimited" ? (
              <Button disabled>Upgrade to an Unlimited plan to purchase</Button>
            ) : (
              <Button
                disabled={props.bundle.inCart || props.disabled}
                onClick={() => {
                  props.addToCart(props.bundle);
                }}
              >
                {props.spinner ? <Spinner size="sm" /> : "Buy Now"}
              </Button>
            )}
          </>
        )
      ) : (
        <Button disabled>Log in to purchase</Button>
      )}
    </>
  );
};

const BundlePage = (props) => {
  const { userInfo, setUserInfo } = useContext(UserInfo);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [error, setError] = useState(null);
  const [inFlight, setInFlight] = useState(false);
  const [billMonthly, setBillMonthly] = useState(true);
  const paymentFormId = "bundle-payment-form";
  const scrollToPaymentForm = () => {
    const e = document.getElementById(paymentFormId);
    if (!e) {
      setTimeout(scrollToPaymentForm, 50);
      return;
    }
    e.scrollIntoView({ block: "center" });
  };
  return (
    <Bundles.Consumer>
      {({ bundles, setBundles }) => {
        return (
          <>
            <h2 className="TitleText">Premium Bundles</h2>
            <p className="mb-4">
              Explore our collection of curated premium bundles. Perfect for
              general education and patient communication.
            </p>
            {bundles === null ? (
              <div className="d-flex justify-content-center">
                <Spinner
                  animation="border"
                  role="loading"
                  className="text-secondary"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <div className="col-auto text-center">
                  <div className="mb-2">
                    Monthly Billing
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={!billMonthly}
                      inline={true}
                      className="ms-3"
                      title={`Switch to ${
                        billMonthly ? "annual" : "monthly"
                      } billing`}
                      onChange={(e) => setBillMonthly(!e.target.checked)}
                      disabled={inFlight || paymentDetails}
                    />
                    Annual Billing
                  </div>
                </div>

                <Row>
                  {paymentDetails && userInfo ? (
                    <div id={paymentFormId}>
                      <StripePromise.Consumer>
                        {(stripePromise) => {
                          return (
                            <Elements
                              stripe={stripePromise}
                              options={{
                                clientSecret:
                                  paymentDetails.stripePaymentSecret,
                                appearance: stripeElementsAppearance,
                              }}
                            >
                              <StripeCheckout
                                paymentDetails={paymentDetails}
                                onCheckout={() => {
                                  setPaymentDetails(null);
                                  const t = [];
                                  for (const b of bundles) {
                                    if (b.inCart) {
                                      const tempObj = shallowCopy(b);
                                      tempObj.inCart = false;
                                      tempObj.purchased = true;
                                      t.push(tempObj);
                                    } else {
                                      t.push(b);
                                    }
                                  }
                                  setBundles(t);
                                }}
                              />
                            </Elements>
                          );
                        }}
                      </StripePromise.Consumer>
                    </div>
                  ) : null}
                  {bundles
                    .filter((s) => s.status == "published")
                    .map((b) => (
                      <Col
                        xs={12}
                        md={6}
                        xl={4}
                        key={b.id}
                        className="px-4 my-4"
                      >
                        <BundleDisplay
                          bundle={b}
                          disabled={inFlight || paymentDetails}
                          spinner={inFlight}
                          billMonthly={billMonthly}
                          addToCart={(bundle) => {
                            APIGetBundlePurchasePI(
                              [bundle.id],
                              billMonthly ? "monthly" : "annual",
                              (res) => {
                                setPaymentDetails(res);
                                setInFlight(false);
                              },
                              (res) => {
                                setError(res);
                                setInFlight(false);
                              }
                            );
                            setError(null);
                            setInFlight(true);
                            scrollToPaymentForm();
                          }}
                        />
                      </Col>
                    ))}
                </Row>
              </>
            )}
          </>
        );
      }}
    </Bundles.Consumer>
  );
};

export default BundlePage;

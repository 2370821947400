import Joyride, {
  ACTIONS as JoyrideActions,
  EVENTS as JoyrideEvents,
  STATUS as JoyrideStatus,
} from "react-joyride";
import { useState, useContext } from "react";
import { ActiveTour } from "../helper/Context";
import { useNavigate, useLocation } from "react-router-dom";

const JoyrideWrapper = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [helpers, setHelpers] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <ActiveTour.Consumer>
      {({ activeTour, setActiveTour }) => {
        if (activeTour) {
          return (
            <Joyride
              steps={activeTour.steps}
              run={true}
              continuous={true}
              hideCloseButton={!!activeTour.hideCloseButton}
              getHelpers={setHelpers}
              disableOverlayClose={!!activeTour.disableOverlayClose}
              scrollToFirstStep={true}
              showProgress={!!activeTour.showProgress}
              disableScrolling={true}
              disableScrollParentFix={true}
              hideBackButton={!!activeTour.hideBackButton}
              showSkipButton={!!activeTour.showSkipButton}
              styles={{options: {backgroundColor: "var(--joyride-bg-color)", textColor: "var(--text-color)", arrowColor: "var(--main-bg-color)"}}}
              stepIndex={currentIndex}
              callback={(e) => {
                if (e.status === JoyrideStatus.FINISHED) {
                  setActiveTour(null);
                }
                const delta = e.action === JoyrideActions.PREV ? -1 : 1;
                const steps = activeTour.steps;
                const currStep = steps[e.index];
                const nextStep =
                  e.index + delta < steps.length && e.index + delta >= 0
                    ? steps[e.index + delta]
                    : null;
                switch (e.action) {
                  case JoyrideActions.START:
                  case JoyrideActions.INIT:
                  case JoyrideActions.RESET:
                    setCurrentIndex(0);
                    break;
                  case JoyrideActions.PREV:
                  case JoyrideActions.NEXT:
                    if (e.type === JoyrideEvents.STEP_AFTER) {
                      if (currStep.postCB && e.action === JoyrideActions.NEXT) {
                        currStep.postCB();
                      }
                      if (currStep.isTerminal) {
                        helpers.close();
                        setCurrentIndex(0);
                        setActiveTour(null);
                        return;
                      }
                      if (
                        nextStep &&
                        nextStep.dest &&
                        nextStep.dest !== location.pathname
                      ) {
                        navigate(nextStep.dest);
                      }
                      if (nextStep && nextStep.preCB) {
                        nextStep.preCB();
                      }
                      if (currentIndex == e.index) {
                        setCurrentIndex(currentIndex + delta);
                      }
                    } else if (e.type === JoyrideEvents.STEP_BEFORE) {
                      if (currStep.preCB) {
                        currStep.preCB();
                      }
                      const target = currStep.target;
                      if (!currStep.disableScrolling) {
                        target.scrollIntoView
                          ? target.scrollIntoView()
                          : document.querySelector(target).scrollIntoView();
                      }
                      if (currStep.optionButtons) {
                        currStep.optionButtons.forEach((option) => {
                          document
                            .getElementById(option.id)
                            .addEventListener("click", () => {
                              const delta = option.callback() || +1;
                              if (delta === 1) {
                                helpers.next();
                              } else if (delta === -1) {
                                helpers.prev();
                              } else {
                                setCurrentIndex(e.index + delta);
                              }
                            });
                        });
                      }
                      if (currStep.autoRouting) {
                        const routeObj = currStep.autoRouting;
                        const func = () => {
                          const delta = routeObj.function();
                          if (delta !== null) {
                            setCurrentIndex(e.index + delta);
                          } else {
                            setTimeout(func, routeObj.retryInterval);
                          }
                        };
                        setTimeout(func, routeObj.retryInterval);
                      }
                    }
                    break;

                  default:
                    break;
                }
              }}
            />
          );
        }
      }}
    </ActiveTour.Consumer>
  );
};

export default JoyrideWrapper;

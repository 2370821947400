import { useState, useEffect, useContext } from "react";
import { APIGetUserInfo } from "../helper/APIFunctions";
import { UserInfo, StripePromise } from "../helper/Context";
import { Spinner, Modal, Button } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import PaymentCompleteComponent from "../components/PaymentCompleteComponent";
import PaySetupCompleteComponent from "../components/PaySetupCompleteComponent";

const PaymentSuccessPage = (props) => {
  const { newTier } = useParams();
  let location = useLocation();
  const pi_secret = new URLSearchParams(location.search).get(
    "payment_intent_client_secret"
  );
  const si_secret = new URLSearchParams(location.search).get(
    "setup_intent_client_secret"
  );
  const navigate = useNavigate();
  const [result, setResult] = useState({ error: null, paymentIntent: null });
  const stripePromise = useContext(StripePromise);
  useEffect(() => {
    if (!pi_secret && !si_secret) {
      navigate("/home"); //No valid payment
    }
    if (!stripePromise) {
      return; //Not loaded yet
    }
    console.log(stripePromise);
    stripePromise.then((stripe) => {
      const func = pi_secret
        ? stripe.retrievePaymentIntent
        : stripe.retrieveSetupIntent;
      const secret = pi_secret || si_secret;
      func(secret).then((res) => {
        console.log(res);
        setResult(res);
      });
    });
  }, [stripePromise]);

  return (
    <>
      {!result ? (
        <Spinner animation="border" role="loading" className="text-secondary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : null}
      {result.error ? (
        <div className="text-error">Unable to fetch your transaction</div>
      ) : null}
      {result.paymentIntent ? (
        <PaymentCompleteComponent
          paymentIntent={result.paymentIntent}
          newTier={newTier}
        />
      ) : null}
      {result.setupIntent ? <PaySetupCompleteComponent setupIntent={result.setupIntent} /> : null}
    </>
  );
};

export default PaymentSuccessPage;

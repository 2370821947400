import { useEffect, useContext, useState } from "react";
import { useParams, Link } from "react-router-dom";
import VisCard from "../components/VisCard";
import { Spinner, Button, Breadcrumb, Form } from "react-bootstrap";
import { APIGetTaggedVisuals } from "../helper/APIFunctions";
import { AvailableTags } from "../helper/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const TaggedVisualsPage = (props) => {
  const { slug = "all" } = useParams();
  const [res, setRes] = useState(null);
  const [tagDict, setTagDict] = useState(null);
  const { availableTags, setAvailableTags } = useContext(AvailableTags);
  useEffect(() => {
    setRes(null);
    APIGetTaggedVisuals(
      slug,
      (r) => {
        setRes({ success: true, obj: r });
      },
      (err) => {
        setRes({ success: false, msg: err.message || err });
      }
    );
  }, [slug]);
  useEffect(() => {
    if (!availableTags) {
      return;
    }
    const tagDict = {
      all: { slug: "all", title: "All", children: [], ancestors: [] },
    };
    availableTags.forEach((tag) => {
      const newObj = Object.assign({ children: [] }, tag);
      tagDict[tag.slug] = newObj;
    });
    availableTags.forEach((tag) => {
      if (tag.parent && tagDict[tag.parent]) {
        tagDict[tag.parent].children.push(tagDict[tag.slug]);
      } else {
        tagDict[tag.slug].parent = "all";
        tagDict["all"].children.push(tagDict[tag.slug]);
      }
    });
    const fillAncestors = (tag) => {
      console.log(tag);
      if (tag.parent) {
        const arr = (tag.ancestors = [
          ...tagDict[tag.parent].ancestors,
          tagDict[tag.parent],
        ]);
      }
      tag.children.forEach((child) => {
        fillAncestors(tagDict[child.slug]);
      });
    };
    fillAncestors(tagDict["all"]);
    console.log(tagDict);
    setTagDict(tagDict);
  }, [availableTags]);
  return (
    <>
      {" "}
      {tagDict && tagDict[slug] ? (
        <Breadcrumb>
          {tagDict[slug].ancestors.map((ancestor) => {
            return (
              <Breadcrumb.Item
                key={ancestor.slug}
                linkAs={Link}
                linkProps={{
                  to:
                    ancestor.slug === "all"
                      ? "/visuals"
                      : `/visuals/${ancestor.slug}`,
                }}
              >
                {ancestor.title}
              </Breadcrumb.Item>
            );
          })}
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{
              to: slug === "all" ? "/visuals" : `/visuals/${slug}`,
            }}
            active
          >
            {tagDict[slug].title}
          </Breadcrumb.Item>
          {tagDict[slug].children.length ? (
            tagDict[slug].children.length === 1 ? (
              <Breadcrumb.Item
                linkAs={Link}
                linkProps={{
                  to:
                    slug === "all"
                      ? "/visuals"
                      : `/visuals/${tagDict[slug].children[0].slug}`,
                }}
              >
                {tagDict[slug].children[0].slug.title}
              </Breadcrumb.Item>
            ) : (
              <li className="breadcrumb-item drop-container">
                  ...
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className="ms-2"
                  size="sm"
                />
                <div class="drop bg-white">
                  <ul class="list pl0">
                    {tagDict[slug].children.map((child) => {
                      return (
                        <li key={child.slug} class="list-group-item">
                          <Link to={`/visuals/${child.slug}`}>
                            {child.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            )
          ) : null}
        </Breadcrumb>
      ) : null}
      {res ? (
        res.success ? (
          <>
            <h3 className="TitleText">{res.obj.tag.title} Visuals</h3>
            <h5 className="text-center">{res.obj.tag.description}</h5>
            <hr />
            {res.obj.visuals.length ? (
              res.obj.visuals.map((vis, i) => {
                return (
                  <div className="border rounded border-secondary" key={i}>
                    <VisCard
                      vis={vis}
                      canEdit={false}
                      canDelete={false}
                      hideFavorite={true}
                      hideQr={true}
                    />
                  </div>
                );
              })
            ) : (
              <div className="text-center mt-4">
                <h5>
                  No{" "} 
                  {res.obj.tag.title === "All"
                    ? "public"
                    : res.obj.tag.title}
                  {" "}visuals yet.
                </h5>
                <Link
                  to={
                    res.obj.tag.title === "All"
                      ? "/dashboard/myvisuals"
                      : `/search?q=${encodeURIComponent(res.obj.tag.title)}`
                  }
                >
                  <Button>Why don't you add one?</Button>
                </Link>
              </div>
            )}
          </>
        ) : (
          <>
            <h3 className="TitleText text-error">Error</h3>
            <p>{res.msg}</p>
          </>
        )
      ) : (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="loading" className="text-secondary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
};

export default TaggedVisualsPage;

import { Row, Col } from "react-bootstrap";
import FeaturedViewers from "../components/FeaturedViewers";
import { Link } from "react-router-dom";
const PowerPointPage = (props) => {
    return (
        <>

            <Row className="justify-content-center">
                {/* <Col xs={9} sm={6} md={5} lg={4} className="align-contents-center mb-4">
          <h4 className="text-center mb-2">Featured Visualizations</h4>
          <FeaturedViewers className="w-100" />
        </Col> */}
                <div className="main-container-narrower">
                    <h2 className="TitleText mb-4">Integrate Snorkle into your PowerPoint presentations with the free add-in </h2>

                    <p>Using the free Snorkle Viewer add-in for Microsoft PowerPoint, you can embed all <a href="#supported_types">supported</a> Snorkle visuals and mobile-activating QR codes directly into your presentations. Because data items are loaded from the cloud, the actual files remain small even if you have massive amounts of data. While the add-in requires no account to get started, you must have a Snorkle account to access your personal or institutional library of visuals. Installation is just a few clicks, see the tutorial video below for more detail on how. <a href="#compatibility">See here for version and operating system requirements.</a>
                    </p>

                    <p> <b>Note on using projectors:</b> If you are presenting PowerPoint live using an overhead projector and your slides utilize the Snorkle Viewer add-in, your computer's presentation mode must be set to display-mirroring mode. On a Mac, this option is available when you have started presentation mode, and in the presenter view you have the option to “use slide presentation.” On a PC, go into display settings in presentation mode, and select duplicate slide show. Without taking these steps, the visuals may appear static on a projection screen and black on your computer screen.    
                    </p>
                    <center>

                        <img src="https://snorkle-output-files.s3.amazonaws.com/snorkle-ppt-addin-downloadscreen.png" width="100%"></img>
                        <p></p>
                        <center>
                        <div class="auto-resizable-iframe">
  <div>
                            <iframe width="100%" height="auto" src="https://www.youtube.com/embed/aqtPizRouF0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                       </div></div>
                        </center>
                        <p></p>
                        <img src="https://snorkle-output-files.s3.amazonaws.com/snorkle-ppt-anatomy-slide.png" width="100%"></img>
                        <p></p>
                        <img src="https://snorkle-output-files.s3.amazonaws.com/snorkle-digital-pathology-ppt.png" width="100%"></img>
                        <p></p>

                    </center>


<div>
                    <a id="supported_types"> <h2 className="TitleText mb-4">Supported file, data, and web resource types</h2></a>
                    <p></p>
                    - DICOM or TIFF image stacks (e.g. radiological scans or 3D microscopy data) <br></br>
                    - Extremely high resolution images such as pathology slides or astronomical sky surveys <br></br>
                    - Protein molecular structures, from the <a href="https://www.rcsb.org/" target="_blank">Protein Data Bank (PDB)</a><br></br>
                    - 3D models from <a href="https://www.sketchfab.com" target="_blank"> Sketchfab</a>. <p></p>
                    Snorkle also generates QR codes that link to mobile-friendly browser viewers so that viewers can simultaneously explore along with the presenter.
                    
                    <p></p>
                    <a id="compatibility"> <h2 className="TitleText mb-4">Operating system compatibility</h2></a>
                    <p></p>
                    <b>Note</b>: If you have difficulty installing the add-in or you do not see that option, <Link to="/contact">contact us</Link> for assistance. <p></p>
                    <b>Mac OS</b>: Requires PowerPoint version 16.19 or later versions including Office 365 (and web versions).  <p></p>
                    <b>Windows</b>: Requires PowerPoint 2019 and later, including Office 365 (and web version). Otherwise, requires The 32- or 64-bit version of Office 2013 or a later version, running on Windows 7 or a later version. Microsoft Edge must be installed, but doesn't have to be the default browser. 
                    
                    </div>

                    {/* <center>

          <img src="https://snorkle-output-files.s3.amazonaws.com/snorkle-ppt-addin-downloadscreen.png" width="500"></img>
          <p></p>
          <img src="https://snorkle-output-files.s3.amazonaws.com/snorkle-ppt-anatomy-slide.png" width="500"></img>
          <p></p>
          <img src="https://snorkle-output-files.s3.amazonaws.com/snorkle-digital-pathology-ppt.png" width="500"></img>
          <p></p>

          </center> */}

                    {/* <Col className="align-contents-center mb-4">
            <h4 className="text-center mb-2">Featured Visuals</h4>
            <FeaturedViewers />
          </Col> */}

                </div>

            </Row>
        </>
    );
};

export default PowerPointPage;

import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-regular-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = (props) => {
  const [showCookieModal, setShowCookieModal] = useState(false);
  window.snorkle.addDarkModeChangeListener((darkMode) => {
    document.getElementById("snorkle-set-dark-mode").classList.toggle("d-none", darkMode);
    document.getElementById("snorkle-set-light-mode").classList.toggle("d-none", !darkMode);
  })
  return (
    <>
      <Modal
        show={showCookieModal}
        onHide={(event) => {
          setShowCookieModal(false);
        }}
      >
        <Modal.Header closeButton closeVariant="white">
          <h3>Cookie Disclaimer</h3>
        </Modal.Header>
        <Modal.Body>
          <p>
            This site uses cookies to associate your browser instance with your
            user account. No identifying of tracking information is collected or
            stored other than a random session identifier.
          </p>
          <p>
            If you do not wish to have this session token stored on your
            browser, then you can use the guest access features instead of
            creating or logging into an account.
          </p>
        </Modal.Body>
      </Modal>

      <Row className="mt-4">
        <Col xs="auto">&copy; 2024 Snorkle, Inc.</Col>

        <Col className="text-start" xs="auto">
          <Link to="/privacy" className="visualization_link">
            <small>Privacy Policy</small>
          </Link>
        </Col>

        <Col className="text-start" xs="auto">
          <Link to="/tos" className="visualization_link">
            <small>Terms of Use</small>
          </Link>
        </Col>
        <Col className="text-start" xs="auto">
          <small>
            <button className="secret-button" onClick={(event) => {
              setShowCookieModal(true);
            }}
            >
              <u
                className="visualization_link"
              >
                Cookie Disclaimer
              </u>
            </button>
          </small>
        </Col>

        <Col xs="auto" className="ms-auto">
          <a
            href="https://twitter.com/SnorkleLabs"
            rel="noreferrer"
            target="_blank"
            className="visualization_link me-2"
          >
            <FontAwesomeIcon icon={faXTwitter} size="lg" title="Check Us Out on X/Twitter" />
          </a>
          <button onClick={() => window.snorkle.toggleDarkMode(true)} id="snorkle-set-dark-mode" className={"secret-button" + (window.snorkle.isDarkMode() ? " d-none" : "")}>
            <FontAwesomeIcon icon={faMoon} size="lg" title="Switch to Dark Mode" style={{ color: "white" }} />
          </button>
          <button onClick={() => window.snorkle.toggleDarkMode(false)} id="snorkle-set-light-mode" className={"secret-button" + (window.snorkle.isDarkMode() ? "" : " d-none")}>
            <FontAwesomeIcon icon={faSun} size="lg" title="Switch to Light Mode" style={{ color: "white" }} />
          </button>
        </Col>
      </Row>
    </>
  );
};

export default Footer;

import { Row, Col, Button } from "react-bootstrap";
import FeaturedViewers from "../components/FeaturedViewers";
import introTour from "../tours/IntroductoryTour";
import { ActiveTour } from "../helper/Context";
import { useContext } from "react";
import { Link } from "react-router-dom";

const TutorialsPage = (props) => {
  const { setActiveTour } = useContext(ActiveTour);
  return (
    <>
      <Row className="justify-content-center">
        <div className="main-container-narrower">


          <h2 className="TitleText mb-4">Overview</h2>
          <div>
            <center>
              <Button onClick={() => setActiveTour(introTour)}>
                Click to revisit the introductory tour!
              </Button>
            </center>
          </div>

          <p></p>
          <br></br>

          <h4 ><b>Creating visuals</b></h4>
          <div>
            <a href="#supported_types">Supported file types for loading images </a>
          </div>

          <div>
            <a href="#models3d">Creating visuals from 3D models</a>
          </div>

          <div>
            <a href="#proteins">Creating visuals from PDB molecules</a>
          </div>


          <br></br>

          <h4 ><b>Viewing and presenting Snorkle visuals</b></h4>
          <div>
            <a href="#viewer_controls">Basic multichannel image viewing tools</a>
          </div>

          <div>
            <a href="#volume_viewer_controls">Special volumetric 3D viewing options for image stacks</a>
          </div>

          <div>
            <a href="#changing_initial_view">Changing the initial view for image stacks</a>
          </div>

          <div>
            <a href="#zoomable_controls">Zoomable image viewer</a>
          </div>

          <div>
            <Link to="/powerpoint"><b>Presenting Snorkle visuals using PowerPoint</b></Link>
          </div>

          <br></br>

          <h4 ><b>Sharing and embedding Snorkle visuals</b></h4>
          <div>
            <a href="#social_media">Sharing visuals via Twitter or Facebook</a>
          </div>
          <div>
            <a href="#embedding">Embedding Snorkle visuals in a webpage</a>
          </div>

          <div>
            <a href="#sharing_url">Sharing visual links via URL or QR code</a>
          </div>

          <br></br>



          <a id="supported_types"><h4><b>Supported image types</b></h4></a>
          <div>
            <b>Flat image uploads:</b> Snorkle accepts standard flat image formats: .png, .jpg, .jpeg, .tif, .tiff, or .dcm (DICOM) files. Very high resolution images (with the exception of .dcm files) will be displayed as "zoomable," dynamically-loading tiled images.
            <p></p><b>Image stacks:</b> Files must be either in a multi-page .tiff format or compressed (.zip) multipage .tiff file or archive. For multi-slice DICOM files such as CT scan or MRIs, these can be uploaded as a zipped folder containing the individual .dcm files associated with each slice or else as a single multi-page .dcm file.
            <p></p><b>Size limits:</b> The maximum size for a single upload (either single image or zipped folder of multiple images) varies by account tier: 50 MB at the free tier, 100 MB at premium, and 250 MB at unlimited.

          </div>
          <br></br>

          <a id="models3d"><h4><b>3D models and animations</b></h4></a>
          <div>
            Currently, Snorkle supports importing 3D models, including animated ones, from <a href="https://www.sketchfab.com" target="_blank"> Sketchfab</a>. Using Snorkle, these can be accessed, viewed, and embedded into PPT for free. Simply search for your model, select to inspect, copy the URL (as in the screenshot below), and paste into the Web Resource input field in "Add Web Resource."
            <center><img src="https://snorkle-output-files.s3.amazonaws.com/sketchfab_samplescreen.png" width="75%"></img>
            <p></p></center>
            <b>Try it out below:</b> embedded within a site, the visual looks like this (displaying the share button in your visuals is optional):
            <center><iframe src="https://vis.snorkle.io/MVumEYhs2tKwIDerNrmTfR4gQgtU9cWTYApRjSb8oMo=.html" width="100%" height="400" /></center>

          </div>
          <br></br>

          <a id="proteins"><h4><b>Protein structures</b></h4></a>
          <div>
            Snorkle currently supports creating interactive visuals of molecules from the <a href="https://www.rcsb.org/" target="_blank"> Protein Data Bank.</a> Simply search for your molecule of interest, then copy either the URL or 4-character PDB code as shown in the screenshot below, then paste into the Web Resource input field in "Add Web Resource."
            <p></p>
            <center>
            <img src="https://snorkle-output-files.s3.amazonaws.com/PDB_screenshot.png" width="75%"></img>
            </center>
            <b>Try it out below:</b> Embedded within a site, an interactive molecule (GFP) looks like this:
            <p></p>
            <br></br>
            <center><iframe src="https://vis.snorkle.io/QQoTH24XgPjWv_Ktqy7M4_z3jIKc6goRwM9dA9N4vXo=.html" width="100%" height="400" /></center>

          </div>
          <br></br>

          <a id="viewer_controls"><h4><b>Basic multichannel image viewing tools</b></h4></a>
          <div>
            Snorkle visuals are "theater mode" by default, meaning they are displayed with minimal buttons, knobs, and sliders. However, Snorkle visuals can be inspected in detail using the image viewing options. Clicking the "settings" button on the bottom left corner of a visual reveals both channel adjustment and display options.
            <p></p><center>
            <img src="https://snorkle-output-files.s3.amazonaws.com/image_adjustments_palette.png" width="75%"></img>
            <p></p></center>
            <b>Try it out below:</b> Embedded within a site, this particular fluorescence image stack looks like this (note that the initial view/display can be customized):
            <p></p>
            <center><iframe src="https://vis.snorkle.io/9EiJ3r4sV79fOepDSKu_pcG2MQffIeCVSlQbThqz0eY=/viewer.html" width="100%" height="400" /></center>
          </div>
          <br></br>

          <a id="volume_viewer_controls"><h4><b>Viewing image stacks in 3D</b></h4></a>
          <div>
            For image stacks with more than <b>10 slices</b>, toggling the "3D" button will create a volumetric 3D rendering of the data. The buttons "fast," "standard," and "detailed" that appear refer to rendering quality, which can affect the smoothness of rotation and zooming on machines with lower graphics performance. The default rendering is "standard," however <b> "detailed" rendering generally moves smoothly on most machines, looks considerably better, and should be used.</b> Another button that appears in 3D mode is the "rotate" button which makes the rendered object slowly rotate until stopped by a mouse / tap.
            <br></br>
            <p></p><center>
            <img src="https://snorkle-output-files.s3.amazonaws.com/volumetric_options.png" width="75%"></img>
            <p></p></center>
            Thus, a single image stack can be adjusted to have a multitude of appearances and data inspection capabilities ranging from standard 2D presentation to exotic-colored sliced volumes, e.g.:

            <p></p> <center>
            <img src="https://snorkle-output-files.s3.amazonaws.com/brain3d_viewoptions.png" width="75%"></img>
            <p></p></center>
            <b>Try it out below:</b> Depending on your specified initial view, this embedded visual can look like this (settings: render quality = detailed, night mode, color map "black to white," rotation on, data thresholded)
            <p></p>
            <center><iframe src="https://vis.snorkle.io/NOS-d7RDrGegh50gr4pL4NHMbC8_OdkZRgxsRQ2DZ00=/viewer.html" width="100%" height="400" /></center>

            <p></p>
            <b>Note on rendering quality on Safari for desktops:</b> Volumetric renderings may be of slightly lower quality when displayed in Safari (on desktops). Any other browser should be used. Rendering quality is still high on iPhones and Android devices.

          </div>
          <br></br>
          <p></p>

          <a id="changing_initial_view"><h4><b>Changing initial view of image stacks / volumetric rendering</b></h4></a>
          <div>
            Image stacks can require fiddling around with to achieve a desired aesthetic. For instance, the volumetric MRI renderings above involve switching to 3D, changing to night mode, adjusting the low threshold, turning on rotation, advancing the stack slider to reveal subcortical regions, etc. Rather than spending time adjusting this on the fly during a presentation (and for the benefit of sharing attractive visualizations), you can set the default initial view settings from within your list of visuals using the "Change initial view" button. That will open up a window with your data item, which you can then adjust the view settings on. Clicking "Save current view" will then cause that visual link to open with the new custom view. <b>It may take up to 15 minutes to propagate that changed initial view over the web.</b>
          </div>
          <br></br>


          <a id="zoomable_controls"><h4><b>Zoomable image viewer</b></h4></a>
          <div>
            High resolution images (>2048 x 2048) will be displayed as dynamically loading tiled image viewers. This is particularly applicable for whole-slide pathology imagery. These visuals may take additional time to process; <b>very high resolution files can take upwards of 15 minutes to create a Snorkle visual version.</b> Below is an interactive example of this:
          </div>
          <p></p>
          <center><iframe src="https://vis.snorkle.io/axcI7mLhucvRLlYQQNCmLF6rAOLREhbeTxlrEhJ2ZGM=.html" width="100%" height="400" /></center>

          <br></br>

          <a id="social_media"><h4><b>Sharing via Twitter or Facebook</b></h4></a>
          <div>
            You can share your Snorkle visuals from your list of Visuals by using the Share button in the bottom right of each visual card. Sharing on Facebook or Twitter will create a link preview that includes a thumbnail image, if you have uploaded one for that visual, as well as the title and description. Visuals from Sketchfab automatically have thumbnails associated with them, but they can be changed using the edit option.
          </div>
          <center>          <p></p>
            <img src="https://snorkle-output-files.s3.amazonaws.com/tweet_image.png" width="50%"></img>
            <p></p>
            <img src="https://snorkle-output-files.s3.amazonaws.com/facebook_sharing.png" width="50%"></img>
            <p></p>
            <img src="https://snorkle-output-files.s3.amazonaws.com/share_options.png" width="50%"></img>
            <p></p>
                      </center>
            You can enable or restrict other people from sharing or embedding your visual by checking or unchecking the share button option in the editing options:
            <center>
            <p></p>
            <img src="https://snorkle-output-files.s3.amazonaws.com/enable_share_button.png" width="50%"></img>
            <p></p>
            </center>


          <br></br>

          <a id="embedding"><h4><b>Embedding Snorkle visuals</b></h4></a>
          <div>
            You can embed Snorkle visuals into a website by copying the embed HTML code snippet offered as one of the sharing options, as below.  
          </div>
          <p></p>
          <center>
            <img src="https://snorkle-output-files.s3.amazonaws.com/embed_code.png" width="50%"></img>
            <p></p>
            </center>

          <br></br>

          <a id="sharing_url"><h4><b>Sharing via URL or QR code</b></h4></a>
          <div>
            Maximizing mobile compatibility and experience is a core value for Snorkle. All visuals created using Snorkle can be shared and viewed on mobile through the automatically generated QR codes. These codes can be copied and pasted from each visual's card in your list. The QR codes can also be auto-inserted into your slides when using the <Link to="/powerpoint"><b>Snorkle Viewer add-in for PowerPoint</b></Link>. Otherwise, simply copying and sharing the Snorkle URL for your visual will enable anyone to view it in the browser.
          </div>
          <br></br>
          <center>
            <img src="https://snorkle-output-files.s3.amazonaws.com/QR_card_MRI_visual.png" width="75%"></img>
            <p></p>
            </center>

          

        </div>
      </Row>
    </>
  );
};

export default TutorialsPage;
